import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import Header from "../../Components/Header";
import withUserAuth from "../../Behavior/withUserAuth";
import {addItem, getAllUnits, updateItem, uploadFile} from "../../API";
import ErrorDialog from "../../Components/ErrorDialog";
import Loader from "../../Components/Loader";
import {useItemStore} from "../../Store";
import {calculateProfit, calculateTaxAmount} from "../../Utils";
import ModifierDialog from "../../Components/ModifierDialog";
import _ from "lodash";
import VariationDialog from "../../Components/VariationDialog";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";
import $ from "jquery";

const PLACEHOLDER_IMAGE = '/img/camera-placeholder.svg';

const Item = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const itemStore = useItemStore();
    const item = location.state?.item || {};
    // const category = location.state?.category || {};
    const allCategories = itemStore.getAllItems();
    const allTaxes = itemStore.getTaxes();
    const allModifiers = itemStore.getModifiers();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [name, setName] = useState(() => item?.name || '')
    const [imageUrl, setImageUrl] = useState(() => item?.detailimageurl || PLACEHOLDER_IMAGE);
    const [imageFile, setImageFile] = useState(null);
    const imageInputRef = useRef();
    const [categoryId, setCategoryId] = useState(() => {
        if (!_.isEmpty(item)) return allCategories.find(cat => cat.categoryID === item.categoryid).categoryID;
        return allCategories[0].categoryID;
    });
    const [description, setDescription] = useState(() => item?.description || '');
    const [price, setPrice] = useState(() => item?.salesprice || '0');
    const [cost, setCost] = useState(() => item?.costprice || '0');
    const [margin, setMargin] = useState(() => item?.margin || '0');
    const [retailPrice, setRetailPrice] = useState(() => item?.retailprice || '0');
    const [profit, setProfit] = useState(() => item?.profit || '0');
    const [selectedVariation, setSelectedVariation] = useState({});

    const category = allCategories.find(cat => cat.categoryID == categoryId);

    const getTaxRate = () => {
        return category.taxes.map(tax => allTaxes.find(t => t.companyTaxId === tax)).reduce((t1, t2) => (t1 + t2.tax), 0);
    }

    const [taxAmount, setTaxAmount] = useState(() => {
        if (item) {
            const taxRate = getTaxRate()
            return calculateTaxAmount(retailPrice, taxRate);
        }
        return 0;
    });

    const [unit, setUnit] = useState(() => item?.unit || '');
    const [sku, setSku] = useState(() => item?.sku || '');
    const [barcode, setBarcode] = useState(() => item?.barcode || '');
    const [variations, setVariations] = useState(() => item?.variations || []);
    const [modifiers, setModifiers] = useState(() => item?.modifiers || []);
    const [showModifierDialog, setShowModifierDialog] = useState(false);
    const [showVariationDialog, setShowVariationDialog] = useState(false);

    const [allUnits, setAllUnits] = useState([]);

    const sendAPIRequest = async () => {
        const res = await getAllUnits();
        if (res.code === 200) {
            setAllUnits(res.data.units);
            if (_.isEmpty(item)) {
                setUnit(res.data.units[0].id);
            }

        } else {
            setError(res.error);
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest();
    }, []);

    useEffect(() => {
        const res = calculateProfit('price', price, cost, margin, getTaxRate());
        setPrice(res.salePrice);
        setRetailPrice(res.retailPrice);
        setMargin(res.margin);
        setProfit(res.profit);
        setTaxAmount(res.taxAmount);
        setCost(res.costPrice);
        if (variations?.length > 0) {
            variations.forEach(v => {
                const res = calculateProfit('price', v.salesprice, v.costprice, v.margin, getTaxRate());
                v.taxamount = res.taxAmount;
                v.retailprice = res.retailPrice;
                v.costprice = res.costPrice;
                v.profilt = res.profit;
            })
        }
    }, [categoryId])

    const handlePriceChange = e => {
        let value = e.target.value;
        const res = calculateProfit('price', value, cost, margin, getTaxRate());
        setPrice(value);
        setRetailPrice(res.retailPrice);
        setMargin(res.margin);
        setProfit(res.profit);
        setTaxAmount(res.taxAmount);
    }

    const handleCostChange = e => {
        let value = e.target.value;
        const res = calculateProfit('cost', price, value, margin, getTaxRate());
        setCost(value);
        setPrice(res.salePrice);
        setRetailPrice(res.retailPrice);
        setMargin(res.margin);
        setProfit(res.profit);
        setTaxAmount(res.taxAmount);
    }

    const handleMarginChange = e => {
        let value = e.target.value;
        const res = calculateProfit('margin', price, cost, value, getTaxRate());
        setPrice(res.salePrice);
        setRetailPrice(res.retailPrice);
        setMargin(value);
        setProfit(res.profit);
        setTaxAmount(res.taxAmount);
    }

    const handleVariantDelete = index => {
        setVariations(_.remove([...variations], ((_, i) => index !== i)));
    }

    const handleModifierDelete = index => {
        setModifiers(_.remove([...modifiers], ((_, i) => index !== i)));
    }

    const handleModifierSelectionChange = (isChecked, modifier) => {
        if (isChecked) {
            setModifiers([...modifiers, modifier]);
        } else {
            setModifiers(_.remove(modifiers, (m => m.modifierId !== modifier.modifierId)));
        }
    }

    const handleRemoveImage = (e) => {
        e.stopPropagation();
        setImageUrl(() => item?.detailimageurl || PLACEHOLDER_IMAGE);
        setImageFile(null);
    }

    const handleVariationSave = (variation) => {
        const tempVariations = [...variations];
        if (!!variation.itemvariationid) {
            const index = tempVariations.findIndex(v => v.itemvariationid === variation.itemvariationid);
            tempVariations.splice(index, 1, variation);
        } else {
            tempVariations.push(variation);
        }
        setVariations(tempVariations);
        setSelectedVariation({});
        setShowVariationDialog(false);
    }

    const handleSave = async () => {

        // if (!unit) {
        //     setError('Unit is missing');
        //     return;
        // }
        const newItem = {
            ...(item.itemid ? {itemid: item.itemid} : {}),
            name,
            description,
            unit,
            imageurl: item.imageUrl || '',
            detailimageurl: item.detailimageurl || '',
            categoryid: categoryId,
            salesprice: price,
            costprice: cost,
            retailprice: retailPrice,
            margin,
            barcode,
            sku,
            taxamount: taxAmount,
            profit,
            hasvariations: variations.length > 0,
            variations,
            hasmodifiers: modifiers.length > 0,
            modifiers: modifiers.map(({modifierId}) => ({modifierId})),
        }
        setLoading(true);
        if (imageUrl !== PLACEHOLDER_IMAGE && imageUrl !== item.detailimageurl) { //need to upload file
            const res = await uploadFile(imageFile);
            if (res.code === 200) {
                newItem.imageurl = res.data.image.url;
                newItem.detailimageurl = res.data.image.url;
            } else {
                setLoading(false);
                setError(res.error)
                return;
            }
        }
        let res;
        if (newItem.itemid) { //Update
            res = await updateItem(newItem);
        } else {
            res = await addItem(newItem);
        }
        setLoading(false);
        if (res.code === 200) {
            navigate(-1);
        } else {
            setError(res.error);
        }
    }

    useEffect(() => {
        function handleResize() {
            const windscroll = $(window).scrollTop();
            if (windscroll >= 100) {
                $(".appHeader").removeClass("transparent")
            } else {
                $(".appHeader").addClass("transparent")
            }
        }

        window.addEventListener('scroll', handleResize)
        handleResize();
        return () => {
            window.removeEventListener('scroll', handleResize)
        }
    }, [])

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <ModifierDialog isOpen={showModifierDialog} modifiers={allModifiers}
                            onSelectionChange={handleModifierSelectionChange} selectedModifiers={modifiers}
                            onClose={() => setShowModifierDialog(false)}/>
            {showVariationDialog && <VariationDialog
                isOpen={showVariationDialog}
                variation={selectedVariation}
                taxRate={getTaxRate()}
                onClose={() => setShowVariationDialog(false)}
                onSave={handleVariationSave}
            />}
            <Header title={"Item"} hasBack/>
            <SideMenu selected={MENUS.ITEMS}/>
            <div id="appCapsule" className="p-0">
                {/* Items */}
                <div onClick={() => imageInputRef.current.click()} className="cover-img-wrapper">
                    <img src={imageUrl} className="cover-img" alt="image"/>
                    <input
                        style={{display: 'none'}}
                        ref={imageInputRef}
                        type="file"
                        className="form-control"
                        id="photo"
                        accept="image/*"
                        onChange={(e) => {
                            if (e.target?.files?.length > 0) {
                                setImageUrl(URL.createObjectURL(e.target.files[0]));
                                setImageFile(e.target.files[0]);
                            }
                        }}
                    />
                </div>

                <div className="section edit-item-details mt-2">
                    {/*<div className="row m-0">*/}
                    {/*    <div className="col-6 ps-0">*/}
                    {/*        <div onClick={() => imageInputRef.current.click()} className="card img-card card-border">*/}
                    {/*            <img*/}
                    {/*                src={imageUrl}*/}
                    {/*                className="card-img-top"*/}
                    {/*            />*/}
                    {/*            <div style={{display: 'flex'}}>*/}
                    {/*                <h4 style={{flex: 1}}>Select Photo</h4>*/}
                    {/*                {imageFile && <h4 onClick={handleRemoveImage} style={{flex: 1}}>Remove</h4>}*/}
                    {/*            </div>*/}
                    {/*            <input*/}
                    {/*                style={{display: 'none'}}*/}
                    {/*                ref={imageInputRef}*/}
                    {/*                type="file"*/}
                    {/*                className="form-control"*/}
                    {/*                id="photo"*/}
                    {/*                accept="image/*"*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    if (e.target?.files?.length > 0) {*/}
                    {/*                        setImageUrl(URL.createObjectURL(e.target.files[0]));*/}
                    {/*                        setImageFile(e.target.files[0]);*/}
                    {/*                    }*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                    {/* * Items */}
                    {/* details */}
                    <div className="card card-border mt-2">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="itemName">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="itemName"
                                            value={name}
                                            onChange={(e => setName(e.target.value))}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                {/*<div className="form-group basic">*/}
                                {/*    <div className="input-wrapper">*/}
                                {/*        <label className="label" htmlFor="category">*/}
                                {/*            Category*/}
                                {/*        </label>*/}
                                {/*        <input*/}
                                {/*            id="category"*/}
                                {/*            type={"text"}*/}
                                {/*            className="form-control"*/}
                                {/*            value={category.name}*/}
                                {/*            readOnly*/}
                                {/*            disabled*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="selectCategory">
                                            Category
                                        </label>
                                        <select
                                            className="form-control custom-select"
                                            id="selectCategory"
                                            value={categoryId}
                                            onChange={(e) => {
                                                setCategoryId(e.target.value);
                                            }}
                                        >
                                            {
                                                allCategories.map(cat => <option key={cat.categoryID}
                                                                                 value={cat.categoryID}>{cat.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="Description">
                                            Description
                                        </label>
                                        <textarea
                                            id="Description"
                                            rows={2}
                                            className="form-control"
                                            value={description}
                                            onChange={e => setDescription(e.target.value)}
                                            maxLength={500}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="price">
                                            Sale Price
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="price"
                                            value={price}
                                            onChange={handlePriceChange}
                                            maxLength={10}
                                        />
                                        {/*<span style={{fontSize: '11px'}}>Retail Price {retailPrice}</span>*/}
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="cost">
                                            Cost
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="cost"
                                            value={cost}
                                            onChange={handleCostChange}
                                            maxLength={10}
                                        />
                                        {/*<span style={{fontSize: '11px'}}>Tax Amount {taxAmount} </span>*/}
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="margin">
                                            Margin
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="margin"
                                            value={margin}
                                            onChange={handleMarginChange}
                                            maxLength={10}
                                        />
                                        {/*<span style={{fontSize: '11px'}}>Profit {profit}</span>*/}
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="unit">
                                            Unit
                                        </label>
                                        <select
                                            className="form-control custom-select"
                                            id="unit"
                                            value={unit}
                                            onChange={(e) => {
                                                setUnit(e.target.value);
                                            }}
                                        >
                                            {
                                                allUnits.map(unit => <option key={unit.id}
                                                                             value={unit.id}>{unit.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="sku">
                                            SKU
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="sku"
                                            value={sku}
                                            onChange={e => setSku(e.target.value)}
                                            maxLength={100}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="barcode">
                                            Barcode
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="barcode"
                                            value={barcode}
                                            onChange={e => setBarcode(e.target.value)}
                                            maxLength={100}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* * details */}
                    {/* Variation */}
                    <div className="mt-2">
                        <ul className="listview image-listview separate-list inset no-line no-arrow m-0">
                            {variations.map((variant, index) => {
                                return (
                                    <li key={index}>
                                        <a onClick={() => {
                                            setSelectedVariation(variant);
                                            console.log('selected', selectedVariation);
                                            setShowVariationDialog(true);
                                        }}
                                           className="item"
                                        >
                                            <div className="in">
                                                <div>{variant.name}</div>
                                                <span>{variant.salesprice}</span>
                                            </div>
                                        </a>
                                        <span onClick={() => handleVariantDelete(index)}
                                              className="delete-item py-3 ps-1 pe-3">
                                            <ion-icon name="close"/>
                                        </span>
                                    </li>
                                )

                            })}
                        </ul>
                        <a
                            onClick={() => setShowVariationDialog(true)}
                            className="btn add-tag-anchor btn-primary mt-1"
                        >
                            <span>
                              <ion-icon name="add-circle"/>
                            </span>
                            Add Variation
                        </a>
                    </div>
                    {/* * Variation */}
                    {/* Modifier */}
                    {/*<ul className="listview simple-listview transparent darkmode-transparent flush mt-3">*/}
                    {/*    <li>*/}
                    {/*        <div>Modifiers</div>*/}
                    {/*        <div className="form-check form-switch">*/}
                    {/*            <input*/}
                    {/*                className="form-check-input"*/}
                    {/*                type="checkbox"*/}
                    {/*                id="SwitchModifiers"*/}
                    {/*            />*/}
                    {/*            <label*/}
                    {/*                className="form-check-label"*/}
                    {/*                htmlFor="SwitchModifiers"*/}
                    {/*                id="itemsSwitchToggler"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                    <div id="itemsSwitchWrapper" style={{marginTop: '16px'}}>
                        <ul className="listview image-listview separate-list inset no-line no-arrow m-0">
                            {
                                modifiers.map((mod, index) => {
                                    const modifier = itemStore.getModifiers().find(m => m.modifierId === mod.modifierId)
                                    return (
                                        <li key={modifier.modifierId}>
                                            <a className="item">
                                                <div className="in">
                                                    <div>
                                                        <h4>{modifier.name}</h4>
                                                        {modifier.modifierOptions.map(option => (<span
                                                            key={option.modifierOptionId}>{`${option.name}, `}</span>))}
                                                    </div>
                                                </div>
                                            </a>
                                            <span onClick={() => handleModifierDelete(index)}
                                                  className="delete-item py-3 ps-1 pe-3">
                                                <ion-icon name="close"/>
                                            </span>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        <a
                            onClick={() => setShowModifierDialog(true)}
                            className="btn add-tag-anchor btn-primary mt-1"
                        >
                            <span>
                              <ion-icon name="add-circle"/>
                            </span>
                            Add Modifier
                        </a>
                    </div>
                    {/* * Modifier */}
                    <div className="mt-4">
                        <a
                            onClick={handleSave}
                            className="btn btn-primary btn-shadow btn-lg btn-block"
                        >
                            Save
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(Item);
