import {useEffect, useState} from 'react';
import withUserAuth from "../../Behavior/withUserAuth";
import {SERVER_URL, shareQR} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import {useLocation, useNavigate} from "react-router-dom";
import QRCode from "react-qr-code";
import {showErrorToast, showSuccessToast, validateEmail} from "../../Utils";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";


const POSShareQR = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {invoiceId} = location.state;
    const codeUrl = `${SERVER_URL}/orderapp/shareinvoice?invoiceid=${invoiceId}`;

    const [isEmail, setIsEmail] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [value, setValue] = useState('');

    useEffect(() => {
        setValue('');
    }, [isEmail]);

    const handleSend = async () => {
        let email = '';
        let sms = '';
        const invoiceid = invoiceId;
        if (isEmail) {
            email = value;
            if (!validateEmail(value)) {
                showErrorToast('Email is not valid');
                return;
            }
        } else {
            sms = value;
        }
        setLoading(true)
        const {code, message, error} = await shareQR({invoiceid, email, sms});
        if (code === 200) {
            showSuccessToast(message);
            navigate('/')
        } else {
            setError(error);
        }
        setLoading(false);
    }

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header title={"Send to Client"} hasBack backURL={'/'}/>
            <SideMenu selected={MENUS.POS}/>
            <div id="appCapsule">
                <div className="sell-checkout-qr mt-4">
                    <div className="section">
                        <div className="card card-border">
                            <div className="card-body">
                                <QRCode value={codeUrl}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="send-via mt-2">
                    <div className="send-email">
                        <a onClick={() => setIsEmail(true)}>
                            <div className="in card-border">
                                <ion-icon class="icon" name="mail-outline"/>
                            </div>
                        </a>
                        <h5>Email</h5>
                    </div>
                    <div className="send-sms">
                        <a onClick={() => setIsEmail(false)}>
                            <div className="in card-border">
                                <ion-icon class="icon" name="chatbox-ellipses-outline"/>
                            </div>
                        </a>
                        <h5>SMS</h5>
                    </div>
                </div>
                <div className="section confirmed-email mt-2">
                    <form action="#">
                        <div className="card card-border">
                            <div className="card-body">
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label"
                                               htmlFor="email1">{isEmail ? 'E-mail' : 'Mobile'} </label>
                                        <input
                                            type={isEmail ? 'email' : 'phone'}
                                            className="form-control" id="email1"
                                            onChange={e => setValue(e.target.value)}
                                            value={value}
                                            placeholder={isEmail ? 'Your e-mail' : '(201) 555-0123'} required/>
                                    </div>
                                </div>
                                <a onClick={handleSend} className="btn btn-primary btn-shadow">Send</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(POSShareQR);

