import {Link} from 'react-router-dom';
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {
    MENUS,
    PATH_SETTINGS_ACCEPT_PAYMENTS,
    PATH_SETTINGS_CASH_LIST,
    PATH_SETTINGS_DELIVERY,
    PATH_SETTINGS_HARDWARES,
    PATH_SETTINGS_MODIFIERS,
    PATH_SETTINGS_ORG_INFO,
    PATH_SETTINGS_PROFILE,
    PATH_SETTINGS_SECTIONS,
    PATH_SETTINGS_TABLES,
    PATH_SETTINGS_TAXES
} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const Settings = ({onSidePanelOpen}) => {

    return (
        <>
            <Header title={"Settings"} onSidePanelClick={onSidePanelOpen}/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">

                <ul className="listview card-border image-listview text inset mt-2">
                    <li>
                        <Link to={PATH_SETTINGS_PROFILE} className="item">
                            <div className="in">
                                <div>My Profile</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_ORG_INFO} className="item">
                            <div className="in">
                                <div>Organization Info</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_ACCEPT_PAYMENTS} className="item">
                            <div className="in">
                                <div>Accepting Payments</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_MODIFIERS} className="item">
                            <div className="in">
                                <div>Modifiers</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_SECTIONS} className="item">
                            <div className="in">
                                <div>Sections</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_TABLES} className="item">
                            <div className="in">
                                <div>Tables</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_HARDWARES} className="item">
                            <div className="in">
                                <div>Hardware</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_CASH_LIST} className="item">
                            <div className="in">
                                <div>Cash List</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_TAXES} className="item">
                            <div className="in">
                                <div>Tax</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_DELIVERY} className="item">
                            <div className="in">
                                <div>Delivery</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <a className="item">
                            <div className="in">
                                <div>Billing</div>
                            </div>
                        </a>
                    </li>
                </ul>

            </div>
        </>
    )
}

export default withUserAuth(Settings);
