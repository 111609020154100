import {useEffect, useState} from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import {deleteClient, getReceipts} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import {Link} from "react-router-dom";
import {
    DISPLAY_DATE_FORMAT,
    DISPLAY_TIME_FORMAT,
    MENUS,
    PATH_RECEIPTS_RECEIPT,
    PAYMENT_TYPES,
    SALE_TYPE_ID_RETURN
} from "../../constants";
import withUserAuth from "../../Behavior/withUserAuth";
import Spinner from "../../Components/Spinner";
import TextSearch from "../../Components/TextSearch";
import {useDebouncedEffect} from "../../Hooks/useDebouncedEffect";
import moment from 'moment';
import SideMenu from "../../Components/SideMenu";


const PAGE_SIZE = 50;

const Receipts = ({onSidePanelOpen}) => {
    const [pageNo, setPageNo] = useState(1);
    const [receipts, setReceipts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [showSearch, setShowSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [hasMore, setHasMore] = useState(true);

    const sendAPIRequest = async (page = pageNo, searchstring = searchText) => {
        const params = {page, pagesize: PAGE_SIZE, search: searchstring};
        const {code, data, error} = await getReceipts(params);
        if (code === 200) {
            setHasMore(data.isNextPageExist);
            if (page === 1) {
                setReceipts(data.saleList);
            } else {
                setReceipts([...receipts, ...data.saleList])
            }
            setPageNo((page + 1));
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(() => {
        sendAPIRequest(1, searchText);
    }, []);

    // useEffect(() => {
    //     const root = document.getElementById('appCapsule');
    //     if (!loading && hasMore && root.scrollHeight <= root.clientHeight) {
    //         sendAPIRequest();
    //     }
    // }, [loading, hasMore]);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest(1);
    }

    const handleDelete = async ({accountid, name}) => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
        if (isConfirm) {
            setLoading(true);
            const {code, error} = await deleteClient({accountid})
            if (code === 200) {
                await sendAPIRequest();
            } else {
                setLoading(false);
                setError({message: error, retry: false});
            }
        }
    }

    const onSearchClick = () => {
        setShowSearch(true);
    }

    const onSearchClose = () => {
        setShowSearch(false);
        setSearchText('');
        setLoading(true);
        sendAPIRequest(1, '');
    }

    useDebouncedEffect(async () => {
            setLoading(true);
            await sendAPIRequest(1,searchText);
    }, [searchText], 500)

    const onRetry = !!error.retry ? handleRetry : null;

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Receipts"} onSidePanelClick={onSidePanelOpen} onSearchClick={onSearchClick}/>
            <SideMenu selected={MENUS.RECEIPTS}/>
            <TextSearch isVisible={showSearch} onClose={onSearchClose} onTextChange={setSearchText}
                        searchText={searchText}/>
            <div id='appCapsule' className={"mt-76"}>
                {/* Items */}
                {/* details */}
                <div className="section">
                    <InfiniteScroll
                        className="listview receipts-filter filter-items image-listview no-line no-arrow inset"
                        dataLength={receipts.length}
                        next={sendAPIRequest}
                        hasMore={hasMore}
                        loader={<Spinner/>}
                    >
                        {
                            receipts.map(sale => {
                                const paymentType = sale?.salePayments?.[0]?.paymentTypeId || 0;
                                const className = sale.invoiceTypeId == SALE_TYPE_ID_RETURN ? 'text-danger' : 'text-success';
                                return (
                                    <li key={sale.invoiceId} className="items-card card card-border" data-item="today">
                                        <Link to={PATH_RECEIPTS_RECEIPT} state={{sale}} className="item">
                                            <div className="in">
                                                <div>
                                                    <h4>{moment(sale.invoiceDate).format(DISPLAY_DATE_FORMAT)}</h4>
                                                    <p>{moment(sale.invoiceDate).format(DISPLAY_TIME_FORMAT)}</p>
                                                </div>
                                                <div>
                                                    <h4 className={className}>{sale.grandTotal}</h4>
                                                    <p>{PAYMENT_TYPES[paymentType]}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                )
                            })
                        }
                        {
                            receipts.length === 0 &&
                            <span className={"fontsize-heading"} style={{textAlign: 'center'}}>No result Found</span>
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(Receipts);
