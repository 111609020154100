import {useEffect, useState} from 'react';
import Header from "../../Components/Header";
import withUserAuth from "../../Behavior/withUserAuth";
import {deleteModifier, getAllModifiers,} from "../../API";
import ErrorDialog from "../../Components/ErrorDialog";
import Loader from "../../Components/Loader";
import {Link} from "react-router-dom";
import {MENUS, PATH_SETTINGS_MODIFIER} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const Modifiers = () => {
    const [modifiers, setModifiers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});

    const sendAPIRequest = async () => {
        const {code, data, error} = await getAllModifiers();
        if (code === 200) {
            setModifiers(data.modifiers);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest();
    }, []);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const handleDelete = async ({modifierId, name}) => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
        if (isConfirm) {
            setLoading(true);
            const {code, error} = await deleteModifier({modifierId})
            if (code === 200) {
                await sendAPIRequest();
            } else {
                setLoading(false);
                setError({message: error, retry: false});
            }
        }
    }

    const onRetry = !!error.retry ? handleRetry : null;

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Modifiers"} hasBack/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                {/* Items */}
                <div className="section item-amend-category mt-2">
                    {/* details */}
                    <ul className="listview image-listview separate-list inset no-line no-arrow m-0">
                        {
                            modifiers.map(modifier => (
                                <li key={modifier.modifierId}>
                                    <Link to={{pathname: PATH_SETTINGS_MODIFIER, search: `id=${modifier.modifierId}`}}
                                          state={{modifier}} className="item">
                                        <div className="in">
                                            <div>{modifier.name}</div>
                                        </div>
                                    </Link>
                                    {/*<span onClick={() => handleDelete(modifier)}*/}
                                    {/*      className="delete-item py-3 ps-1 pe-3">*/}
                                    {/*  <ion-icon name="close"/>*/}
                                    {/*</span>*/}
                                </li>
                            ))
                        }

                    </ul>
                    <Link to={PATH_SETTINGS_MODIFIER} className="btn add-tag-anchor btn-primary mt-1">
                      <span>
                        <ion-icon name="add-circle"/>
                      </span>
                        Add Modifier
                    </Link>
                </div>
            </div>

        </>
    )
}

export default withUserAuth(Modifiers);
