import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';

const ReportItem = ({title, value}) => {

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{width: '100px', height: '100px'}}>
                <CircularProgressbarWithChildren
                    value={value}
                    strokeWidth={8}
                    styles={buildStyles({
                        textColor: "#1dcc70",
                        pathColor: "#1dcc70",
                        trailColor: "#dadada",

                    })}
                >
                    <div className="circle" id="circleAvgMarginPayment">
                        <strong>{value}%</strong>
                    </div>
                </CircularProgressbarWithChildren>
            </div>
            <h4>{title}</h4>
        </div>

    );
};

export default ReportItem;
