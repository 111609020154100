import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import withUserAuth from '../../Behavior/withUserAuth'
import {deleteItem, getAllItems, updateItemOrder} from "../../API";
import Loader from "../../Components/Loader";
import ItemsList from "../../Components/ItemsList";
import Header from "../../Components/Header";
import TextSearch from "../../Components/TextSearch";
import ErrorDialog from "../../Components/ErrorDialog";
import {MENUS, PATH_ITEM} from "../../constants";
import {useObserver} from "mobx-react-lite";
import {useItemStore} from "../../Store";
import {cleanObj, showSuccessToast} from "../../Utils";
import SideMenu from "../../Components/SideMenu";

const Items = ({onSidePanelOpen}) => {
    const navigate = useNavigate();
    // const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [searchText, setSearchText] = useState('');

    const itemStore = useItemStore();

    const sendAPIRequest = async () => {
        const res = await getAllItems();
        if (res.code === 200) {
            itemStore.setAll(res.data)
            itemStore.setAllItems(res.data.allitems)
        } else {
            setError(res.error);
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest();
    }, []);

    const handleRetry = async () => {
        setError('');
        setLoading(true)
        await sendAPIRequest();
    }

    const onSearchClick = () => {
        setShowSearch(true);
    }

    const onSearchClose = () => {
        setShowSearch(false);
        setSearchText('');
    }

    const onItemDelete = async ({itemid, name}) => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
        if (isConfirm) {
            setLoading(true);
            const {code, error} = await deleteItem({itemid})
            if (code === 200) {
                await sendAPIRequest();
            } else {
                setLoading(false);
                setError(error);
            }
        }
    }

    const onItemEdit = (item, category) => {
        item = cleanObj(item);
        category = cleanObj(category);
        navigate(PATH_ITEM, {state: {item, category}, search: `id=${item.itemid}`});
    }

    const handleSortEnd = async ({newIndex, item, oldItem}) => {
        const previousId = oldItem.itemid;
        const DisplayOrder = newIndex + 1;
        const itemid = item.itemid;
        setLoading(true);
        const {code, message, error} = await updateItemOrder({previousId, DisplayOrder, itemid});
        if (code == 200) {
            showSuccessToast(message);
            await sendAPIRequest();
        } else {
            setLoading(false)
            setError(error);
        }
    }

    return useObserver(() => {
        if (loading) return <Loader/>
        return (
            <div>
                {!!error &&
                    <ErrorDialog isOpen={true} content={error} onClose={() => setError('')} onRetry={handleRetry}/>}
                <Header title={"Items"} onSidePanelClick={onSidePanelOpen} onSearchClick={onSearchClick}/>
                <SideMenu selected={MENUS.ITEMS}/>
                <TextSearch isVisible={showSearch}
                            onClose={onSearchClose}
                            onTextChange={setSearchText}
                            searchText={searchText}
                />
                <ItemsList items={itemStore.getAllItems()}
                           searchText={searchText}
                           onItemEdit={onItemEdit}
                           onItemDelete={onItemDelete}
                           onSortEnd={handleSortEnd}
                           isEditMode
                />
            </div>
        )
    });
}

export default withUserAuth(Items);

