import {useEffect, useState} from 'react';
import {v1 as uuidv1} from "uuid";
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {MENUS, PATH_POS_RECEIPT} from "../../constants";
import {createSale, getTerminalInfo} from "../../API";
import {cleanObj, showSuccessToast} from "../../Utils";
import {useNavigate} from "react-router-dom";
import {usePosStore} from "../../Store";
import {CollectPayment} from "../../Scripts/stripe-terminal";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import SideMenu from "../../Components/SideMenu";

const POSCard = () => {
    const posStore = usePosStore();
    const sale = posStore.getFinalSale();
    const navigate = useNavigate();
    const grandTotal = parseFloat(sale.grandTotal);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [terminal, setTerminal] = useState({});

    const sendAPIRequest = async () => {
        const {code, data, error} = await getTerminalInfo();
        if (code === 200) {
            setTerminal(data.terminalReader || {})
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(() => {
        sendAPIRequest();
    }, []);

    const handleSave = async () => {
        const salePayments = [];

        if (Object.keys(terminal).length > 0) {
            const payment = CollectPayment();
        } else {
            salePayments.push({
                guid: uuidv1(),
                paymentMethodId: 0,
                paymentTypeId: 2,
                cardTypeId: -1,
                amount: grandTotal,
                transactionCode: '',
                transactionInfo: '',
            })
        }
        const saleReq = {...sale, salePayments};
        setLoading(true);
        const {code, error, data, message} = await createSale(saleReq);
        if (code === 200) {
            showSuccessToast(message);
            const newSale = {...saleReq, invoiceNumber: data.invoiceNumber}
            posStore.processSaleResponse(saleReq, data);
            navigate(PATH_POS_RECEIPT, {state: {receipt: data, sale: cleanObj(newSale)}})
        } else {
            setLoading(false);
            setError({message: error});
        }

    }

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const onRetry = !!error.retry ? handleRetry : null;

    return <>
        {loading && <Loader/>}
        {!!error.message &&
            <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
        <Header title={"Card Payment"} hasBack/>
        <SideMenu selected={MENUS.POS}/>
        <div id="appCapsule">
            {/* Cash payment Amount */}
            <div className="row section sell-card-payment mt-4">
                <div className="col-4 offset-4">
                    <div className="single-cash-payment">
                        <p>Amount</p>
                        <h2>{grandTotal}</h2>
                    </div>
                </div>
            </div>
            {/* * Cash payment Amount */}
            {/* Buttons */}
            <div className="section mt-4">
                <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Confirm</a>
            </div>
            {/* * Buttons */}
        </div>
    </>
}

export default withUserAuth(POSCard);
