import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {PATH_HOME} from "../constants";
import {connectInStore, disconnectInStore, firstSetupDetail, getConnectStatusInStore} from "../API";
import Header from "../Components/Header";
import Loader from "../Components/Loader";
import ErrorDialog from "../Components/ErrorDialog";
import {setOrgData, setUserData} from "../DB";

const SignupStripe = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const signUpDetail = location?.state;

    const [isConnected, setIsConnected] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});

    const sendAPIRequest = async () => {
        const res = await getConnectStatusInStore();
        if (res.code === 200) {
            setIsConnected(res.data.isConnected);
        } else {
            setError({message: res.error, retry: true});
        }
        setLoading(false);
    }

    useEffect(() => {
        sendAPIRequest();
    }, [])

    const handleSave = async () => {
        setLoading(true);
        const res = await firstSetupDetail(signUpDetail);
        setLoading(false);
        if (res.code === 200) {
            setUserData(res.data.user);
            setOrgData(res.data.company);
            navigate(PATH_HOME);
        } else {
            setError({message: res.error});
        }
    }

    const handleConnect = async () => {
        setLoading(true);
        const ReturnURL = window.location.href;
        const {code, data, error} = await connectInStore({ReturnURL});
        if (code === 200) {
            window.location.href = data.redirectURL;
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    const handleDisconnect = async () => {
        setLoading(true);
        const {code, error} = await disconnectInStore();
        if (code === 200) {
            setIsConnected(false);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const onRetry = !!error.retry ? handleRetry : null;


    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            {/* App Header */}
            <Header hasBack title={"Stripe.com"}/>
            {/* App Capsule */}
            <div id="appCapsule">
                <div className="section mt-2">
                    <h2>We’ve partnerred with Stripe, to provide you a perfect payment experience. You can operate your
                        system without Stripe, but most of our clients choose to connect with Stripe. </h2>
                </div>
                <div className="section stripe-wrapper mt-3">
                    {
                        !isConnected ?
                            <a onClick={handleConnect} className="stripe-connect"><span>Connect with</span></a>
                            : <a onClick={handleDisconnect} className="stripe-connect"><span>Disconnect</span></a>

                    }
                    <h4 className="stripe-massage">Your Stripe Account is verified and all your In-store Payments, will
                        be done through Stripe Processing. </h4>
                </div>
                <div className="section mt-2">
                    <h4>Nah, I’ll sort this out later.</h4>
                </div>
                <div className="section mt-4">
                    <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Continue</a>
                </div>
            </div>
        </>

    )
}

export default SignupStripe;
