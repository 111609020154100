import {useNavigate} from "react-router-dom";
//import {Document, Page} from 'react-pdf';
import {useInvoiceStore} from "../../Store";
import {useState} from "react";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import SideMenu from "../../Components/SideMenu";
import {MENUS, PATH_INVOICES} from "../../constants";
import {removeDraftInvoice, sendInvoice, sendStripeInvoice, voidInvoice } from "../../API";
import {formatStripeAmount, formatStripeDate} from "../../Utils";

const InvoiceView = () => {
    
    const navigate = useNavigate();
    const invoiceStore = useInvoiceStore();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [tab, setTab] = useState('email');


    const invoice = invoiceStore.getInvoice();
    const [invoiceImg, setInvoiceImg] = useState(invoice.hostedInvoiceUrl);
    const [invoiceObj, setInvoiceObj] = useState(invoice);
    
    const handlePDFClick = () => {
        if (invoice && invoice.invociePdf) {
            document.location = invoice.invociePdf;
        }
    }
    const handleDeleteClick = async ()=>{
        setLoading(true);
        const params = {invoiceid: invoice.id};
        const {code, error} = await removeDraftInvoice(params);
        if (code === 200) {
            navigate(PATH_INVOICES);
            invoiceStore.clearStore();
        } else {
            setError(error);
        }
        setLoading(false);
    }
    const handleVoidClick = async ()=>{
        setLoading(true);
        const params = {invoiceid: invoice.id};
        const {code, error} = await voidInvoice(params);
        if (code === 200) {
            navigate(PATH_INVOICES);
            invoiceStore.clearStore();
        } else {
            setError(error);
        }
        setLoading(false);
    }

    const handleSendClick = async ()=>{
        setLoading(true);
        const params = {invoiceid: invoice.id};
        const {code, error, data} = await sendStripeInvoice(params);
        if (code === 200) {
            invoiceStore.setInvoice(data.invoice);
            setInvoiceObj(data.invoice);
            setInvoiceImg(data.invoice.hostedInvoiceUrl);
        } else {
            setError(error);
        }
        setLoading(false);
    }

    const customButton = ()=>{


        if(invoice.status == "draft")
            return <a onClick={handleDeleteClick} className="btn btn-primary btn-shadow btn-danger btn-lg btn-block">Delete</a> 
        else if(invoice.status == "open")
            return <a onClick={handleVoidClick} className="btn btn-primary btn-shadow btn-danger btn-lg btn-block">Void</a> 
        else 
            return <a className="btn btn-primary btn-shadow btn-secondary btn-lg btn-block">Void</a>
    }

    // const handleClick = async ({ externalAccountId }) => {
    //     setLoading(true);
    //     const {code, data, error} = await addInvoice({customerId: externalAccountId});
    //     if (code === 200) {
    //         invoiceStore.setInvoice(data.invoice);
    //         navigate(-1);
    //     } else {
    //         setError(error);
    //     }
    //     setLoading(false);
    // }

    //
    //setInvoiceImg()
    //const params = {id: invoice.id, url: invoice.hostedInvoiceUrl};
    // const rr = getInstance(getAuthToken()).get('InvoiceStripe/getInvoiceImage',{params}).then((response)=>
    // {   
    //     const data = `data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`;
    //     this.setInvoiceImg(data)
    // });
    //console.log(rr,"rr");
    // fetch('http://localhost:8181/images/appliance-OTHER.svg', {
    //      method: "GET",
    //      headers: { Authorization: 'Bearer eyJhbGciOiJIU' }
    // }).then((response) => {
    //     const data = `data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`;
    //         this.setState({ imgSrc: data });
    // });
    
    console.log('invoice.hostedInvoiceUrl', invoice.invociePdf);
    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header title={"Preview & Send"}/>
            <SideMenu selected={MENUS.INVOICES}/>
            <div id="appCapsule">

                <div className="category-info-wrapper">
                    {/* Category */}
                    <ul id="categories" className="categories ps-2 py-1 pe-4">
                        <li className={`category ${tab === 'email' ? 'category-active' : ''}`}>
                            <a onClick={() => setTab('email')}>Email</a>
                        </li>
                        <li className={`category ${tab === 'pdf' ? 'category-active' : ''}`}>
                            <a onClick={() => handlePDFClick()}>PDF</a>
                        </li>

                    </ul>
                        {<div className=" pe-2"><span className='invoice-number'>{invoice.invoiceNumber} </span> {invoice && invoice.status && <span className={"badge badge-primary invoice-status-" + invoice.status}>{invoice.status}</span>}</div>}

                </div>
                <div className="section filter-items invoice-email-pdf-wrapper">
                    {/* Email */}
                    {tab === 'email' ? (
                        <div className="sell-preview sell-preview-email mt-2">
                            {false && <div className="due-card-wrapper">
                                {/* Due */}
                                <div className="due-in">
                                    <h2>Due in</h2>
                                    <a href="#">
                                        <span>5 Days </span>
                                        <ion-icon name="chevron-down"/>
                                    </a>
                                </div>
                                {/* * Due */}
                                {/* card payment switcher */}
                                <div className="allow-card">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="switchCardPayment"/>
                                        <label className="form-check-label" htmlFor="switchCardPayment"
                                               id="itemsSwitchToggler"/>
                                    </div>
                                    <div className="medium ms-2">Card Payment</div>
                                </div>
                                {/* * card payment switcher */}
                            </div>
                                }
                            <div className="card card-border p-2 mt-2">
                                {invoiceImg && <img src={`https://shot.screenshotapi.net/screenshot?token=FYJ6CNN-V20MMJE-Q9QMYE1-CB49SQ8&url=${encodeURI(invoiceImg)}&width=430&full_page=true&output=image&file_type=png&wait_for_event=load`} alt="invoice" className="invoice-image" />}
                                {!invoiceImg &&
                                   <div>                                       
                                   <div className="ContentCard ContentCard-mobileNoMargin">
                                        {invoice.status && <p><span className={"badge badge-primary invoice-status-" + invoice.status}>{invoice.status}</span></p>}
                                      <div className="InvoiceSummary InvoiceSummary-prePayment">
                                         <div className="flex-container direction-row justify-content-space-between">
                                            <div className="flex-container direction-column">
                                               <h1 className="Text Text-color--gray800 Text-fontSize--36 Text-fontWeight--600">
                                                  <div className="flex-container spacing-8 direction-row align-items-center">
                                                     <div className="flex-item width-fixed" data-testid="invoice-total-amount">
                                                        <span>{formatStripeAmount(invoice.total)}</span>
                                                     </div>
                                                  </div>
                                               </h1>
                                               <div className="flex-container spacing-8 direction-row align-items-center">
                                                  <div className="flex-item width-grow">
                                                     <span className="Text Text-color--gray500 Text-fontSize--14 Text-fontWeight--500">{formatStripeDate(invoice.dueDate,"Due ")}</span>
                                                  </div>
                                                  <div className="flex-item width-fixed">
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                      <div className="App-InvoiceDetails flex-item width-grow flex-container direction-column">
                                         <table className="InvoiceDetails-table">
                                            <tbody>
                                               <tr className="LabeledTableRow LabeledTableRow--wide">
                                                  <td style={{verticalAlign: 'top', width: '1px', whiteSpace: 'nowrap'}}><span className="Text Text-color--gray400 Text-fontSize--14">To </span></td>
                                                  <td style={{verticalAlign: 'top'}}><span className="Text Text-color--default Text-fontSize--14 margin-left--15">{invoice.customerName}</span></td>
                                               </tr>
                                               <tr className="LabeledTableRow LabeledTableRow--wide">
                                                  <td style={{verticalAlign: 'top', width: '1px', whiteSpace: 'nowrap'}}><span className="Text Text-color--gray400 Text-fontSize--14">From </span></td>
                                                  <td style={{verticalAlign: 'top'}}><span className="Text Text-color--default Text-fontSize--14 margin-left--15">{invoice.accountName}</span></td>
                                               </tr>
                                                
                                            </tbody>
                                         </table>
                                      </div>
                                   </div>
                                </div>
                                }

                            </div>
                            <div className="row mt-4 mb-2">
                                <div className="col-6">
                                    {customButton()}
                                </div>
                                {/* <div className="col-4">
                                    <a href="./invoice-choose-payment.html"
                                       className="btn btn-primary btn-shadow btn-lg btn-block">Charge
                                        Now</a>
                                </div> */}
                                <div className="col-6">
                                    <a onClick={handleSendClick} className="btn btn-primary btn-shadow btn-lg btn-block">Send</a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="sell-preview sell-preview-pdf">
 
                        </div>
                    )}

                </div>
            </div>

        </>
    )
}

export default withUserAuth(InvoiceView);
