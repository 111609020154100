import {useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import _ from 'lodash';
import withUserAuth from "../../Behavior/withUserAuth";
import {addModifier, updateModifier} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import {showErrorToast} from "../../Utils";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";


const Modifier = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const modifier = location.state?.modifier;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [name, setName] = useState(() => {
        if (modifier) {
            return modifier.name;
        }
        return '';
    });
    const [isOptional, setIsOptional] = useState(() => {
        if (modifier) {
            return !!modifier.isOptional;
        }
        return false;
    })
    const [selectionAllowed, setSelectionAllowed] = useState(() => {
        if (modifier) {
            return modifier.selectionAllowed;
        }
        return 0;
    })
    const [modifierOptions, setModifierOptions] = useState(() => {
        if (modifier && modifier.modifierOptions?.length > 0) {
            return modifier.modifierOptions.map(({modifierOptionId, name, price}) => ({modifierOptionId, name, price}));
        }
        return [{name: '', price: ''}];
    })

    const handleAddOption = () => {
        setModifierOptions(options => [...options, {name: '', price: ''}]);
    }

    const handleDeleteOption = (index) => {
        if (modifierOptions.length > 1) {
            const options = _.remove([...modifierOptions], ((_, i) => index !== i));
            setModifierOptions(options);
        } else {
            showErrorToast('Should have at least 1 option');
        }

    }

    const handleOptionValue = (index, key, value) => {
        setModifierOptions(options => {
            let tempArray = [...options]
            tempArray[index][key] = value;
            return tempArray;
        });
    }

    const handleSave = async () => {
        let res;
        setLoading(true);
        if (modifier) {
            res = await updateModifier({
                modifierId: modifier.modifierId,
                name,
                isOptional,
                selectionAllowed,
                modifierOptions
            });
        } else {
            res = await addModifier({name, isOptional, selectionAllowed, modifierOptions});
        }
        setLoading(false);
        if (res.code === 200) {
            navigate(-1);
        } else {
            setError(res.error);
        }

    }

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header title={"Modifier"} hasBack/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                {/* Items */}
                <div className="section item-amend-modifier-edit mt-2">
                    {/* Set Name */}
                    <div className="card card-border">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="variationName">
                                            Modifier Set Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="variationName"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            maxLength={200}
                                        />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"/>
                                        </i>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* * Set Name */}
                    {/* Options */}
                    <ul className="listview transparent darkmode-transparent flush mt-2">
                        <li>
                            <div>Is Optional</div>
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="SwitchCheckMore"
                                    checked={isOptional}
                                    onChange={(e) => setIsOptional(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="SwitchCheckMore"/>
                            </div>
                        </li>
                    </ul>
                    <div className="card card-border">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="selectionAllowed">
                                            Selection Allowed
                                        </label>
                                        <select
                                            className="form-control custom-select"
                                            id="selectionAllowed"
                                            value={selectionAllowed}
                                            onChange={(e) => setSelectionAllowed(e.target.value)}
                                        >
                                            <option value={0}/>
                                            <option value={1}>Select 1</option>
                                            <option value={2}>Select 2</option>
                                            <option value={3}>Select 3</option>
                                            <option value={4}>Select 4</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="section-title">Options</div>
                    {
                        modifierOptions.map((option, index) => {
                            return (
                                <div className="card card-border mb-2" key={index}>
                                    {
                                        modifierOptions.length > 1 && (
                                            <span onClick={() => handleDeleteOption(index)} id="optionCardDelete">
                                                <ion-icon name="close"/>
                                            </span>
                                        )
                                    }
                                    <div className="delete-modal"/>
                                    <div className="card-body">
                                        <form>
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="label" htmlFor="Name">
                                                        Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Name"
                                                        value={option.name}
                                                        onChange={(e) => handleOptionValue(index, 'name', e.target.value)}
                                                        maxLength={200}
                                                    />

                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="label" htmlFor="Price">
                                                        Price (Optional)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Price"
                                                        value={option.price}
                                                        onChange={(e) => handleOptionValue(index, 'price', e.target.value)}
                                                        maxLength={10}
                                                    />

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <a
                        onClick={handleAddOption}
                        className="btn add-tag-anchor btn-primary mt-1"
                    >
                        <span>
                            <ion-icon name="add-circle"/>
                        </span>
                        Add Option
                    </a>
                    {/* * Options */}
                    <div className="mt-4">
                        <a
                            onClick={handleSave}
                            className="btn btn-primary btn-shadow btn-lg btn-block"
                        >
                            Save
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(Modifier);
