import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import _ from 'lodash';

const ModifierItem = forwardRef(({
                                     index,
                                     saleItem,
                                     modifier,
                                     currentModifier,
                                     handleModifierHeaderClick,
                                     onModifierSelected,
                                     selectedModifier
                                 }, ref) => {

    const [values, setValues] = useState(() => saleItem?.selectedModifiers?.filter(m => m.modifierId === modifier.modifierId) || []);

    const handleModifierChange = (e, {modifierOptionId, name, price}) => {
        const option = {
            modifierId: modifier.modifierId,
            modifierOptionId,
            name,
            price
        };

        if (modifier.selectionAllowed === 1) {
            setValues([option]);
        } else {
            let tempValues = _.cloneDeep([...values]);
            if (e.target.checked) {
                tempValues.push(option);
            } else {
                const index = _.findIndex(tempValues, ({modifierOptionId}) => modifierOptionId === option.modifierOptionId);
                tempValues.splice(index, 1);
            }
            setValues(tempValues);
        }
    }

    useEffect(() => {
        onModifierSelected();
    }, [values])

    useImperativeHandle(
        ref,
        () => ({
            modifierId: modifier.modifierId,
            values,
            getSelectedItemPrice: () => {
                return values.reduce((previousValue, option) => (previousValue + option.price), 0)
            },
            getValues: () => {
                if (values.length > 0) {
                    return values;
                } else if (modifier.isOptional) {
                    return [];
                }

                throw `Please select ${modifier.name}`;
            }
        }),
        [values]);

    return (
        <>
            <div className="variation-heading">
                <h3 className="variation-title">{modifier.name}</h3>
            </div>
            {
                modifier.modifierOptions.map(option => {
                    const isChecked = _.findIndex(values, ({modifierOptionId}) => modifierOptionId === option.modifierOptionId) >= 0;
                    return (
                        <div key={option.modifierOptionId}
                             className={`single-modification ${modifier.selectionAllowed > 1 && 'modifiacation-checkbox'}`}>
                            <div className="card-title mb-0">{option.price != 0 && option.price}</div>
                            <div className="form-check mb-1">
                                <input
                                    type={modifier.selectionAllowed === 1 ? "radio" : "checkbox"}
                                    className="form-check-input"
                                    onChange={(e) => handleModifierChange(e, option)}
                                    value={option.modifierOptionId} checked={isChecked}
                                    id={`${saleItem.id}-${option.modifierOptionId}`}
                                />
                                <label
                                    className={`form-check-label`}
                                    htmlFor={`${saleItem.id}-${option.modifierOptionId}`}>{option.name}</label>
                            </div>
                        </div>
                    )
                })
            }

        </>

        // <div key={index} data-optional={modifier.isOptional} className="accordion-item">
        //     <h2 className="accordion-header" id={`modifier${modifier.modifierId}`}>
        //         <button id={`headerBtn-${index}`}
        //                 className={"accordion-button" + (index === currentModifier ? '' : ' collapsed')}
        //                 disabled={index == currentModifier ? false : false} type="button"
        //                 onClick={(e) => handleModifierHeaderClick(e, index)}>
        //             {modifier.name}
        //         </button>
        //     </h2>
        //     <div className={"accordion-collapse collapse" + (index == currentModifier ? ' show' : '')}
        //          aria-labelledby={`modifier${modifier.modifierId}`} data-bs-parent="#modifiers">
        //         <div className="accordion-body modification optional input-list w-100">
        //             {
        //                 modifier.modifierOptions.map(option => {
        //                     const isChecked = _.findIndex(values, ({modifierOptionId}) => modifierOptionId === option.modifierOptionId) >= 0;
        //                     return (
        //                         <div key={option.modifierOptionId} className="single-modification">
        //                             <div className="card-title">{option.price != 0 && option.price}</div>
        //                             <div className="form-check">
        //                                 <input type={modifier.selectionAllowed === 1 ? "radio" : "checkbox"}
        //                                        className="form-check-input" onChange={(e) => {
        //                                     handleModifierChange(e, option)
        //                                 }} value={option.modifierOptionId} checked={isChecked}
        //                                        id={`${saleItem.id}-${option.modifierOptionId}`}/>
        //                                 <label
        //                                     className={`form-check-label ${modifier.selectionAllowed === 1 ? 'radio' : 'checkbox'} `}
        //                                     htmlFor={`${saleItem.id}-${option.modifierOptionId}`}>{option.name}</label>
        //                             </div>
        //
        //                         </div>
        //                     )
        //                 })
        //             }
        //         </div>
        //     </div>
        // </div>
    )

});

export default ModifierItem;
