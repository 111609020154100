import {useEffect, useState} from 'react';
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {getDeliveryOptions, setDeliveryOptions} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {useNavigate} from "react-router-dom";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const OPTIONS = [{id: 'DineIn', label: 'Dine In'}, {id: 'TakeAway', label: 'Take Away'}, {
    id: 'Delivery',
    label: 'Delivery'
}];

const Delivery = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [defaultOption, setDefaultOption] = useState(OPTIONS[0].id);
    const [dineIn, setDineIn] = useState(false);
    const [takeAway, setTakeAway] = useState(false);
    const [delivery, setDelivery] = useState(false);

    const sendAPIRequest = async () => {
        const {code, data, error} = await getDeliveryOptions();
        if (code === 200) {
            const {deliveyOptions} = data;
            setDefaultOption(deliveyOptions.defaultOption);
            setDineIn(JSON.parse(deliveyOptions.dineIn.toLowerCase()));
            setTakeAway(JSON.parse(deliveyOptions.takeAway.toLowerCase()));
            setDelivery(JSON.parse(deliveyOptions.delivery.toLowerCase()));
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(() => {
        sendAPIRequest();
    }, []);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }


    const handleSave = async () => {

        const req = {
            DefaultOption: defaultOption,
            DineIn: dineIn,
            TakeAway: takeAway,
            Delivery: delivery
        }
        setLoading(true);
        const {code, error} = await setDeliveryOptions(req);
        if (code === 200) {
            navigate(-1);
        } else {
            setError({message: error, retry: false});
        }
        setLoading(false);
    }

    const onRetry = !!error.retry ? handleRetry : null;

    return <>
        {loading && <Loader/>}
        {!!error.message &&
            <ErrorDialog isOpen content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
        <Header title={"Delivery Options"} hasBack/>
        <SideMenu selected={MENUS.SETTINGS}/>
        <div id="appCapsule">
            {/* Items */}
            <div className="section mt-2">
                {/* Set Name */}
                <div className="card card-border">
                    <div className="card-body">
                        <form>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="hardwareType">Default Options</label>
                                    <select className="form-control custom-select" id="hardwareType"
                                            value={defaultOption}
                                            onChange={(e) => setDefaultOption(e.target.value)}>
                                        {OPTIONS.map(({id, label}) => <option key={id}
                                                                              value={id}>{label}</option>)}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/* * Set Name */}
                <ul className="listview separate-list image-listview transparent no-line flush mt-2">
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    Dine In
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="SwitchDineIn"
                                           checked={dineIn}
                                           onChange={(e) => setDineIn(e.target.checked)}/>
                                    <label className="form-check-label" htmlFor="SwitchDineIn"/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    Take Away
                                </div>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="switchTakeAway"
                                        checked={takeAway}
                                        onChange={(e) => setTakeAway(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="switchTakeAway"/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    Delivery
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="switchDelivery"
                                           checked={delivery}
                                           onChange={(e) => setDelivery(e.target.checked)}/>
                                    <label className="form-check-label" htmlFor="switchDelivery"/>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="mt-2">
                    <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block mt-2">Save</a>
                </div>
            </div>
        </div>


    </>
}

export default withUserAuth(Delivery);
