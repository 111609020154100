import {useEffect, useState} from 'react';
import withUserAuth from "../../Behavior/withUserAuth";
import {getOrderAppDetails} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import QRCode from "react-qr-code";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";


const OrderQR = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [qrCodeUrl, setQrCodeUrl] = useState('');


    const sendAPIRequest = async () => {
        const {code, data, error} = await getOrderAppDetails();
        if (code === 200) {
            const {orderApp} = data;
            setQrCodeUrl(orderApp.orderAppURL);
        } else {
            setError(error);
        }
        setLoading(false);

    }

    useEffect(async () => {
        await sendAPIRequest();
    }, []);

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header title={"QR"} hasBack/>
            <SideMenu selected={MENUS.ITEMS}/>
            <div id="appCapsule">
                <div className="qr mt-4">
                    <div className="section">
                        <div className="card card-border">
                            <div className="card-body">
                                <QRCode value={qrCodeUrl}/>
                            </div>
                        </div>
                        <h3 className="text-center mt-4 px-3">Show this QR Code, to Client to start new order</h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(OrderQR);

