import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {MENUS, PATH_SETTINGS_ACCEPT_PAYMENTS, PATH_SETTINGS_IN_STORE_PAYMENTS} from "../../constants";
import {useEffect, useState} from "react";
import {
    connectInStore,
    connectOnline,
    disconnectInStore,
    disconnectOnline,
    getConnectStatusInStore,
    getConnectStatusOnline
} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {matchPath, useLocation} from "react-router-dom";
import SideMenu from "../../Components/SideMenu";

const PaymentsDetail = () => {
    const {pathname} = useLocation();
    const inStore = !!matchPath(pathname, PATH_SETTINGS_IN_STORE_PAYMENTS);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [isConnected, setIsConnected] = useState(false);

    const sendAPIRequest = async () => {
        const {code, data, error} = inStore ? await getConnectStatusInStore() : await getConnectStatusOnline();
        if (code === 200) {
            setIsConnected(data.isConnected);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(() => {
        sendAPIRequest();
    }, []);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const onRetry = !!error.retry ? handleRetry : null;

    const handleConnect = async () => {
        setLoading(true);
        const ReturnURL = window.location.href;
        const {code, data, error} = inStore ? await connectInStore({ReturnURL}) : await connectOnline({ReturnURL});
        if (code === 200) {
            window.location.href = data.redirectURL;
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    const handleDisconnect = async () => {
        setLoading(true);
        const {code, error} = inStore ? await disconnectInStore() : await disconnectOnline();
        if (code === 200) {
            setIsConnected(false);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={inStore ? 'In Store Payments' : 'Online Payments'} hasBack
                    backURL={PATH_SETTINGS_ACCEPT_PAYMENTS}/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                <div className="section item-taxes mt-2">
                    <div className="card card-border">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="role">Provider</label>
                                        <select className="form-control custom-select" id="role">
                                            <option value={1}>Stripe</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="section stripe-wrapper text-center mt-3">
                        {
                            !isConnected &&
                            <a onClick={handleConnect} className="stripe-connect"><span>Connect with</span></a>

                        }
                        {
                            isConnected && <>
                                <h4 className="stripe-massage">Your Stripe Account is verified and all
                                    your {inStore ? 'In - store' : 'Online'}
                                    Payments, will
                                    be done through Stripe Processing. </h4> <a onClick={handleDisconnect}>Disconnect
                                Stripe</a>
                            </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(PaymentsDetail);
