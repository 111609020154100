import {useEffect, useState} from 'react';
import Header from "../../Components/Header";
import withUserAuth from "../../Behavior/withUserAuth";
import {getTerminalInfo} from "../../API";
import ErrorDialog from "../../Components/ErrorDialog";
import Loader from "../../Components/Loader";
import {Link, useNavigate} from "react-router-dom";
import {MENUS, PATH_SETTINGS_HARDWARE} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const Hardwares = () => {
    const navigate = useNavigate();
    const [terminal, setTerminal] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});

    const sendAPIRequest = async () => {
        const {code, data, error} = await getTerminalInfo();
        if (code === 200) {
            setTerminal(data.terminalReader || {})
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest();
    }, []);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const deleteTerminal = async () => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${terminal.readerName}?`);
        if (isConfirm) {
            setLoading(true);
            const {code, error} = await setTerminal({});
            if (code === 200) {
                await sendAPIRequest();
            } else {
                setLoading(false);
                setError({message: error, retry: false});
            }
        }
    }

    const handleHardwareClick = () => {
        navigate(navigate, {state: {terminal}});
    }

    const onRetry = !!error.retry ? handleRetry : null;

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Hardware"} hasBack/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                <div className="section mt-2">
                    <ul className="listview separate-list image-listview no-line inset m-0">
                        {
                            Object.keys(terminal).length > 0 && <li>
                                <div className="item" onClick={handleHardwareClick}>
                                    <div className="icon-box bg-primary">
                                        <ion-icon name="id-card-outline" className="m-0"/>
                                    </div>
                                    <div className="in">
                                        <div>
                                            <h4>{terminal.readerName}</h4>
                                            <h5 className="mb-0">Terminal</h5>
                                        </div>
                                    </div>
                                    <span onClick={deleteTerminal} className="delete-item py-3 ps-1">
                                      <ion-icon name="close"/>
                                    </span>
                                </div>
                            </li>
                        }
                        {/*<li>*/}
                        {/*    <div className="item">*/}
                        {/*        <div className="icon-box bg-primary">*/}
                        {/*            <ion-icon name="print-outline" className="m-0" />*/}
                        {/*        </div>*/}
                        {/*        <div className="in">*/}
                        {/*            <div>*/}
                        {/*                <h4>Star TSSII</h4>*/}
                        {/*                <h5 className="mb-0">Printer</h5>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <span className="delete-item py-3 ps-1">*/}
                        {/*            <ion-icon name="close" />*/}
                        {/*        </span>*/}
                        {/*        <div className="delete-modal" />*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <div className="item">*/}
                        {/*        <div className="icon-box bg-primary">*/}
                        {/*            <ion-icon name="finger-print-outline" className="m-0" />*/}
                        {/*        </div>*/}
                        {/*        <div className="in">*/}
                        {/*            <div>*/}
                        {/*                <h4>Star Scanner</h4>*/}
                        {/*                <h5 className="mb-0">Scanner</h5>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <span className="delete-item py-3 ps-1">*/}
                        {/*          <ion-icon name="close" />*/}
                        {/*        </span>*/}
                        {/*        <div className="delete-modal" />*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                    </ul>
                    <Link to={PATH_SETTINGS_HARDWARE} className="btn add-tag-anchor btn-primary mt-1">
                        <span>
                            <ion-icon name="add-circle"/>
                        </span>
                        Add Hardware
                    </Link>
                </div>

            </div>

        </>
    )
}

export default withUserAuth(Hardwares);
