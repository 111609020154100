import {useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Item from "./Item";
import {PATH_ITEMS_CATEGORIES, PATH_ITEMS_ITEM} from "../constants";
import {cleanObj} from "../Utils";
import CustomItemDialog from "./CustomItemDialog";
import {usePosStore} from "../Store";

const SortableList = SortableContainer(({items}) => {
    return (
        <div className="row sell-items filter-items" style={{width: '100%'}}>
            {items}
            {items && items.length <= 0 &&
                <span className={"fontsize-heading"} style={{textAlign: 'center'}}>No Item Available</span>}
        </div>
    )
});

const SortableItem = SortableElement(Item);

const ItemsList = ({items: allItems, onSortEnd, onItemClick, onItemEdit, onItemDelete, isEditMode, searchText, onPOS,setSearchText}) => {
    const posStore = usePosStore();
    const [searchParams, setSearchParams] = useSearchParams();
    const categoryId = searchParams.get('categoryId');
    
    const [selectedCategory, setSelectedCategory] = useState({});
    const [showCustomDialog, setShowCustomDialog] = useState(false);

    useEffect(() => {
        let tempSelectedCategory;
        if (allItems.length > 0) {
            if (categoryId) {
                tempSelectedCategory = allItems.find(({categoryID}) => categoryID == categoryId);
            }
        }
        tempSelectedCategory = tempSelectedCategory || allItems[0];
        setSelectedCategory(tempSelectedCategory);
    }, [categoryId])

    const onCategorySelected = (id) => () => {
        const urlParams = new URLSearchParams(searchParams);
        urlParams.set('categoryId', id);
        setSearchParams(urlParams);
        if(setSearchText)
            setSearchText('');
    }

    const handleCustomItemClick = () => {
        setShowCustomDialog(true);
    }

    const handleSaveCustomItem = (item) => {
        posStore.addItemToCart(item);
        setShowCustomDialog(false);
    }

 
   
    let cat_items = selectedCategory.items;
    let isSearching = false;
    if(onPOS && searchText && searchText.length > 0)
    {
        cat_items = allItems.map(({ items }) => items).flat();
        isSearching = true;
    }
    const items = cat_items?.filter(({name}) => name === '' || name.toLowerCase().includes(searchText.toLowerCase()))
        .map((item, index) => (
            <SortableItem
                key={item.itemid}
                index={index}
                isEditMode={isEditMode}
                item={item}
                selectedCategory={selectedCategory}
                onItemClick={onItemClick}
                onItemEdit={onItemEdit}
                onItemDelete={onItemDelete}
            />));
            
    //if isSearching no category will be mark active
    const categories = allItems.map(({categoryID, name}) => (
        <li key={categoryID} className={`category ${categoryID === selectedCategory.categoryID && isSearching == false? 'category-active' : ''}`}>
            <a onClick={onCategorySelected(categoryID)}>{name}</a>
        </li>
    ));
    const handleSortEnd = ({oldIndex, newIndex}) => {
        if (newIndex !== oldIndex) {
            const item = selectedCategory.items[oldIndex];
            const oldItem = selectedCategory.items[newIndex];
            onSortEnd({item, oldItem, oldIndex, newIndex});
        }
    }

    return (
        <>
            {showCustomDialog &&
                <CustomItemDialog isOpen onClose={() => setShowCustomDialog(false)} onSave={handleSaveCustomItem}
                                  categoryId={selectedCategory.categoryID}/>}
            <div id="appCapsule" className={"mt-76"}>
                <ul id="categories" className="categories ps-2 py-1 pe-4">
                    {categories}
                    {isSearching &&  <li key={'all'} className="category category-active">
                        <a>All</a>
                    </li>}
                </ul>
                <SortableList
                    items={items}
                    axis={'xy'}
                    onSortEnd={handleSortEnd}
                    useDragHandle
                />
                {
                    !isEditMode && <div className="section mt-4">
                        <a onClick={handleCustomItemClick} className="btn btn-primary btn-shadow btn-lg btn-block">Custom
                            Item</a>
                    </div>
                }
                {
                    isEditMode && <div className="section mt-4">
                        <div className="section mt-4">
                            <Link to={PATH_ITEMS_CATEGORIES} className="btn btn-primary btn-shadow btn-lg btn-block">Edit
                                Categories</Link>
                            <Link to={PATH_ITEMS_ITEM} state={{category: cleanObj(selectedCategory)}}
                                  className="btn btn-primary btn-shadow btn-lg btn-block mt-2">Add Item</Link>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default ItemsList;
