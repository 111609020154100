import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import SideMenu from "../../Components/SideMenu";
import {MENUS, PATH_PRODUCTS_PRODUCT} from "../../constants";
import TextSearch from "../../Components/TextSearch";
import {useEffect, useState} from "react";
import {useDebouncedEffect} from "../../Hooks/useDebouncedEffect";
import withUserAuth from "../../Behavior/withUserAuth";
import {getAllProducts} from "../../API";
import {Link} from "react-router-dom";
import ProductItem from "../../Components/ProductItem";
import {useNavigate} from "react-router";

const Products = ({onProductClick}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [showSearch, setShowSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [products, setProducts] = useState([]);

    const sendAPIRequest = async (searchstring = searchText) => {
        const res = await getAllProducts(searchstring);
        if (res.code === 200) {
            setProducts(res.data.productList);
        } else {
            setError(res.error);
        }
        setLoading(false);
    }

    useEffect(() => {
        sendAPIRequest(searchText);
    }, []);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const onSearchClick = () => {
        setShowSearch(true);
    }

    const onSearchClose = () => {
        setShowSearch(false);
        setSearchText('');
        setLoading(true);
        sendAPIRequest('');
    }

    const handleItemClick = (product) => {
        if (onProductClick) {
            onProductClick(product);
        } else {
            navigate(PATH_PRODUCTS_PRODUCT, {state: {product}});
        }

    }

    useDebouncedEffect(async () => {
        if (showSearch) {
            setLoading(true);
            await sendAPIRequest('');
        }
    }, [searchText], 500)

    const onRetry = !!error.retry ? handleRetry : null;

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Products"} onSearchClick={onSearchClick}/>
            <SideMenu selected={MENUS.PRODUCTS}/>
            <TextSearch isVisible={showSearch} onClose={onSearchClose} onTextChange={setSearchText}
                        searchText={searchText}/>
            <div id="appCapsule" className="mt-76">
                {/* Items */}
                <div className="section invoice-added-items">
                    <ul className="listview separate-list  service-add-item-filter filter-items image-listview inset no-line no-arrow mx-0">
                        {
                            products.map((product) => (
                                    <ProductItem
                                        key={product.externalProductId}
                                        product={product}
                                        onClick={handleItemClick}
                                    />
                                )
                            )
                        }
                    </ul>
                    <Link to={PATH_PRODUCTS_PRODUCT} className="add-btn-large mt-2" href="#">
                        <ion-icon name="add"/>
                    </Link>
                </div>
                {/* * Items */}
            </div>


        </>
    )
}

export default withUserAuth(Products);

