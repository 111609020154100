import Header from "../../Components/Header";
import {useLocation, useNavigate} from "react-router";
import {useEffect, useRef, useState} from "react";
import InputMask from "react-input-mask";
import moment from 'moment';
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {COUNTRIES, MENUS, PLACEHOLDER_CAMERA} from "../../constants";
import {addClient, deleteClient, updateClient, uploadFile} from "../../API";
import withUserAuth from "../../Behavior/withUserAuth";
import {validateEmail} from "../../Utils";
import SideMenu from "../../Components/SideMenu";
import $ from "jquery";

const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
const DATE_OUTPUT_FORMAT = 'MM/DD/YYYY';

const Client = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const client = location.state?.client || {};

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [imageUrl, setImageUrl] = useState(() => client?.imageurl || PLACEHOLDER_CAMERA);
    const [imageFile, setImageFile] = useState(null);
    const imageInputRef = useRef();
    const [name, setName] = useState(() => client?.name || '')
    const [surname, setSurname] = useState(() => client?.surname || '')
    const [note, setNote] = useState(() => client?.note || '');

    const [hasMore, setHasMore] = useState(false);
    const [email, setEmail] = useState(() => client?.email || '')
    const [phone, setPhone] = useState(() => client?.phone || '');
    const [gender, setGender] = useState(() => client?.gender || 1);
    const [birthdate, setBirthdate] = useState(() => {
        if (client.birthdate) {
            return moment(client.birthdate, DATE_INPUT_FORMAT).format(DATE_OUTPUT_FORMAT);
        }
        return '';
    });
    const [address, setAddress] = useState(() => client?.address || '');
    const [country, setCountry] = useState(() => client?.country || COUNTRIES[0]);


    const handleRemoveImage = (e) => {
        e.stopPropagation();
        setImageUrl(() => client?.imageurl || PLACEHOLDER_CAMERA);
        setImageFile(null);
    }

    const handleSave = async () => {

        if (!validateEmail(email)) {
            setError('Email is invalid');
            return;
        }

        if (birthdate && !moment(birthdate, DATE_OUTPUT_FORMAT).isValid()) {
            setError('Birthdate is invalid. Please verify the format');
            return;
        }

        const newClient = {
            ...(client.accountid ? {accountid: client.accountid} : {}),
            imageurl: client.imageUrl || '',
            name,
            surname,
            email,
            phone,
            note,
            gender,
            birthdate: moment(birthdate, DATE_OUTPUT_FORMAT).isValid() ? moment(birthdate, DATE_OUTPUT_FORMAT).format(DATE_INPUT_FORMAT) : '',
            address,
            country,
        }
        setLoading(true);
        if (imageUrl !== PLACEHOLDER_CAMERA && imageUrl !== client.imageurl) { //need to upload file
            const res = await uploadFile(imageFile);
            if (res.code === 200) {
                newClient.imageurl = res.data.image.url;
            } else {
                setLoading(false);
                setError(res.error)
                return;
            }
        }
        let res;
        if (client.accountid) { //Update
            res = await updateClient(newClient);
        } else {
            res = await addClient(newClient);
        }
        setLoading(false);
        if (res.code === 200) {
            navigate(-1);
        } else {
            setError(res.error);
        }
    }

    const handleDelete = async () => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
        if (isConfirm) {
            setLoading(true);
            const {accountid} = client;
            const {code, error} = await deleteClient({accountid})
            if (code === 200) {
                navigate(-1);
            } else {
                setLoading(false);
                setError(error);
            }
        }
    }

    useEffect(() => {
        function handleResize() {
            const windscroll = $(window).scrollTop();
            if (windscroll >= 100) {
                $(".appHeader").removeClass("transparent")
            } else {
                $(".appHeader").addClass("transparent")
            }
        }

        window.addEventListener('scroll', handleResize)
        handleResize();
        return () => {
            window.removeEventListener('scroll', handleResize)
        }
    }, [])

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header hasBack title={"Client"} className={"transparent"}/>
            <SideMenu selected={MENUS.CLIENTS}/>
            <div id="appCapsule" className={"p-0"}>
                <div onClick={() => imageInputRef.current.click()} className="cover-img-wrapper">
                    <img src={imageUrl} className="cover-img" alt="image"/>
                    <input
                        style={{display: 'none'}}
                        ref={imageInputRef}
                        type="file"
                        className="form-control"
                        id="photo"
                        accept="image/*"
                        onChange={(e) => {
                            if (e.target?.files?.length > 0) {
                                setImageUrl(URL.createObjectURL(e.target.files[0]));
                                setImageFile(e.target.files[0]);
                            }
                        }}
                    />
                </div>

                <div className="section clients-edit mt-2">
                    {/*<div className="row m-0">*/}
                    {/*    <div className="col-6 ps-0">*/}
                    {/*        <div*/}
                    {/*            onClick={() => imageInputRef.current.click()}*/}
                    {/*            className="card img-card card-border" data-bs-toggle="modal"*/}
                    {/*            data-bs-target="#getPicturesDialog">*/}
                    {/*            <img src={imageUrl} className="card-img-top" alt="image"/>*/}
                    {/*            <div style={{display: 'flex'}}>*/}
                    {/*                <h4 style={{flex: 1}}>Select Photo</h4>*/}
                    {/*                {imageFile && <h4 onClick={handleRemoveImage} style={{flex: 1}}>Remove</h4>}*/}
                    {/*            </div>*/}
                    {/*            <input*/}
                    {/*                style={{display: 'none'}}*/}
                    {/*                ref={imageInputRef}*/}
                    {/*                type="file"*/}
                    {/*                className="form-control"*/}
                    {/*                id="photo"*/}
                    {/*                accept="image/*"*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    if (e.target?.files?.length > 0) {*/}
                    {/*                        setImageUrl(URL.createObjectURL(e.target.files[0]));*/}
                    {/*                        setImageFile(e.target.files[0]);*/}
                    {/*                    }*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    /!*<div className="col-6 pe-0 mt-auto">*!/*/}
                    {/*    /!*    <a href="chat.html" className="btn btn-primary btn-shadow btn-lg btn-block">Chat</a>*!/*/}
                    {/*    /!*    <a href="receipts.html" className="btn btn-primary btn-shadow btn-block btn-lg mt-2">Transactions</a>*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*</div>*/}
                    {/* * Items */}
                    {/* details */}
                    <div className="section-title mt-3">Details</div>
                    <div className="card card-border">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="ClientName">Client Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="ClientName"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="ClientName">Surname</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="ClientName"
                                            value={surname}
                                            onChange={e => setSurname(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="email">E-mail</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="phone">Mobile</label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            className="form-control"
                                            autoComplete="off"
                                            value={phone}
                                            maxLength={15}
                                            onChange={e => setPhone(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="description">Description</label>
                                        <textarea
                                            id="description"
                                            rows={2} className="form-control"
                                            value={note}
                                            onChange={e => setNote(e.target.value)}
                                            maxLength={500}
                                        />

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* * details */}
                    {/* More */}
                    <ul className="listview simple-listview transparent darkmode-transparent flush mt-4">
                        <li>
                            <div>More</div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="SwitchMore"
                                       checked={hasMore} onChange={(e) => setHasMore(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="SwitchMore" id="itemsSwitchToggler"/>
                            </div>
                        </li>
                    </ul>
                    {
                        hasMore && <div id="itemsSwitchWrapper" className="card card-border">
                            <div className="card-body">
                                <form>
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="Gender">Gender</label>
                                            <select className="form-control custom-select" id="Gender"
                                                    value={gender}
                                                    onChange={(e) => setGender(e.target.value)}
                                            >
                                                <option value={1}>Male</option>
                                                <option value={2}>Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group basic">
                                        <label className="label" htmlFor="birthdate">Birthdate (MM/DD/YYYY)</label>
                                        <InputMask
                                            id={"birthdate"}
                                            className="form-control"
                                            maskPlaceholder={null}
                                            mask={"99/99/9999"}
                                            value={birthdate}
                                            onChange={e => setBirthdate(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="Address">Address</label>
                                            <input type="text" className="form-control" id="Address"
                                                   value={address}
                                                   onChange={e => setAddress(e.target.value)}
                                                   maxLength={200}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="country">Country</label>
                                            <select className="form-control custom-select" id="country"
                                                    value={country}
                                                    onChange={(e) => setCountry(e.target.value)}
                                            >
                                                {COUNTRIES.map(country => <option value={country}>{country}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                    <div className="mt-4">
                        <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Save</a>
                        {
                            !!client.accountid && <a onClick={handleDelete}
                                                     className="btn btn-danger btn-shadow btn-lg btn-block mt-2">Delete</a>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(Client);
