import {useEffect, useState} from 'react';
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {MENUS, PATH_POS_RECEIPT} from "../../constants";
import {createSale} from "../../API";
import {cleanObj, formatNumber, showErrorToast, showSuccessToast} from "../../Utils";
import {useNavigate} from "react-router-dom";
import {usePosStore} from "../../Store";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import SideMenu from "../../Components/SideMenu";

const POSCash = () => {
    const posStore = usePosStore();
    const sale = posStore.getFinalSale();
    const navigate = useNavigate();
    const grandTotal = parseFloat(sale.grandTotal);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [amount, setAmount] = useState(0);
    const [newBalance, setNewBalance] = useState(0);
    useEffect(() => {
        calculateBalance();
    }, [amount]);

    const calculateBalance = () => {
        const balance = grandTotal - parseFloat(amount);
        if (balance > 0) {
            setNewBalance(0);
        } else {
            setNewBalance(balance * -1);
        }

    }

    const addAmount = (_amount) => () => {
        const newAmount = parseFloat(amount) + parseFloat(_amount);
        setAmount(newAmount);
    }

    const handleSave = async () => {
        const balance = grandTotal - parseFloat(amount);

        if (isNaN(balance) || balance > 0) {
            showErrorToast('Payment Cannot be lesser than amount');
            return;
        }

        const salePayments = [{
            paymentMethodId: 0,
            paymentTypeId: 1,
            amount: sale.grandTotal,
        }];
        const saleReq = {...sale, salePayments};
        setLoading(true);

        const {code, error, data, message} = await createSale(saleReq);
        if (code === 200) {
            showSuccessToast(message);
            const newSale = {...saleReq, invoiceNumber: data.invoiceNumber}
            posStore.processSaleResponse(saleReq, data);
            navigate(PATH_POS_RECEIPT, {state: {receipt: data, sale: cleanObj(newSale)}})
        } else {
            setLoading(false);
            setError(error);
        }
    }

    return <>
        {loading && <Loader/>}
        {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
        <Header title={"Cash Payment"} hasBack/>
        <SideMenu selected={MENUS.POS}/>
        <div id="appCapsule">
            {/* Cash payment Amount */}
            <div className="row section cash-payment-amount mt-2">
                <div className="col-4">
                    <div className="single-cash-payment">
                        <p>Sale Amount</p>
                        <input
                            type="number"
                            className="form-control"
                            id="taxRate"
                            value={formatNumber(sale.grandTotal)}
                            disabled
                            style={{
                                background: 'transparent',
                                border: 0,
                                fontSize: '24px',
                                fontWeight: 700,
                                padding: '2px',
                                textAlign: 'center'
                            }}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="single-cash-payment">
                        <p>Got Cash</p>
                        <input
                            type="number"
                            className="form-control"
                            id="taxRate"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            style={{
                                background: 'transparent',
                                border: 0,
                                fontSize: '24px',
                                fontWeight: 700,
                                padding: '2px',
                                textAlign: 'center'
                            }}
                        />

                    </div>
                </div>
                <div className="col-4">
                    <div className="single-cash-payment">
                        <p>Give Change</p>
                        <input
                            type="number"
                            className="form-control"
                            id="taxRate"
                            value={formatNumber(newBalance)}
                            disabled
                            style={{
                                background: 'transparent',
                                border: 0,
                                fontSize: '24px',
                                fontWeight: 700,
                                padding: '2px',
                                textAlign: 'center'
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row section amount-btn mt-3">
                <div className="col">
                    <button onClick={addAmount(10)} className="btn btn-light btn-shadow btn-block">10</button>
                </div>
                <div className="col">
                    <button onClick={addAmount(20)} className="btn btn-light btn-shadow btn-block">20</button>
                </div>
                <div className="col">
                    <button onClick={addAmount(50)} className="btn btn-light btn-shadow btn-block">50</button>
                </div>
                <div className="col">
                    <button onClick={addAmount(100)} className="btn btn-light btn-shadow btn-block">100</button>
                </div>
                <div className="col">
                    <button onClick={addAmount(500)} className="btn btn-light btn-shadow btn-block">500</button>
                </div>
            </div>
            {/* * Amount Button */}
            {/* Options */}
            <div className="section mt-3">
                <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Confirm</a>
            </div>
            {/* * Buttons */}
        </div>

    </>
}

export default withUserAuth(POSCash);
