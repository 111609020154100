import ModifierItem from "./ModifierItem";
import {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import _ from "lodash";
import {useItemStore} from "../Store";
import {calculateRetailPrice, calculateTaxAmount} from "../Utils";

const POSItemDetail = forwardRef(({category, item, saleItem, allModifiers}, ref) => {
    const itemStore = useItemStore();

    const taxesRate = itemStore.getTaxes().filter(t => category.taxes?.includes(t.companyTaxId));//.reduce((total, current) => total + current.tax, 0);
    const [totalPrice, setTotalPrice] = useState(() => saleItem?.total || item.salesprice);
    const totalTaxRate = taxesRate.reduce((total, curr) => (curr.tax + total), 0);
    const [note, setNote] = useState(() => saleItem?.note || '');
    const [selectedVariant, setSelectedVariant] = useState(() => {
        if (saleItem && saleItem.itemVariationId) {
            return {
                itemVariationId: saleItem.itemVariationId,
                variationName: saleItem.variationName,
            }
        }
        return {};
    });

    const [currentModifier, setCurrentModifier] = useState(0);

    const modifierRefs = useRef([]);

    const modifiers = useMemo(() => item.modifiers
        .map(({modifierId}) => allModifiers.find(m => m.modifierId === modifierId))
        .filter(m => !!m), [item.modifiers, allModifiers]);

    const onPriceUpdate = () => {
        let newTotalPrice = item.salesprice;

        if (!_.isEmpty(selectedVariant)) {
            const variant = item.variations.find(variant => variant.itemvariationid === selectedVariant.itemVariationId);
            if (variant) {
                newTotalPrice += variant.salesprice;
            }
        }
        modifierRefs.current.forEach(ref => {
            newTotalPrice += ref.getSelectedItemPrice();
        })
        setTotalPrice(newTotalPrice);
    }

    useEffect(() => {
        onPriceUpdate();
    }, [selectedVariant.itemVariationId])

    useImperativeHandle(ref, () => ({
        id: saleItem.id,
        getItemDetail: () => {
            const selectedModifiers = modifierRefs.current.reduce((preModifiers, nextModifiers) => {
                return [...preModifiers, ...nextModifiers.getValues()];
            }, []);
            const retailPrice = calculateRetailPrice(totalPrice, totalTaxRate);
            return {
                id: saleItem.id,
                itemId: item.itemid,
                itemName: item.name,
                description: item.description,
                imageUrl: item.detailimageurl,
                itemVariationId: selectedVariant.itemVariationId,
                variationName: selectedVariant.variationName,
                taxes: taxesRate.map(tax => ({
                    taxId: tax.companyTaxId,
                    name: tax.name,
                    value: tax.tax,
                    taxAmount: parseFloat(calculateTaxAmount(retailPrice, tax.tax)),
                })),
                total: totalPrice,
                selectedModifiers,
                quantity: 1,
                isCustomItem: 0,
                note,
            }
        }
    }))

    const setModifierRef = (el) => {
        if (el) {
            const refIndex = _.findIndex(modifierRefs.current, (refs => refs.modifierId === el.modifierId));
            if (refIndex >= 0) {
                modifierRefs.current.splice(refIndex, 1, el);
            } else {
                modifierRefs.current.push(el);
            }
        }
    }

    const handleVariantChange = (variation) => (e) => {
        if (e.target.checked) {
            setSelectedVariant({
                itemVariationId: variation.itemvariationid,
                variationName: variation.name,
            })
        } else {
            setSelectedVariant({});
        }
    }

    const handleModifierHeaderClick = (e, index) => {
        if (index === currentModifier) {
            setCurrentModifier(-1);
        } else {
            setCurrentModifier(index);
        }
    }

    return (
        <div className="card card-border mt-2">
            {/*<div style={{textAlign: 'end', marginTop: '8px'}}>*/}
            {/*        <span style={{padding: '8px'}} onClick={() => {*/}
            {/*        }} className="delete-item">*/}
            {/*            <ion-icon name="close"/>*/}
            {/*        </span>*/}
            {/*</div>*/}
            <div className="card-body">
                {/* product text */}
                {/*<div className="modification-item-name" style={{borderBottom: '0'}}>*/}
                {/*    <h4>{item.name}</h4>*/}
                {/*    <h3>{item.salesprice}</h3>*/}
                {/*</div>*/}

                <div className="modification input-list w-100">
                    {
                        item.variations.map(variation => {
                            return (
                                <div className="single-modification modifiacation-checkbox"
                                     key={variation.itemvariationid}>
                                    <div className="card-title mb-0">{(variation.salesprice)}</div>
                                    <div className="form-check mb-1">
                                        <input type="checkbox" className="form-check-input"
                                               checked={variation.itemvariationid === selectedVariant.itemVariationId}
                                               id={`variantCheck-${saleItem.id}-${variation.itemvariationid}`}
                                               onChange={handleVariantChange(variation)}/>
                                        <label className="form-check-label"
                                               htmlFor={`variantCheck-${saleItem.id}-${variation.itemvariationid}`}>{`${item.name} - ${variation.name}`}</label>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {modifiers.length > 0 &&
                    <div className="modification input-list w-100">
                        {
                            modifiers.map((modifier, index) => {
                                return (
                                    <ModifierItem
                                        ref={setModifierRef}
                                        key={modifier.modifierId}
                                        index={index}
                                        saleItem={saleItem}
                                        currentModifier={currentModifier}
                                        modifier={modifier}
                                        handleModifierHeaderClick={handleModifierHeaderClick}
                                        onModifierSelected={onPriceUpdate}
                                    />
                                )
                            })
                        }
                    </div>
                }

                {/* * Total Amount */}
                {/* qnt total */}
                <div className="qnt-total input-list w-100">
                    <h3>
                        Total
                    </h3>
                    <div className="total-amount">
                        <h1 className="m-0">{totalPrice}</h1>
                    </div>
                </div>
            </div>
            <div className="instruction" style={{padding: '4px 16px'}}>
                <textarea name="instruction" id="instruction" rows={2} placeholder="Add an instruction to this item"
                          className="w-100 p-1" value={note}
                          onChange={(e) => setNote(e.target.value)}/>
            </div>
        </div>
    )
});

export default POSItemDetail;
