import Modal from 'react-modal';
import {useState} from "react";
import InputMask from "react-input-mask";
import moment from "moment";
import {REPORT_DATE_FORMAT} from "../constants";

const DATE_OUTPUT_FORMAT = 'MM/DD/YYYY';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0, 0.5)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'none',
        inset: 'none',
        borderRadius: 'none',
        border: 0,
        background: 'none',
    },
};

const ReportExportDialog = ({isOpen, startDate: startDateStr, endDate: endDateStr, onSave, onClose}) => {
    const [email, setEmail] = useState('');
    const [startDate, setStartDate] = useState(() => moment(startDateStr).format(DATE_OUTPUT_FORMAT));
    const [endDate, setEndDate] = useState(() => moment(endDateStr).format(DATE_OUTPUT_FORMAT));
    const [exportType, setExportType] = useState('pdf');

    const handleSave = () => {
        const outStartDate = moment(startDate).format(REPORT_DATE_FORMAT);
        const outEndDate = moment(endDate).format(REPORT_DATE_FORMAT);
        onSave({email, startDate: outStartDate, endDate: outEndDate, type: exportType});
    }

    return (
        <Modal
            style={customStyles}
            isOpen={isOpen}>
            <div className="dialogbox show">
                <div className="modal-dialog">
                    <div className="modal-content card-border">
                        <div className="modal-icon" style={{fontSize: '18px', marginBottom: 0, marginTop: '16px'}}>
                            Export
                        </div>
                        <div className="modal-body listing mt-2">
                            <form>
                                <div className="report-check-radio">
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name="export-type"
                                            value="pdf"
                                            id="pdfCheck"
                                            checked={exportType === 'pdf'}
                                            onChange={e => setExportType(e.target.value)}
                                        />
                                        <label className="form-check-label radio" htmlFor="pdfCheck">PDF</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name="export-type"
                                            value="excel"
                                            id="excelCheck"
                                            checked={exportType === 'excel'}
                                            onChange={e => setExportType(e.target.value)}
                                        />
                                        <label className="form-check-label radio" htmlFor="excelCheck">Excel</label>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="email">E-mail</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="startDate">
                                            Start Date
                                        </label>
                                        <InputMask
                                            id={"startDate"}
                                            className="form-control"
                                            mask={"99/99/9999"}
                                            value={startDate}
                                            onChange={e => setStartDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="endDate">
                                            End Date
                                        </label>
                                        <InputMask
                                            id={"endDate"}
                                            className="form-control"
                                            mask={"99/99/9999"}
                                            value={endDate}
                                            onChange={e => setEndDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <a onClick={onClose} className="btn">Close</a>
                                <a onClick={handleSave} className="btn">Save</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ReportExportDialog;
