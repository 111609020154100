import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import Header from "../../Components/Header";
import SaleDetail from "../../Components/SaleDetail";
import withUserAuth from "../../Behavior/withUserAuth";
import {usePosStore} from "../../Store";
import {deleteSale, getOrderDetail} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {PATH_POS_CHECKOUT} from "../../constants";
import _ from "lodash";

const OrderDetail = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const invoiceId = searchParams.get('id');
    const posStore = usePosStore();
    const [sale, setSale] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const sendAPIRequest = async () => {
        const {code, data, error} = await getOrderDetail(invoiceId);
        if (code === 200) {
            setSale(data.invoice)
        } else {
            setError(error);
        }
        setLoading(false);

    }

    useEffect(() => {
        sendAPIRequest();
    }, [])

    const handleProceed = () => {
        posStore.clearStore();
        posStore.setSale(sale);
        navigate(PATH_POS_CHECKOUT);
    }

    const handleDelete = async () => {
        const isConfirm = await window.confirm(`Are you sure you want to delete this parked order?`);
        if (isConfirm) {
            setLoading(true);
            const invoiceid = sale.invoiceId;
            const {code, error} = await deleteSale({invoiceid})
            if (code === 200) {
                navigate(-1);
            } else {
                setLoading(false);
                setError(error);
            }
        }
    }

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header title={"Order Detail"} hasBack/>
            {!_.isEmpty(sale) && 
            <SaleDetail 
            sale={sale} handleProceed={handleProceed} 
            hideAction={true}
            isOnlineOrder={true}
            handleDelete={handleDelete}/>}
        </>


    )
}

export default withUserAuth(OrderDetail);
