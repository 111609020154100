export function createItemsStore() {
    return {
        taxes: [],
        modifiers: [],
        sections: [],
        allItems: [],
        setAll(data) {
            this.setModifiers(data.modifiers);
            this.setAllItems(data.allitems);
            this.setSections(data.kitchens);
            this.setTaxes(data.taxes);
        },
        setModifiers(modifiers) {
            this.modifiers = modifiers;
            window.sessionStorage.setItem('MODIFIERS', JSON.stringify(modifiers));
        },
        getModifiers() {
            if (this.modifiers.length === 0) {
                const tempModifiers = window.sessionStorage.getItem('MODIFIERS');
                if (tempModifiers) {
                    this.modifiers = JSON.parse(tempModifiers);
                }
            }
            return this.modifiers;
        },
        setAllItems(allItems) {
            this.allItems = allItems;
            window.sessionStorage.setItem('ALL_ITEMS', JSON.stringify(allItems));
        },
        getCategoryById(categoryId) {
            return this.getAllItems().find(cat => cat.categoryID === categoryId);
        },
        getItemById(itemId) {
            return this.getAllItems().map(cat => cat.items).flat().find(i => i.itemid === itemId)
        },
        getAllItems() {
            if (this.allItems.length === 0) {
                const tempItems = window.sessionStorage.getItem('ALL_ITEMS');
                if (tempItems) {
                    this.allItems = JSON.parse(tempItems);
                }
            }
            return this.allItems;
        },
        setSections(sections) {
            this.sections = sections;
            window.sessionStorage.setItem('SECTIONS', JSON.stringify(sections));
        },
        getSections() {
            if (this.sections.length === 0) {
                const tempSections = window.sessionStorage.getItem('SECTIONS');
                if (tempSections) {
                    this.sections = JSON.parse(tempSections);
                }
            }
            return this.sections;
        },
        setTaxes(taxes) {
            this.taxes = taxes;
            window.sessionStorage.setItem('TAXES', JSON.stringify(taxes));
        },
        getTaxes() {
            if (this.taxes.length === 0) {
                const tempTaxes = window.sessionStorage.getItem('TAXES');
                if (tempTaxes) {
                    this.taxes = JSON.parse(tempTaxes);
                }
            }
            return this.taxes;
        },
    }
}
