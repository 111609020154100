import {useNavigate} from "react-router-dom";
import {useInvoiceStore} from "../../Store";
import Clients from "../Clients/Clients";
import {useState} from "react";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {addInvoice} from "../../API";


const InvoiceClients = () => {
    const navigate = useNavigate();
    const invoiceStore = useInvoiceStore();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleClick = async ({externalAccountId}) => {
        setLoading(true);
        const {code, data, error} = await addInvoice({customerId: externalAccountId});
        if (code === 200) {
            invoiceStore.setInvoice(data.invoice);
            navigate(-1);
        } else {
            setError(error);
        }
        setLoading(false);
    }
    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Clients onClientClick={handleClick}/>
        </>
    )
}

export default InvoiceClients;
