import Modal from 'react-modal';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0, 0.5)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'none',
        inset: 'none',
        borderRadius: 'none',
        border: 0,
        background: 'none',
    },
};

const SectionsDialog = ({isOpen, sections = [], selectedSection, onSelectionChange, onClose}) => {

    return (
        <Modal
            style={customStyles}
            isOpen={isOpen}>
            <div className="dialogbox show">
                <div className="modal-dialog">
                    <div className="modal-content card-border">
                        <div className="modal-icon" style={{fontSize: '18px', marginBottom: 0, marginTop: '16px'}}>
                            Notify Section
                        </div>
                        <div className="modal-body listing mt-2">
                            {
                                sections.map(section => {
                                    return (
                                        <div key={section.kitchenId} className="form-check" style={{margin: '16px 0'}}>
                                            <input type={"radio"}
                                                   id={`section-${section.kitchenId}`}
                                                   checked={section.kitchenId === selectedSection.kitchenId}
                                                   onChange={() => onSelectionChange(section)}
                                                   className="form-check-input"/>
                                            <label
                                                className={`form-check-label radio`}
                                                htmlFor={`section-${section.kitchenId}`}>{section.name}</label>
                                        </div>
                                    )
                                })
                            }
                            {
                                sections.length === 0 && <div>No section available.</div>
                            }
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <a onClick={onClose} className="btn">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SectionsDialog;
