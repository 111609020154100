import {BrowserRouter, Route, Routes} from "react-router-dom";
import PrintProvider, {NoPrint} from 'react-easy-print';

import Login from './Pages/Login'
import POS from "./Pages/POS/POS";
import SignupFirst from "./Pages/SignupFirst";
import Signup from "./Pages/Signup";
import ForgotPassword from "./Pages/ForgotPassword";
import NotFound from "./Pages/NotFound";
import Home from './Pages/Home';
import Modal from 'react-modal';
import SignupWhatDoYouDo from "./Pages/SignupWhatDoYouDo";
import SignupPayment from "./Pages/SignupPayment";
import SignupStripe from "./Pages/SignupStripe";
import {getDarkMode} from "./DB";
import POSItem from "./Pages/POS/POSItem";
import {PosProvider} from "./Store";
import {
    PATH_CLIENTS,
    PATH_CLIENTS_CLIENT,
    PATH_FORGOT_PASSWORD,
    PATH_HOME,
    PATH_INVOICES,
    PATH_INVOICES_CLIENT,
    PATH_INVOICES_NEW,
    PATH_INVOICES_PRODUCT,
    PATH_INVOICES_VIEW,
    PATH_ITEM,
    PATH_ITEMS,
    PATH_ITEMS_CATEGORIES,
    PATH_ITEMS_CATEGORY,
    PATH_LOGIN,
    PATH_ORDERS,
    PATH_ORDERS_DETAIL,
    PATH_ORDERS_QR,
    PATH_PARKED_DETAIL,
    PATH_PARKED_LIST,
    PATH_POS_CARD,
    PATH_POS_CASH,
    PATH_POS_CHECKOUT,
    PATH_POS_CLIENT,
    PATH_POS_CUSTOM,
    PATH_POS_DISCOUNT,
    PATH_POS_EMAIL_RECEIPT,
    PATH_POS_ITEM,
    PATH_POS_RECEIPT,
    PATH_POS_SHARE_QR,
    PATH_POS_SMS_RECEIPT,
    PATH_POS_TABLE,
    PATH_POS_TIP,
    PATH_PRODUCTS,
    PATH_PRODUCTS_PRODUCT,
    PATH_RECEIPTS,
    PATH_RECEIPTS_RECEIPT,
    PATH_RECEIPTS_REFUND,
    PATH_REPORT,
    PATH_SELL,
    PATH_SETTINGS,
    PATH_SETTINGS_ACCEPT_PAYMENTS,
    PATH_SETTINGS_CASH_EDIT,
    PATH_SETTINGS_CASH_LIST,
    PATH_SETTINGS_DELIVERY,
    PATH_SETTINGS_HARDWARE,
    PATH_SETTINGS_HARDWARES,
    PATH_SETTINGS_IN_STORE_PAYMENTS,
    PATH_SETTINGS_MODIFIER,
    PATH_SETTINGS_MODIFIERS,
    PATH_SETTINGS_ONLINE_PAYMENTS,
    PATH_SETTINGS_ORDER_APP,
    PATH_SETTINGS_ORG_INFO,
    PATH_SETTINGS_PROFILE,
    PATH_SETTINGS_SECTION,
    PATH_SETTINGS_SECTIONS,
    PATH_SETTINGS_TABLE,
    PATH_SETTINGS_TABLES,
    PATH_SETTINGS_TAX,
    PATH_SETTINGS_TAXES,
    PATH_SIGNUP,
    PATH_SIGNUP_FIRST,
    PATH_SIGNUP_PAYMENT,
    PATH_SIGNUP_STRIPE,
    PATH_SIGNUP_WHAT_DO_YOU_SELL,
    PATH_STAFFS,
    PATH_STAFFS_STAFF,
} from "./constants";
import Items from "./Pages/Items/Items";
import CategoryList from "./Pages/Items/CategoryList";
import Category from "./Pages/Items/Category";
import Settings from "./Pages/Settings/Settings";
import Sections from "./Pages/Settings/Sections";
import Section from "./Pages/Settings/Section";
import Taxes from "./Pages/Settings/Taxes";
import Tax from "./Pages/Settings/Tax";
import Modifiers from "./Pages/Settings/Modifiers";
import Modifier from "./Pages/Settings/Modifier";
import {ToastContainer} from "react-toastify";
import Item from "./Pages/Items/Item";
import Clients from "./Pages/Clients/Clients";
import Client from "./Pages/Clients/Client";
import Staff from "./Pages/Staff/Staff";
import Staffs from "./Pages/Staff/Staffs";
import Receipts from "./Pages/Receipts/Receipts";
import ParkedList from "./Pages/ParkedList/ParkedList";
import Receipt from "./Pages/Receipts/Receipt";
import Parked from "./Pages/ParkedList/Parked";
import POSCheckout from "./Pages/POS/POSCheckout";
import 'react-toastify/dist/ReactToastify.css';
import Orders from "./Pages/Order/Orders";
import Report from "./Pages/Report/Report";
import CashList from "./Pages/Settings/CashList";
import CashEdit from "./Pages/Settings/CashEdit";
import Tables from "./Pages/Settings/Tables";
import Table from "./Pages/Settings/Table";
import Refund from "./Pages/Receipts/Refund";
import POSDiscount from "./Pages/POS/POSDiscount";
import POSTip from "./Pages/POS/POSTip";
import POSTables from "./Pages/POS/POSTables";
import POSClients from "./Pages/POS/POSClients";
import POSCash from "./Pages/POS/POSCash";
import POSReceipt from "./Pages/POS/POSReceipt";
import POSCard from "./Pages/POS/POSCard";
import POSCustom from "./Pages/POS/POSCustom";
import POSSendReceipt from "./Pages/POS/POSSendReceipt";
import POSShareQR from "./Pages/POS/POSShareQR";
import OrderQR from "./Pages/Order/OrderQR";
import Profile from "./Pages/Settings/Profile";
import Hardwares from "./Pages/Settings/Hardwares";
import Hardware from "./Pages/Settings/Hardware";
import OrganizationInfo from "./Pages/Settings/OrganizationInfo";
import PaymentsDetail from "./Pages/Settings/PaymentsDetail";
import AcceptPayments from "./Pages/Settings/AcceptPayments";
import OrderApp from "./Pages/Settings/OrderApp";
import Delivery from "./Pages/Settings/Delivery";
import Invoices from "./Pages/Invoices/Invoices";
import NewInvoice from "./Pages/Invoices/NewInvoice";
import Products from "./Pages/Products/Products";
import Product from "./Pages/Products/Product";
import InvoiceClients from "./Pages/Invoices/InvoiceClients";
import InvoiceProducts from "./Pages/Invoices/InvoiceProducts";
import InvoiceView from "./Pages/Invoices/InvoiceView";
import OrderDetail from "./Pages/Order/OrderDetail";


function App() {
    Modal.setAppElement('#root');

    if (getDarkMode()) {
        document.body.classList.add('dark-mode');
    } else {
        document.body.classList.remove('dark-mode');
    }

    return (
        <PosProvider>
            <ToastContainer/>
            <PrintProvider>
                <NoPrint>
                    <BrowserRouter>
                        <Routes>
                            <Route path={PATH_HOME} element={<Home/>}/>
                            <Route path={PATH_LOGIN} element={<Login/>}/>
                            <Route path={PATH_SIGNUP} element={<Signup/>}/>
                            <Route path={PATH_FORGOT_PASSWORD} element={<ForgotPassword/>}/>
                            <Route path={PATH_SIGNUP_FIRST} element={<SignupFirst/>}/>
                            <Route path={PATH_SIGNUP_WHAT_DO_YOU_SELL} element={<SignupWhatDoYouDo/>}/>
                            <Route path={PATH_SIGNUP_PAYMENT} element={<SignupPayment/>}/>
                            <Route path={PATH_SIGNUP_STRIPE} element={<SignupStripe/>}/>
                            <Route path={PATH_SELL} element={<POS/>}/>
                            <Route path={PATH_POS_ITEM} element={<POSItem/>}/>
                            <Route path={PATH_POS_CUSTOM} element={<POSCustom/>}/>
                            <Route path={PATH_POS_CHECKOUT} element={<POSCheckout/>}/>
                            <Route path={PATH_POS_DISCOUNT} element={<POSDiscount/>}/>
                            <Route path={PATH_POS_TIP} element={<POSTip/>}/>
                            <Route path={PATH_POS_TABLE} element={<POSTables/>}/>
                            <Route path={PATH_POS_CLIENT} element={<POSClients/>}/>
                            <Route path={PATH_POS_CASH} element={<POSCash/>}/>
                            <Route path={PATH_POS_CARD} element={<POSCard/>}/>
                            <Route path={PATH_POS_RECEIPT} element={<POSReceipt/>}/>
                            <Route path={PATH_POS_EMAIL_RECEIPT} element={<POSSendReceipt type={'email'}/>}/>
                            <Route path={PATH_POS_SMS_RECEIPT} element={<POSSendReceipt type={'sms'}/>}/>
                            <Route path={PATH_POS_SHARE_QR} element={<POSShareQR/>}/>
                            <Route path={PATH_ITEMS} element={<Items/>}/>
                            <Route path={PATH_ITEM} element={<Item/>}/>
                            <Route path={PATH_ITEMS_CATEGORIES} element={<CategoryList/>}/>
                            <Route path={PATH_ITEMS_CATEGORY} element={<Category/>}/>
                            <Route path={PATH_CLIENTS} element={<Clients/>}/>
                            <Route path={PATH_CLIENTS_CLIENT} element={<Client/>}/>
                            <Route path={PATH_STAFFS} element={<Staffs/>}/>
                            <Route path={PATH_STAFFS_STAFF} element={<Staff/>}/>
                            <Route path={PATH_RECEIPTS} element={<Receipts/>}/>
                            <Route path={PATH_RECEIPTS_RECEIPT} element={<Receipt/>}/>
                            <Route path={PATH_RECEIPTS_REFUND} element={<Refund/>}/>
                            <Route path={PATH_PARKED_LIST} element={<ParkedList/>}/>
                            <Route path={PATH_PARKED_DETAIL} element={<Parked/>}/>
                            <Route path={PATH_INVOICES} element={<Invoices/>}/>
                            <Route path={PATH_INVOICES_NEW} element={<NewInvoice/>}/>
                            <Route path={PATH_INVOICES_CLIENT} element={<InvoiceClients/>}/>
                            <Route path={PATH_INVOICES_PRODUCT} element={<InvoiceProducts/>}/>
                            <Route path={PATH_INVOICES_VIEW} element={<InvoiceView/>}/>
                            <Route path={PATH_PRODUCTS} element={<Products/>}/>
                            <Route path={PATH_PRODUCTS_PRODUCT} element={<Product/>}/>
                            <Route path={PATH_ORDERS} element={<Orders/>}/>
                            <Route path={PATH_ORDERS_DETAIL} element={<OrderDetail/>}/>
                            <Route path={PATH_ORDERS_QR} element={<OrderQR/>}/>
                            <Route path={PATH_SETTINGS} element={<Settings/>}/>
                            <Route path={PATH_SETTINGS_SECTIONS} element={<Sections/>}/>
                            <Route path={PATH_SETTINGS_SECTION} element={<Section/>}/>
                            <Route path={PATH_SETTINGS_TAXES} element={<Taxes/>}/>
                            <Route path={PATH_SETTINGS_TAX} element={<Tax/>}/>
                            <Route path={PATH_SETTINGS_MODIFIERS} element={<Modifiers/>}/>
                            <Route path={PATH_SETTINGS_MODIFIER} element={<Modifier/>}/>
                            <Route path={PATH_SETTINGS_CASH_LIST} element={<CashList/>}/>
                            <Route path={PATH_SETTINGS_CASH_EDIT} element={<CashEdit/>}/>
                            <Route path={PATH_SETTINGS_TABLES} element={<Tables/>}/>
                            <Route path={PATH_SETTINGS_TABLE} element={<Table/>}/>
                            <Route path={PATH_SETTINGS_PROFILE} element={<Profile/>}/>
                            <Route path={PATH_SETTINGS_ORG_INFO} element={<OrganizationInfo/>}/>
                            <Route path={PATH_SETTINGS_HARDWARES} element={<Hardwares/>}/>
                            <Route path={PATH_SETTINGS_HARDWARE} element={<Hardware/>}/>
                            <Route path={PATH_SETTINGS_ACCEPT_PAYMENTS} element={<AcceptPayments/>}/>
                            <Route path={PATH_SETTINGS_IN_STORE_PAYMENTS} element={<PaymentsDetail/>}/>
                            <Route path={PATH_SETTINGS_ONLINE_PAYMENTS} element={<PaymentsDetail/>}/>
                            <Route path={PATH_SETTINGS_ORDER_APP} element={<OrderApp/>}/>
                            <Route path={PATH_SETTINGS_DELIVERY} element={<Delivery/>}/>
                            <Route path={PATH_REPORT} element={<Report/>}/>
                            {/*<Route path={PATH_PRINT} element={<Print/>}/>*/}
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>
                    </BrowserRouter>
                </NoPrint>
            </PrintProvider>
        </PosProvider>
    );
}

export default App;
