import Modal from 'react-modal';
import {useState} from "react";
import {validateEmail} from "../Utils";

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0, 0.5)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'none',
        inset: 'none',
        borderRadius: 'none',
        border: 0,
        background: 'none',
    },
};

const EmailDialog = ({onSend, onClose}) => {

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleSave = () => {
        if (!email) {
            setError('Email is required');
            return;
        } else if (!validateEmail(email)) {
            setError('Please enter valid email');
            return;
        }
        onSend(email);
    }

    return (
        <Modal
            style={customStyles}
            isOpen>
            <div className="dialogbox show">
                <div className="modal-dialog">
                    <div className="modal-content card-border">
                        <div className="modal-icon" style={{fontSize: '18px', marginBottom: 0, marginTop: '16px'}}>
                            Email
                        </div>
                        <div className="modal-body listing mt-2">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="itemName">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="itemName"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                        <span className="text-danger">{error}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <a onClick={onClose} className="btn">Close</a>
                                <a onClick={handleSave} className="btn">Send</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EmailDialog;
