import {useState} from "react";
import Sidebar from "react-sidebar";
import SideMenu from "../Components/SideMenu";


const withSideMenu = (Component) => {
    const WrappedComponent = () => {
        const [isOpen, setIsOpen] = useState(false);

        return (
            <div>
                <Sidebar
                    sidebar={<SideMenu handleClose={() => setIsOpen(false)}/>}
                    open={isOpen}
                    onSetOpen={() => setIsOpen(false)}
                    contentId={"sidebar-content"}
                    styles={{sidebar: {width: '200px', zIndex: 9990}}}
                    docked={true}
                >
                    <div style={{width: '480px'}}>
                        <Component onSidePanelOpen={() => setIsOpen(true)}/>
                    </div>
                </Sidebar>
            </div>
        )
    }
    return WrappedComponent;
}

export default withSideMenu;
