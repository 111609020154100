import _ from 'lodash';
import {ORDER_TYPE_DINE_IN, STATUS_PARKED, TYPE_ID_SALE} from "../constants";
import {v1 as uuidv1} from "uuid";
import {calculateGrandTotal, formatParseNumber} from "../Utils";

const getSessionObj = (KEY, Default, shouldParse = true) => {
    const objStr = window.sessionStorage.getItem(KEY);
    if (!_.isEmpty(objStr)) {
        if (shouldParse) {
            return JSON.parse(objStr);
        }
        return objStr;
    }
    return Default;
}
export const DEFAULT_SALE = {
    discount: 0,
    discountAmount: 0,
    tip: 0,
    tipAmount: 0,
    onlineOrderType: ORDER_TYPE_DINE_IN,
    amount: 0,
    netTotal: 0,
    grandTotal: 0,
    taxAmount: 0,
    saleItems: [],
};

export function createPosStore() {
    return {
        checks: getSessionObj('CHECKS', []),
        selectedCheck: getSessionObj('SELECTED_CHECK', '', false),
        isSplitEqual: getSessionObj('IS_SPLIT_EQUAL', false),
        sale: getSessionObj('SALE', DEFAULT_SALE),
        saleItems: getSessionObj('SALE_ITEMS', []),
        client: getSessionObj('CLIENT', {}),
        table: getSessionObj('TABLE', {}),
        setSale(sale) {
            this.sale = _.omit(sale, ['saleDetails', 'json']);
            this.saleItems = sale.saleDetails.map(detail => ({id: uuidv1(), ...detail}));
            this.saveSaleItem();
            this.calculateNetTotal();
        },
        setIsSplitEqual(isSplitEqual) {
            this.isSplitEqual = isSplitEqual
            window.sessionStorage.setItem('IS_SPLIT_EQUAL', JSON.stringify(this.isSplitEqual));
        },
        saveSaleItem() {
            window.sessionStorage.setItem('SALE_ITEMS', JSON.stringify(this.saleItems));
        },
        addItemToCart(newSaleItem, clearChecks = true) {
            clearChecks && this.saveChecks([]);
            this.saleItems.push(newSaleItem);
            this.saveSaleItem()
            this.calculateNetTotal();
        },
        removeItemFromCart(itemId, clearChecks = true) {
            clearChecks && this.saveChecks([]);
            const index = _.findLastIndex(this.saleItems, (i => i.itemId == itemId))
            if (index >= 0) {
                this.saleItems.splice(index, 1);
            }
            this.saveSaleItem()
            this.calculateNetTotal();
        },
        addItemsToCart(itemId, newSaleItems) {
            this.saveChecks([]);
            let saleItems = _.cloneDeep(this.saleItems);
            const itemsWithId = saleItems.filter(i => i.itemId === itemId);
            if (itemsWithId.length > 0) {
                let itemsToRemove = []
                if (newSaleItems.length > 0) {
                    itemsToRemove = _.differenceBy(itemsWithId, newSaleItems, 'id');
                } else {
                    itemsToRemove = itemsWithId;
                }
                if (itemsToRemove.length > 0) {
                    saleItems = _.differenceBy(saleItems, itemsToRemove, 'id');
                }
            }
            this.saleItems = _.unionBy(newSaleItems, saleItems, 'id');
            this.saveSaleItem();
            this.calculateNetTotal();
        },
        saveSale() {
            window.sessionStorage.setItem('SALE', JSON.stringify(this.sale));
        },
        saveChecks(checks) {
            this.checks = checks;
            window.sessionStorage.setItem('CHECKS', JSON.stringify(this.checks));
        },
        addCheck() {
            const newCheck = [
                ...this.checks,
                {
                    id: uuidv1(),
                    ...DEFAULT_SALE
                }
            ];
            this.saveChecks(newCheck);
        },
        getSelectedCheck() {
            if (this.checks.length > 0) {
                return this.checks.find(check => check.id == this.selectedCheck);
            }
        },
        setSelectedCheck(selectedCheck) {
            this.selectedCheck = selectedCheck;
            window.sessionStorage.setItem('SELECTED_CHECK', selectedCheck);
        },
        getDiscount() {
            return parseFloat(this.getSelectedCheck()?.discountAmount || 0);
        },
        setDiscount(discount, discountAmount) {
            const check = this.getSelectedCheck();
            if (check) {
                check.discount = discount;
                check.discountAmount = discountAmount;
            }
            const checks = _.cloneDeep(this.checks);
            const index = _.findIndex(this.checks, (check => check.id == this.selectedCheck));
            checks.splice(index, 1, check);
            this.saveChecks(checks);
        },
        getTip() {
            return parseFloat(this.getSelectedCheck()?.tipAmount || 0);
        },
        setTip(tip, tipAmount) {
            const check = this.getSelectedCheck();
            if (check) {
                check.tip = tip;
                check.tipAmount = tipAmount;
            }
            const checks = _.cloneDeep(this.checks);
            const index = _.findIndex(this.checks, (check => check.id == this.selectedCheck));
            checks.splice(index, 1, check);
            this.saveChecks(checks);
        },
        setTable(tableId, tableName) {
            this.table = {tableId, tableName};
            window.sessionStorage.setItem('TABLE', JSON.stringify(this.table));
        },
        setClient(client) {
            this.client = {accountId: client.accountid > 0 || client.externalAccountId, accountName: client.name};
            window.sessionStorage.setItem('CLIENT', JSON.stringify(this.client));
        },
        setOnlineOrderType(onlineOrderType) {
            const check = this.getSelectedCheck();
            if (check) {
                check.onlineOrderType = onlineOrderType;
            }
            const checks = _.cloneDeep(this.checks);
            const index = _.findIndex(this.checks, (check => check.id == this.selectedCheck));
            checks.splice(index, 1, check);
            this.saveChecks(checks);
        },
        calculateNetTotal() {
            this.sale.amount = this.saleItems.reduce((total, curr) => total + curr.total, 0);
            const totalTax = _.flatMap(this.saleItems, 'taxes')
                .filter(item => !_.isEmpty(item))
                .reduce((total, curr) => total + curr.taxAmount, 0);
            this.sale.netTotal = this.sale.amount - totalTax;
            this.saveSale();
        },
        getItemById(itemId) {
            return this.saleItems.filter(sale => sale.itemId === itemId)
        },
        getFinalSale() {
            const {saleItems, ...selectedCheck} = this.getSelectedCheck();
            const amount = saleItems.reduce((total, current) => total + current.total, 0);

            const sale = {
                isSplitCheck: this.checks.length > 1,
                ...selectedCheck,
                saleDetails: saleItems,
                ..._.pick(this.sale, ['invoiceId', 'invoiceNumber']),
                netTotal: formatParseNumber(selectedCheck.netTotal),
                amount: formatParseNumber(selectedCheck.amount),
                grandTotal: calculateGrandTotal(amount, selectedCheck),
                ...this.client,
                ...this.table,
            }
            if (this.checks.length > 1) {
                const parkedSale = {
                    typeid: TYPE_ID_SALE,
                    status: STATUS_PARKED,
                };
                if (this.isSplitEqual) {
                    const divider = this.checks.length;
                    const multiplier = divider - 1;
                    parkedSale.amount = formatParseNumber((this.sale.amount / divider) * multiplier);
                    parkedSale.netTotal = formatParseNumber((this.sale.netTotal / divider) * multiplier);
                    parkedSale.grandTotal = formatParseNumber((this.sale.amount / divider) * multiplier);
                    parkedSale.saleDetails = this.saleItems.map(item => ({
                        ...item,
                        total: formatParseNumber((item.total / divider) * multiplier),
                        originalSellingPrice: formatParseNumber(item.total),
                        taxes: item.taxes.map(tax => ({
                            ...tax,
                            taxAmount: formatParseNumber((tax.taxAmount / divider) * multiplier)
                        })),
                        isSplitItem: true,
                    }));
                } else {
                    let saleDetails = [];
                    this.checks.forEach(check => {
                        if (check.id != this.selectedCheck) {
                            saleDetails = saleDetails.concat(check.saleItems);
                        }
                    })
                    parkedSale.saleDetails = saleDetails;
                    parkedSale.amount = formatParseNumber(saleDetails.reduce((total, current) => total + current.total, 0));
                    parkedSale.netTotal = formatParseNumber(parkedSale.amount - _.flatMap(saleDetails, 'taxes')
                        .filter(item => !_.isEmpty(item))
                        .reduce((total, curr) => total + curr.taxAmount, 0));
                    parkedSale.grandTotal = parkedSale.amount;
                }
                if (parkedSale.amount > 0) {
                    sale.parkSplitSale = parkedSale;
                }
            }
            return sale;
        },
        processSaleResponse(req, res) {
            if (res.parkSplitSalePayload && req.parkSplitSale) {
                const sale = {
                    ...req.parkSplitSale,
                    invoiceNumber: res.parkSplitSalePayload.invoiceNumber,
                    invoiceId: res.parkSplitSalePayload.invoiceId,
                }
                this.setSale(sale);
                const checks = _.cloneDeep(this.checks);
                const index = _.findIndex(checks, (check => check.id == this.selectedCheck));
                checks.splice(index, 1);
                this.saveChecks(checks);
                this.setSelectedCheck(checks[0].id);
            } else {
                this.clearStore();
            }
        },
        clearChecks() {
            const checks = this.checks.map((check, index) => ({
                ...check,
                saleItems: index === 0 ? this.saleItems : []
            }));
            this.setSelectedCheck(checks[0].id);
            this.saveChecks(checks);
        },
        clearStore() {
            this.checks = [];
            this.selectedCheck = null;
            this.isSplitEqual = false;
            this.sale = DEFAULT_SALE;
            this.saleItems = [];
            this.client = {};
            this.table = {};
            window.sessionStorage.removeItem('CHECKS');
            window.sessionStorage.removeItem('SELECTED_CHECK');
            window.sessionStorage.removeItem('IS_SPLIT_EQUAL');
            window.sessionStorage.removeItem('SALE');
            window.sessionStorage.removeItem('SALE_ITEMS');
            window.sessionStorage.removeItem('CLIENT');
            window.sessionStorage.removeItem('TABLE');
        }
    }
}
