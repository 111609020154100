import {useEffect, useState} from 'react';
import Header from "../../Components/Header";
import withUserAuth from "../../Behavior/withUserAuth";
import {deleteSection, getAllSections} from "../../API";
import ErrorDialog from "../../Components/ErrorDialog";
import Loader from "../../Components/Loader";
import {Link} from "react-router-dom";
import {MENUS, PATH_SETTINGS_SECTION} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const Sections = () => {
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});

    const sendAPIRequest = async () => {
        const {code, data, error} = await getAllSections();
        if (code === 200) {
            setSections(data.kitchens);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest();
    }, []);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const handleDelete = async ({kitchenId, name}) => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
        if (isConfirm) {
            setLoading(true);
            const {code, error} = await deleteSection({kitchenId})
            if (code === 200) {
                await sendAPIRequest();
            } else {
                setLoading(false);
                setError({message: error, retry: false});
            }
        }
    }

    const onRetry = !!error.retry ? handleRetry : null;

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Sections"} hasBack/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                {/* Items */}
                <div className="section item-amend-category mt-2">
                    {/* details */}
                    <ul className="listview image-listview separate-list inset no-line no-arrow m-0">
                        {
                            sections.map(section => (
                                <li key={section.kitchenId}>
                                    <Link to={{pathname: PATH_SETTINGS_SECTION, search: `id=${section.kitchenId}`}}
                                          state={section} className="item">
                                        <div className="in">
                                            <div>{section.name}</div>
                                        </div>
                                    </Link>
                                    <span onClick={() => handleDelete(section)}
                                          className="delete-item py-3 ps-1 pe-3">
                                      <ion-icon name="close"/>
                                    </span>
                                </li>
                            ))
                        }

                    </ul>
                    <Link to={PATH_SETTINGS_SECTION} className="btn add-tag-anchor btn-primary mt-1">
                      <span>
                        <ion-icon name="add-circle"/>
                      </span>
                        Add Section
                    </Link>
                </div>
            </div>

        </>
    )
}

export default withUserAuth(Sections);
