import {useEffect, useState} from 'react';
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {MENUS, TYPE_AMOUNT} from "../../constants";
import {formatNumber} from "../../Utils";
import {useNavigate} from "react-router-dom";
import {usePosStore} from "../../Store";
import SideMenu from "../../Components/SideMenu";

const POSCustom = () => {
    const posStore = usePosStore();
    const sale = posStore.sale;
    const navigate = useNavigate();

    const saleAmount = parseFloat(sale.amount) - parseFloat(sale.discountAmount);

    const [tip, setTip] = useState(() => {
        return sale.tipAmount || 0;
    });
    const [newBalance, setNewBalance] = useState(() => {
        return saleAmount + tip;
    });
    const [type, setType] = useState(TYPE_AMOUNT);

    useEffect(() => {
        calculateBalance();
    }, [tip, type]);

    const calculateBalance = () => {
        const _tip = parseFloat(tip);
        let balance;
        if (type === TYPE_AMOUNT) {
            balance = saleAmount + _tip;
        } else {
            balance = saleAmount + ((saleAmount * _tip) / 100);
        }
        setNewBalance(formatNumber(balance));
    }

    const handleSave = async () => {
        const _tip = parseFloat(tip);
        let amount, percentage;
        if (type === TYPE_AMOUNT) {
            amount = _tip;
            percentage = (100 * amount) / saleAmount
        } else {
            percentage = _tip;
            amount = (saleAmount * percentage) / 100;
        }
        posStore.setTip(formatNumber(percentage), formatNumber(amount));
        navigate(-1);
    }

    return <>
        <Header title={"Add Tip"} hasBack/>
        <SideMenu selected={MENUS.POS}/>
        <div id="appCapsule">
            {/* Items */}
            <div className="section item-amend-variation mt-2">
                {/* details */}
                <div className="section-title mt-3">Details</div>
                <div className="card card-border">
                    <div className="card-body">
                        <form>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="amount">Amount</label>
                                    <input type="text" className="form-control" id="amount"/>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"/>
                                    </i>
                                </div>
                            </div>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="selectCategory">Category</label>
                                    <select className="form-control custom-select" id="selectCategory">
                                        <option value={0}>select Category</option>
                                        <option value={1}>select 1</option>
                                        <option value={1}>select 2</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="tax">Name</label>
                                    <input type="text" className="form-control" id="tax"/>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"/>
                                    </i>
                                </div>
                            </div>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="selectTax">Tax</label>
                                    <select className="form-control custom-select" id="selectTax">
                                        <option value={0} selected>Add text</option>
                                        <option value={1}>select 1</option>
                                        <option value={1}>select 2</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/* * details */}
                <div className="mt-4">
                    <a href="index.html" className="btn btn-primary btn-shadow btn-lg btn-block">Confirm</a>
                </div>
            </div>
        </div>

    </>
}

export default withUserAuth(POSCustom);
