import * as React from 'react';
import {useEffect, useState} from 'react';

import {PATH_LOGIN} from "../constants";
import {isEmptyObj, validateEmail} from '../Utils';
import {Link} from "react-router-dom";
import {signupUser} from "../API";
import ErrorDialog from "../Components/ErrorDialog";
import SignupDialog from "../Components/SignupDialog";
import Loader from "../Components/Loader";
import PasswordField from "../Components/PasswordField";

const Signup = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        window.fbFunc();
    }, [])

    const handleSignUp = async (e) => {
        e.preventDefault();
        let errors = {};
        if (!name) {
            errors.name = 'Name is required.';
        }
        if (!email) {
            errors.email = 'Email is required';
        } else if (!validateEmail(email)) {
            errors.email = 'Please enter valid email';
        }
        if (!password || !confirmPassword) {
            if (!password) {
                errors.password = 'Password is required';
            }
            if (!confirmPassword) {
                errors.confirmPassword = 'Confirm Password is required';
            }
        } else if (password !== confirmPassword){
            errors.password = `Password and Repeat password doesn't match`;
        }
        if (!isEmptyObj(errors)) {
            setFormErrors(errors);
            return;
        }
        setFormErrors({});
        setLoading(true);
        const res = await signupUser({name, email, password});
        setLoading(false);
        if (res.code === 200) {
            setSuccess(res.message);
        } else {
            setError(res.error);
        }
    }

    return (
        <>
            { loading && <Loader /> }
            <SignupDialog isOpen={!!success} />
            <ErrorDialog isOpen={!!error} content={error} onClose={() => setError('')} />
            <div id="appCapsule" className="pt-0">
                <div className="section mt-2">
                    <h2>Sign Up</h2>
                </div>
                <div className="section sign-up-form mb-5 p-2">
                    <form onSubmit={handleSignUp} noValidate>
                        <div className="card card-border">
                            <div className="card-body">
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="email1">Name</label>
                                        <input type="text" className="form-control" id="text1"
                                               onChange={(e) => setName(e.target.value)}
                                        />
                                        <span className="text-danger">{formErrors.name}</span>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="email1">E-mail</label>
                                        <input type="email" className="form-control" id="email1"
                                               onChange={(e) => setEmail(e.target.value)}/>
                                        <span className="text-danger">{formErrors.email}</span>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <PasswordField
                                        label={"Password"}
                                        password={password}
                                        setPassword={setPassword}
                                        error={formErrors.password}
                                    />

                                </div>
                                <div className="form-group basic">
                                    <PasswordField
                                        label={"Repeat Password"}
                                        password={confirmPassword}
                                        setPassword={setConfirmPassword}
                                        error={formErrors.confirmPassword}
                                    />
                                </div>
                                <div className="custom-control custom-checkbox mt-2 mb-1">
                                    By signing up to our services, means you agree to the
                                    <a className="section-title" href="https://rapedido.esterms-of-service/"> Seller
                                        Agreement &amp; Privacy
                                        Policy</a>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-primary btn-shadow btn-lg btn-block mt-4" data-bs-toggle="modal" data-bs-target="#DialogIconedSuccess">Sign Up</button>
                        <div className="section text-center mt-2">
                            Already Have an account?&nbsp;
                            <Link className="section-title" to={PATH_LOGIN}>Sign In</Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );}

export default Signup;
