import {toast} from "react-toastify";
import _ from 'lodash';
import {SERVER_URL} from "../API";

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const isEmptyObj = (obj) => {
    return Object.keys(obj).length <= 0;
}

export const cleanObj = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}

export const replaceItem = (array, newItem, key) => {
    const index = _.findIndex(array, item => item[key] == newItem[key]);
    if (index >= 0) {
        array.splice(index, 1, newItem);
    }
    return array;
}

export const calculateGrandTotal = (amount, {discountAmount, tipAmount}) => {
    const grandTotal = parseFloat(amount || 0) - parseFloat(discountAmount || 0) + parseFloat(tipAmount || 0);
    return parseFloat(formatNumber(grandTotal));
}

export const formatNumber = (number) => {
    if (Number.isNaN(number)) {
        return '';
    }
    const fixed = parseFloat(number).toFixed(2);
    if (fixed == parseInt(number)) {
        return parseInt(number);
    }
    return fixed;
}

export const formatPrintNumber = (number) => {
    if (Number.isNaN(number)) {
        return '';
    }
    const fixed = parseFloat(number).toFixed(2);
    return fixed;
}

export const formatParseNumber = (number) => {
    if (Number.isNaN(number)) {
        return 0;
    }
    const fixed = parseFloat(number).toFixed(2);
    if (fixed == parseInt(number)) {
        return parseInt(number);
    }
    return parseFloat(fixed);
}

export const formatStripeAmount = (amount) => {
    if (Number.isNaN(amount)) {
        return 0;
    }
    const number = amount / 100;
    const fixed = parseFloat(number).toFixed(2);
    if (fixed == parseInt(number)) {
        return parseInt(number).toFixed(2);
    }
    return parseFloat(fixed).toFixed(2);
}

export const formatStripeDate = (date, prefix) => {
    if (!date) {
        return "";
    }
    
    var d = new Date(date).toString().slice(4,15)
    if(prefix)
        return prefix + d;
    return d;
}
//

export const calculateActualProfit = (retailPrice, costPrice) => {
    if (retailPrice > 0 && costPrice > 0) {
        return retailPrice - costPrice;
    }
    return 0;
}

export const calculateTaxAmount = (retailPriceStr, taxRateStr) => {
    const retailPrice = parseFloat(retailPriceStr);
    const taxRate = parseFloat(taxRateStr);
    return formatNumber(retailPrice * (taxRate / 100));
}

export const calculateRetailPrice = (salePriceStr, taxRate) => {
    const salePrice = parseFloat(salePriceStr);
    const taxAmount = parseFloat(calculateTaxFromSalePrice(salePrice, taxRate));
    return formatNumber(salePrice - taxAmount);
}

export const calculateTaxFromSalePrice = (salePriceStr, taxRate) => {
    const salePrice = parseFloat(salePriceStr);
    const retailPrice = salePrice / (1 + (taxRate / 100));
    const taxAmount = retailPrice * (taxRate / 100);
    return formatNumber(taxAmount);
}

export const calculateProfit = (src, salePriceStr, costPriceStr, marginStr, taxRateStr) => {
    let salePrice = parseFloat(salePriceStr);
    let costPrice = parseFloat(costPriceStr);
    let margin = parseFloat(marginStr) || 0;
    let taxRate = parseFloat(taxRateStr)
    let taxAmount = 0;
    let profit = 0;
    let retailPrice = 0;
    if (src === 'margin') { // Price
        if (costPrice) {
            margin = margin / 100;
            margin = margin / (1 - margin);
            margin = 1 + margin;
            retailPrice = costPrice * margin;
            profit = calculateActualProfit(retailPrice, costPrice);
            taxAmount = calculateTaxAmount(retailPrice, taxRate);
            salePrice = taxAmount + retailPrice;
        }
    } else {
        if (!!costPrice && !!salePrice) {
            retailPrice = salePrice / (1 + (taxRate / 100));
            profit = calculateActualProfit(retailPrice, costPrice);
            taxAmount = calculateTaxAmount(retailPrice, taxRate);
            if (profit > 0 || retailPrice > 0) {
                margin = (profit / retailPrice) * 100
            }
        } else {
            margin = 0;
        }
    }
    return {
        margin: formatNumber(margin),
        salePrice: formatNumber(salePrice),
        profit: formatNumber(profit),
        retailPrice: formatNumber(retailPrice),
        taxAmount: formatNumber(taxAmount),
        costPrice: formatNumber(costPrice),
    };
};

export const showSuccessToast = (text) => {
    toast.success(text, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export const showErrorToast = (text) => {
    toast.error(text, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export const getUrl = (url) => {
    if (url.startsWith("http")) {
        return url;
    }
    return SERVER_URL + url;
}
