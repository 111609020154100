import { useState } from "react";
import {isEmptyObj, validateEmail} from "../Utils";
import { forgotPassword } from "../API";
import Loader from "../Components/Loader";
import SignupDialog from "../Components/SignupDialog";
import ErrorDialog from "../Components/ErrorDialog";
import InfoDialog from "../Components/InfoDialog";
import {Link} from "react-router-dom";
import {PATH_LOGIN} from "../constants";

const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let errors = {};

        if (!email) {
            errors.email = 'Email is required';
        } else if(!validateEmail(email)) {
            errors.email = 'Please enter valid email';
        }
        console.log('email', email);
        if (!isEmptyObj(errors)) {
            setFormErrors(errors);
            return;
        }
        setFormErrors({});
        setLoading(true);
        const res = await forgotPassword({ email });
        setLoading(false);
        if (res.code === 200) {
            setSuccess(res.message);
        } else {
            setError(res.error);
        }
    }
    return (
        <div id="appCapsule" className="pt-0">
            { loading && <Loader /> }
            <InfoDialog isOpen={!!success} content={success} onClose={() => setSuccess('')}/>
            <ErrorDialog isOpen={!!error} content={error} onClose={() => setError('')} />
            <div className="section mt-2">
                <h2>Forgot Password?</h2>
            </div>
            <div className="section forgot-form mb-5 p-2">
                <form onSubmit={handleFormSubmit} noValidate >
                    <div className="card card-border">
                        <div className="card-body">
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="email1">E-mail</label>
                                    <input type="email" className="form-control" id="email1" onChange={(e) => setEmail(e.target.value)} />
                                    <span className="text-danger">{formErrors.email}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary btn-shadow btn-lg btn-block mt-4">Reset</button>
                    <div className="section text-center mt-2">
                        Already Have an account?&nbsp;
                        <Link className="section-title" to={PATH_LOGIN}>Sign In</Link>
                    </div>
                </form>
            </div>
        </div>

    )
}

export default ForgotPassword;
