import {Print} from "react-easy-print";
import './Print.css';
import {getOrgData} from "../DB";
import _ from "lodash";
import {calculateGrandTotal, formatPrintNumber, getUrl} from "../Utils";
import {PAYMENT_TYPES} from "../constants";

const PrintView = ({sale}) => {
    const company = getOrgData();

    const logoUrl = getUrl(company?.logourl || '')
    const saleItems = sale.saleDetails || sale.saleItems;

    const groupedItems = _.flatMap(_.mapValues(_.groupBy(saleItems, 'itemId'),
        (item => ({
                id: item[0].itemId,
                name: item[0].itemName,
                imageUrl: item[0].imageUrl,
                isCustomItem: item[0].isCustomItem,
                taxes: item[0].taxes,
                total: item.reduce((total, current) => total + current.total, 0),
                quantity: item.length,
            })
        )), (item) => item);

    const amount = groupedItems.reduce((total, current) => total + current.total, 0);

    const groupedTax = _.flatMap(_.mapValues(_.groupBy(_.flatMap(saleItems, 'taxes')
        .filter(item => !_.isEmpty(item)), 'taxId'), tax => ({
        value: tax[0].value,
        name: tax[0].name,
        taxAmount: tax.reduce((total, curr) => total + parseFloat(curr.taxAmount), 0)
    })));

    const taxAmount = groupedTax.reduce((total, curr) => total + curr.taxAmount, 0);

    const netTotal = amount - taxAmount;

    const grandTotal = calculateGrandTotal(amount, sale);

    const salePayment = sale.salePayments?.[0];

    // document.body.style.backgroundColor = 'white';
    return (
        <Print printOnly>
            <div className="tab">
                <div className="tabb">
                    <div className="summary">
                        <img
                            className="logo"
                            src={logoUrl}/>
                        <div className="title pt">{company.name}</div>
                        <div className="subtitle pt2">{company.address}</div>
                        <div className="subtitle pd2">Contact: {company.orgbusinesscontact}</div>
                    </div>
                    <div className="divider"></div>
                    <div className="text pt2">Org. Nr: {company.orgnumber || ''}</div>
                    <div className="text">Receipt No: {sale.invoiceNumber || ''} </div>
                    <div className="text">{sale.invoiceDateStr || ''}</div>
                    <div className="text">Client: {sale.accountName || ''}</div>
                    <div className="divider"></div>
                    {
                        groupedItems.map(item => (
                            <div key={item.id} className="item pd2 pt2">
                                <div className="name">{item.quantity} x {item.name}</div>
                                <div className="amount">{formatPrintNumber(item.total)}</div>
                            </div>
                        ))
                    }
                    <div className="dotted-divider"></div>
                    <div className="item pt">
                        <div className="name">Net Total</div>
                        <div className="amount">{formatPrintNumber(netTotal)}</div>
                    </div>
                    <div className="item pt">
                        <div className="name">Discount</div>
                        <div className="amount">{formatPrintNumber(sale.discountAmount)}</div>
                    </div>
                    <div className="item pt pd">
                        <div className="name">Tip</div>
                        <div className="amount">{formatPrintNumber(sale.tipAmount)}</div>
                    </div>
                    {
                        groupedTax.map((tax, index) => (
                            <div key={index} className="item pt">
                                <div className="name">{tax.name}({tax.value}%)</div>
                                <div className="amount">{formatPrintNumber(tax.taxAmount)}</div>
                            </div>
                        ))
                    }
                    <div className="dotted-divider"></div>
                    <div className="item pt2">
                        <div className="total">Grand Total</div>
                        <div className="total-amount">{formatPrintNumber(grandTotal)}</div>
                    </div>
                    {
                        salePayment && (
                            <div className="item">
                                <div className="name">{PAYMENT_TYPES[salePayment.paymentTypeId]}</div>
                                <div className="amount">{formatPrintNumber(salePayment.amount)}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </Print>
    )
};

export default PrintView;


