import {useEffect, useState} from 'react';
import Header from "../../Components/Header";
import withUserAuth from "../../Behavior/withUserAuth";
import {deleteTax, getAllTaxes} from "../../API";
import ErrorDialog from "../../Components/ErrorDialog";
import Loader from "../../Components/Loader";
import {Link} from "react-router-dom";
import {MENUS, PATH_SETTINGS_TAX} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const Taxes = () => {
    const [taxes, setTaxes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});

    const sendAPIRequest = async () => {
        const {code, data, error} = await getAllTaxes();
        if (code === 200) {
            setTaxes(data.taxes || []);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest();
    }, []);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const handleDelete = async ({companyTaxId, name}) => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
        if (isConfirm) {
            setLoading(true);
            const {code, error} = await deleteTax({companyTaxId})
            if (code === 200) {
                await sendAPIRequest();
            } else {
                setLoading(false);
                setError({message: error, retry: false});
            }
        }

    }

    const onRetry = !!error.retry ? handleRetry : null;

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Taxes"} hasBack/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                {/* Items */}
                <div className="section item-amend-category mt-2">
                    <ul className="listview image-listview separate-list inset no-line no-arrow m-0">

                        {
                            taxes.map(tax => (
                                <li key={tax.companyTaxId}>
                                    <Link to={{pathname: PATH_SETTINGS_TAX, search: `id=${tax.companyTaxId}`}}
                                          state={{tax}} className="item">
                                        <div className="in">
                                            <div>{tax.name}</div>
                                        </div>
                                        <span>{tax.tax}%</span>
                                    </Link>
                                    <span onClick={() => handleDelete(tax)}
                                          className="delete-item py-3 ps-1 pe-3">
                                      <ion-icon name="close"/>
                                    </span>
                                </li>
                            ))
                        }
                    </ul>
                    <Link to={PATH_SETTINGS_TAX} className="btn add-tag-anchor btn-primary mt-1">
                      <span>
                        <ion-icon name="add-circle"/>
                      </span>
                        Add Tax
                    </Link>
                </div>
            </div>

        </>
    )
}

export default withUserAuth(Taxes);
