import {useState} from "react";
import {Link} from "react-router-dom";
import {PATH_SIGNUP_WHAT_DO_YOU_SELL} from "../constants";

const SignupFirst = () => {
    const [businessType, setBusinessType] = useState('business');
    return (
        <>
            <div className="appHeader">
                <div className="pageTitle">Your Business</div>
            </div>
            <div id="appCapsule">
                <div className="section mt-2">
                    <h2>Tell us a little bit about yourself, so we can better set up your account. </h2>
                    <h4 className="mt-3">What type of business are you?</h4>
                </div>
                {/* Options */}
                <div className="section line-btn-group mt-3">
                    <div className="btn-group" role="group">
                        <input type="radio"
                               className="btn-check "
                               name="btnradioGroup1"
                               id="btnradio1"
                               checked={businessType === 'business'}
                               onChange={() => setBusinessType('business')}
                        />
                        <label className="btn btn-outline-primary" htmlFor="btnradio1">Business</label>
                        <input
                            type="radio"
                            className="btn-check "
                            name="btnradioGroup1"
                            id="btnradio2"
                            checked={businessType === 'individual'}
                            onChange={() => setBusinessType('individual')}
                        />
                        <label className="btn btn-outline-primary" htmlFor="btnradio2">Individual</label>
                    </div>
                </div>
                {/* * Options */}
                <div className="section mt-4">
                    <Link to={PATH_SIGNUP_WHAT_DO_YOU_SELL} state={{businessType}}
                          className="btn btn-primary btn-shadow btn-lg btn-block">Continue</Link>
                </div>
            </div>
        </>
    );
}

export default SignupFirst;
