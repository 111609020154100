import {useNavigate} from "react-router-dom";
import {usePosStore} from "../../Store";
import Clients from "../Clients/Clients";


const POSClients = () => {
    const navigate = useNavigate();
    const posStore = usePosStore();

    const handleClick = (client) => {
        posStore.setClient(client);
        navigate(-1)
    }
    return (
        <Clients onClientClick={handleClick}/>
    )
}

export default POSClients;
