import Modal from 'react-modal';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0, 0.5)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'none',
        inset: 'none',
        borderRadius: 'none',
        border: 0,
        background: 'none',
    },
};

const TaxesDialog = ({isOpen, taxes = [], selectedTaxes, onSelectionChange, onClose}) => {

    return (
        <Modal
            style={customStyles}
            isOpen={isOpen}>
            <div className="dialogbox show">
                <div className="modal-dialog">
                    <div className="modal-content card-border">
                        <div className="modal-icon" style={{fontSize: '18px', marginBottom: 0, marginTop: '16px'}}>
                            Tax
                        </div>
                        <div className="modal-body listing mt-2">
                            {
                                taxes.map(tax => {
                                    const isChecked = selectedTaxes.find(t => t.companyTaxId === tax.companyTaxId);
                                    return (
                                        <div key={tax.companyTaxId} className="form-check"
                                             style={{margin: '16px 0', display: 'flex'}}>
                                            <input
                                                type={"checkbox"}
                                                id={`tax-checkbox-${tax.companyTaxId}`}
                                                checked={isChecked}
                                                onChange={(e) => onSelectionChange(e.target.checked, tax)}
                                                className="form-check-input"
                                            />
                                            <label
                                                className={`form-check-label checkbox`}
                                                style={{flex: 1}}
                                                htmlFor={`tax-checkbox-${tax.companyTaxId}`}>{tax.name}</label>
                                            <div>
                                                {tax.tax}%
                                            </div>
                                        </div>

                                    )
                                })
                            }
                            {
                                taxes.length === 0 && <div>No tax available.</div>
                            }
                        </div>
                        <div className="modal-footer">
                            <div onClick={onClose} className="btn-inline">
                                <a className="btn">Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default TaxesDialog;
