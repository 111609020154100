import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import withUserAuth from '../../Behavior/withUserAuth'
import {getAllItems} from "../../API";
import Loader from "../../Components/Loader";
import ItemsList from "../../Components/ItemsList";
import Header from "../../Components/Header";
import TextSearch from "../../Components/TextSearch";
import ErrorDialog from "../../Components/ErrorDialog";
import {MENUS, PATH_ORDERS_QR, PATH_POS_CHECKOUT, PATH_POS_ITEM} from "../../constants";
import {useItemStore, usePosStore} from "../../Store";
import {useObserver} from "mobx-react-lite";
import {cleanObj, showErrorToast} from "../../Utils";
import SideMenu from "../../Components/SideMenu";

const POS = ({onSidePanelOpen}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [searchText, setSearchText] = useState('');

    const posStore = usePosStore();
    const itemStore = useItemStore();

    const sendAPIRequest = async () => {
        const res = await getAllItems();
        if (res.code === 200) {
            itemStore.setAll(res.data)
        } else {
            setError(res.error);
        }
        setLoading(false);
    }

    useEffect(async () => {


        await sendAPIRequest();
    }, []);

    const handleRetry = async () => {
        setError('');
        setLoading(true)
        await sendAPIRequest();
    }

    const onQrCodeClick = () => {
        navigate(PATH_ORDERS_QR);
    }

    const onCheckoutClick = () => {
        if (posStore.saleItems.length > 0) {
            navigate(PATH_POS_CHECKOUT);
        } else {
            showErrorToast('Cart is empty!');
        }
    }

    const onSearchClick = () => {
        setShowSearch(true);
    }

    const onSearchClose = () => {
        // setShowSearch(false);
        setSearchText('');
    }

    const onItemClick = (item) => {
        navigate(PATH_POS_ITEM, {state: {item: cleanObj(item)}});
    }

    return useObserver(() => {
        if (loading) return <Loader />
        return (
            <>
                {!!error &&
                    <ErrorDialog isOpen={true} content={error} onClose={() => setError('')} onRetry={handleRetry}/>}
                <Header title={"Sell"} onSidePanelClick={onSidePanelOpen} onQrCodeClick={onQrCodeClick}
                        onSearchClick={onSearchClick} onCheckoutClick={onCheckoutClick}
                        checkoutCount={posStore.saleItems.length}/>
                <SideMenu selected={MENUS.POS}/>
                <TextSearch isVisible={showSearch} onClose={onSearchClose} onTextChange={setSearchText}
                            searchText={searchText}/>
                <ItemsList items={itemStore.getAllItems()} searchText={searchText} onPOS={true} setSearchText={setSearchText} onItemClick={onItemClick}/>
            </>
        )
    });
}

export default withUserAuth(POS);

