import {createPaymentIntent, getConnectionToken} from "../API";

let terminal;

const discoverReader = async (locationId, useTestMode) => {

    const discoverResult = await terminal.discoverReaders({simulated: useTestMode || false, location: locationId});

    if (discoverResult.error) {
        generateResponse(discoverResult.error);
    } else if (discoverResult.discoveredReaders.length === 0) {
        generateResponse('No available readers.');
    } else {
        await connectReader(discoverResult);
    }
}

const connectReader = async (discoverResult) => {

    const selectedReader = discoverResult.discoveredReaders[0];
    const connectResult = await terminal.connectReader(selectedReader, {fail_if_in_use: true});

    if (connectResult.error) {
        generateResponse(connectResult.error);
    } else {
        console.log('Connected to reader:', connectResult.reader.label);
    }
}

const collectPaymentDetails = async (paymentIntentSecret) => {
    const {error, paymentIntent} = await terminal.collectPaymentMethod(paymentIntentSecret);
    if (error) {
        await generateResponse(error);
    } else {
        await processPayment(paymentIntent);
    }
}

const processPayment = async (pendingPaymentIntent) => {

    const {error, paymentIntent} = await terminal.processPayment(pendingPaymentIntent);
    if (error) {
        generateResponse(error);
    } else if (paymentIntent) {

        if (paymentIntent.status == 'requires_payment_method') {
            alert("Your selected payment method has been declined. Please choose again");
            await collectPaymentDetails(paymentIntent.client_secret);
        } else if (paymentIntent.status == 'requires_confirmation') {
            alert("Temporary connectivity problem occurred. Retrying please wait...");
            await processPayment(paymentIntent);
        } else if (paymentIntent.status == 'nil') {
            alert("Request to Stripe timed out, unknown PaymentIntent status. Retrying please wait...");
            await processPayment(paymentIntent);
        } else if (paymentIntent.status == 'requires_capture') {
            await capturePayment(paymentIntent.id);
        } else {
            generateResponse(`Something went wrong. Status ${paymentIntent.status}`);
        }
    }
};

const capturePayment = async (paymentIntentId) => {
    const {data, code, error} = await capturePayment(paymentIntentId);
    if (code === 200) {
        generateResponse(data.responseMessage);
    } else {
        generateResponse(error);
    }

    // $.ajax({
    //     url: "/StripeTerminal/CapturePaymentIntent",
    //     type: "POST",
    //     data: { paymentIntentId: paymentIntentId },
    //     success(response) {
    //
    //         generateResponse(response.responseMessage);
    //     },
    //     error() {
    //         generateResponse("Something went wrong")
    //     }
    // });
}

const generateResponse = async (response) => {
    if (response && response.code) {
        //do any appropriate action based on error code
        switch (response.code) {
            case 'no_established_connection':
                break;
            case 'no_active_collect_payment_method_attempt':
                break;
            case 'no_active_read_reusable_card_attempt':
                break;
            case 'canceled':
                break;
            case 'cancelable_already_completed':
                break;
            case 'cancelable_already_canceled':
                break;
            case 'network_error':
                break;
            case 'network_timeout':
                break;
            case 'already_connected':
                break;
            case 'failed_fetch_connection_token':
                break;
            case 'discovery_too_many_readers':
                break;
            case 'invalid_reader_version':
                break;
            case 'reader_error':
                break;
            case 'command_already_in_progress':
                break;
            default:
                alert(response || "Something went wrong. Unable to process the request");
        }

        alert(response.message);
    } else {
        alert(response.message || response || "Something went wrong. Unable to process the request")
    }

    console.log(response);
    // location.reload(true);
};

const setupTerminalConnection = () => {
    resetTerminalConnection();
    terminal = window.StripeTerminal.create({
        onFetchConnectionToken: async () => {
            const {data} = await getConnectionToken();
            const {isSuccessful, connectionTokenResponse} = await data;
            if (isSuccessful) {
                return connectionTokenResponse;
            } else {
                generateResponse(connectionTokenResponse);
            }
        },
        onUnexpectedReaderDisconnect: () => {
            alert("Experiencing disconnects")
        }
    });
}

const resetTerminalConnection = () => {
    if (terminal) {
        const terminalStatus = terminal.getConnectionStatus();
        if (terminalStatus == 'connected') {
            terminal.disconnectReader();
            terminal.clearCachedCredentials();
        }
    }
};

export const CollectPayment = async (readerLocationId, useTestMode) => {
    if (!terminal) {
        setupTerminalConnection();
    }

    //If not already connected, make the connection
    if (terminal && terminal.getConnectionStatus() !== 'connected') {
        // updateText(_this, 'Connecting with reader');
        await discoverReader(readerLocationId, useTestMode)
    }

    if (terminal.getConnectionStatus() == 'connected') {
        // updateText(_this, 'Processing...')
        const {data, code, error} = await createPaymentIntent();
        if (code === 200) {
            if (data.isSuccessful && data.paymentIntentSecret) {
                await collectPaymentDetails(data.paymentIntentSecret);
            } else {
                generateResponse(data.responseMessage)
            }
        } else {
            generateResponse(error);
        }
    }
}

