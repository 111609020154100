import _ from 'lodash';

const getSessionObj = (KEY, Default, shouldParse = true) => {
    const objStr = window.sessionStorage.getItem(KEY);
    if (!_.isEmpty(objStr)) {
        if (shouldParse) {
            return JSON.parse(objStr);
        }
        return objStr;
    }
    return Default;
}

export function createInvoiceStore() {
    return {
        invoice: getSessionObj('INVOICE', {}),
        setInvoice(invoice) {
            this.invoice = invoice;
            window.sessionStorage.setItem('INVOICE', JSON.stringify(invoice));
        },
        setInvoiceLine(line) {
            const invoice = {...this.invoice};
            const lineIndex = invoice.lines.findIndex(l => line.lineId === l.lineId);
            if (lineIndex >= 0) {
                invoice.lines.splice(lineIndex, 1, line);
            } else {
                invoice.lines.push(line);
            }
            this.setInvoice(invoice);
        },
        removeInvoiceLine(lineId) {
            const invoice = {...this.invoice};
            const lineIndex = invoice.lines.findIndex(l => lineId === l.lineId);
            if (lineIndex >= 0) {
                invoice.lines.splice(lineIndex, 1);
            }
            this.setInvoice(invoice);
        },
        getInvoice() {
            return this.invoice;
        },
        clearStore() {
            this.invoice = {};
            window.sessionStorage.removeItem('INVOICE');
        }
    }
}
