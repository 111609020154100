import {useEffect, useState} from 'react';
import withUserAuth from "../../Behavior/withUserAuth";
import {getCashList} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../../Components/Spinner";
import {Link} from "react-router-dom";
import {DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, MENUS, PATH_SETTINGS_CASH_EDIT} from "../../constants";
import moment from "moment";
import SideMenu from "../../Components/SideMenu";

const PAGE_SIZE = 50;

const CASH_TYPE = {
    0: {
        iconClass: 'bg-primary',
        icon: 'arrow-forward-outline',
        textClass: 'text-primary',
        text: true,
    },
    'Take Cash': {
        sign: '-',
        iconClass: 'bg-danger',
        icon: 'arrow-down-outline',
        textClass: 'text-danger'
    },
    'Refund': {
        sign: '-',
        iconClass: 'bg-danger',
        icon: 'arrow-down-outline',
        textClass: 'text-danger'
    },
    'Put Cash': {
        sign: '+',
        iconClass: 'bg-success',
        icon: 'arrow-up-outline',
        textClass: 'text-success'
    },
    'Sale': {
        sign: '+',
        iconClass: 'bg-success',
        icon: 'arrow-up-outline',
        textClass: 'text-success'
    },

}

const CashList = () => {
    const [pageNo, setPageNo] = useState(1);
    const [cashList, setCashList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [hasMore, setHasMore] = useState(true);

    const sendAPIRequest = async (page = pageNo) => {

        const params = {page, pagesize: PAGE_SIZE};
        const {code, data, error} = await getCashList(params);
        if (code === 200) {
            setHasMore(data.isNextPageExist);
            if (page === 1) {
                setCashList(data.cashManagementList);
            } else {
                setCashList([...cashList, ...data.cashManagementList])
            }
            setPageNo((page + 1));
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest(1);
    }, []);

    // useEffect(() => {
    //     const root = document.getElementById('sidebar-content');
    //     if (!loading && hasMore && root.scrollHeight <= root.clientHeight) {
    //         sendAPIRequest();
    //     }
    // }, [loading, hasMore]);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest(1);
    }

    const onRetry = !!error.retry ? handleRetry : null;

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Cash List"} hasBack/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id='appCapsule'>
                {/* Items */}
                {/* details */}

                <InfiniteScroll
                    className="listview separate-list cash-list-wrapper image-listview inset"
                    style={{marginTop: '16px'}}
                    dataLength={cashList.length}
                    next={sendAPIRequest}
                    hasMore={hasMore}
                    loader={<Spinner/>}
                    scrollableTarget={"sidebar-content"}
                >
                    {
                        cashList.map(cash => {
                            const type = CASH_TYPE[cash.typeName] || CASH_TYPE[0];
                            return (
                                <li key={cash.invoiceId} className="items-card card card-border">
                                    <div className="item">
                                        <div className={`icon-box ${type.iconClass}`}>
                                            <ion-icon name={type.icon} Style={'margin: 0'}></ion-icon>
                                        </div>
                                        <div className="in">
                                            <div>
                                                <h4>{moment(cash.createdDate).format(DISPLAY_DATE_FORMAT)}</h4>
                                                <h5>{moment(cash.createdDate).format(DISPLAY_TIME_FORMAT)}</h5>
                                            </div>
                                            <div className="justify-content-center">
                                                <p style={{textAlign: 'right'}}
                                                   className={type.textClass}>{type.sign}{cash.amount}</p>
                                                <h4>{cash.typeName}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                    {
                        cashList.length === 0 &&
                        <span className={"fontsize-heading"} style={{textAlign: 'center'}}>No Entry Found</span>
                    }
                </InfiniteScroll>
                <div className="section row mt-2">
                    <div className="col-6 mt-2">
                        <a href="" className="btn btn-primary btn-shadow btn-lg btn-block">Cash
                            Box</a>
                    </div>
                    <div className="col-6 mt-2">
                        <Link to={PATH_SETTINGS_CASH_EDIT}
                              className="btn btn-primary btn-shadow btn-lg btn-block">Add/Take</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(CashList);
