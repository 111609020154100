import {useEffect, useState} from 'react';
import Header from "../../Components/Header";
import {useItemStore} from "../../Store";
import {Link} from "react-router-dom";
import {MENUS, PATH_ITEMS_CATEGORY} from "../../constants";
import {deleteCategory, getAllItems} from "../../API";
import {useObserver} from "mobx-react-lite";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import withUserAuth from "../../Behavior/withUserAuth";
import SideMenu from "../../Components/SideMenu";

const CategoryList = () => {
    const itemStore = useItemStore();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const sendAPIRequest = async () => {
        const res = await getAllItems();
        if (res.code === 200) {
            itemStore.setAll(res.data)
            itemStore.setAllItems(res.data.allitems)
        } else {
            setError(res.error);
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest();
    }, []);

    const handleRetry = async () => {
        setError('');
        setLoading(true)
        await sendAPIRequest();
    }

    const handleDeleteCategory = async ({categoryID, name}) => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
        if (isConfirm) {
            setLoading(true);
            const {code, error} = await deleteCategory({categoryid: categoryID})
            if (code === 200) {
                await sendAPIRequest();
            } else {
                setLoading(false);
                setError(error);
            }
        }
    }

    return useObserver(() => (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')} onRetry={handleRetry}/>}
            <Header title={"Edit Categories"} hasBack/>
            <SideMenu selected={MENUS.ITEMS}/>
            <div id="appCapsule">
                <div className="section items-edit-categories mt-2">
                    {/* details */}
                    <ul className="listview image-listview separate-list inset no-line no-arrow m-0">
                        {itemStore.getAllItems().map(category => (
                            <li key={category.categoryID}>
                                <Link
                                    to={{pathname: PATH_ITEMS_CATEGORY, search: `id=${category.categoryID}`}}
                                    state={{category: JSON.parse(JSON.stringify(category))}}
                                    className="item"
                                >
                                    <div className="in">
                                        <div>{category.name}</div>
                                    </div>
                                </Link>
                                <span onClick={() => handleDeleteCategory(category)}
                                      className="delete-item p-2">
                                   <ion-icon name="close"/>
                               </span>
                            </li>
                        ))
                        }
                    </ul>
                    <div style={{marginTop: '16px'}}>
                        <Link to={PATH_ITEMS_CATEGORY} className="btn add-tag-anchor btn-primary mt-1">
                          <span>
                            <ion-icon name="add-circle"/>
                          </span>
                            Add Category
                        </Link>
                    </div>
                </div>
            </div>
        </>
    ));
}

export default withUserAuth(CategoryList);
