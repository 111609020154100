import * as React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import Loader from '../Components/Loader'
import ErrorDialog from '../Components/ErrorDialog'
import {loginUser, loginWithToken} from '../API'
import {PATH_FORGOT_PASSWORD, PATH_HOME, PATH_SIGNUP} from "../constants";
import {isUserLoggedIn, setAuthToken, setOrgData, setUserData} from "../DB";
import {isEmptyObj, validateEmail} from "../Utils";
import PasswordField from "../Components/PasswordField";

const Login =  () => {
    const [searchParams] = useSearchParams();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);
    const [formErrors, setFormErrors] = React.useState({});
    const [error, setError] = React.useState('');
    const navigate = useNavigate();

    React.useEffect(async () => {
        if (isUserLoggedIn()) {
            navigate(PATH_HOME, {replace: true})
            return;
        }
        const token = searchParams.get("token");
        console.log('searchParams.get("token")', token);
        if (token) {
            const {code, data, error} = await loginWithToken(token);
            if (code === 200) {
                setUserData(data.user);
                setAuthToken(data.user.token);
                setOrgData(data.company);
                navigate(PATH_HOME, {replace: true})
            } else {
                setError(error)
            }
        }
    }, [])

    const handleLogin = async (e) => {
        e.preventDefault();
        let errors = {};
        if (!email) {
            errors.email = 'Email is required';
        } else if(!validateEmail(email)) {
            errors.email = 'Please enter valid email';
        }
        if (!password) {
            errors.password = 'Password is required';
        }

        if (!isEmptyObj(errors)) {
            setFormErrors(errors);
            return;
        }
        setFormErrors({});
        setLoading(true);

        const user = await loginUser(email, password);
        if (user.code === 200) {
            setUserData(user.data.user);
            setAuthToken(user.data.user.token);
            setOrgData(user.data.company);
            navigate(PATH_HOME, {replace: true})
        } else {
            setLoading(false);
            setError(user.error);
        }
    }

    return (
        <>
            { isLoading && <Loader />}
            { !!error &&  <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}  />}
            <div id="appCapsule" className="pt-0">
                <div className="section mt-2">
                    <h2>Sign In</h2>
                </div>
                <form onSubmit={handleLogin} noValidate>
                    <div className="section sign-in-form mb-5 p-2">
                        <div className="card card-border">
                            <div className="card-body">
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="email1">
                                            E-mail
                                        </label>
                                        <input
                                            id="email1"
                                            type="email"
                                            className="form-control"
                                            value={email}
                                            required
                                            onChange={(e) => setEmail(e.target.value)}/>
                                        <span className="text-danger">{formErrors.email}</span>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <PasswordField
                                        label={"Password"}
                                        password={password}
                                        setPassword={setPassword}
                                        error={formErrors.password}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section mt-2">
                            <Link className="section-title" to={PATH_FORGOT_PASSWORD}>
                                Forgot Password?
                            </Link>
                        </div>
                        <button
                            className="btn btn-primary btn-shadow btn-lg btn-block mt-2"
                        >
                            Sign In
                        </button>
                        <div className="section text-center mt-2">
                            Don't Have an account?{' '}
                            <Link className="section-title" to={PATH_SIGNUP}>
                                Sign Up
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Login;
