import {useRef} from 'react';
import $ from "jquery";

const TextSearch = ({searchText, onTextChange, onClose}) => {
    const inputRef = useRef();

    const handleHide = () => {
        $('#search').removeClass("show")
        onClose();
    }

    return (
        <div id="search" className={`appHeader`}>
            <form className="search-form">
                <div className="form-group searchbox">
                    <input ref={inputRef} type="text" className="form-control" placeholder="Search..."
                           value={searchText} onChange={e => onTextChange(e.target.value)}/>
                    <i className="input-icon icon ion-ios-search"/>
                    <a onClick={handleHide} className="ms-1 close toggle-searchbox d-lg-none"><i
                        className="icon ion-ios-close-circle"/></a>
                </div>
            </form>
        </div>
    );
}

export default TextSearch;
