import {useEffect, useState} from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import {deleteClient, getAllClients} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import {Link} from "react-router-dom";
import {MENUS, PATH_CLIENTS_CLIENT, PLACEHOLDER_AVATAR} from "../../constants";
import withUserAuth from "../../Behavior/withUserAuth";
import Spinner from "../../Components/Spinner";
import TextSearch from "../../Components/TextSearch";
import {useDebouncedEffect} from "../../Hooks/useDebouncedEffect";
import SideMenu from "../../Components/SideMenu";
import {useNavigate} from "react-router";

const PAGE_SIZE = 50;

const Clients = ({onClientClick, onSidePanelOpen}) => {
     

    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState(1);
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [showSearch, setShowSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [hasMore, setHasMore] = useState(true);

    const sendAPIRequest = async (page = pageNo, searchstring = searchText) => {
        const params = {page, pagesize: PAGE_SIZE, searchstring};
        const {code, data, error} = await getAllClients(params);
        if (code === 200) {
            setHasMore(data.isNextPageExist);
            if (page === 1) {
                setClients(data.accountList);
            } else {
                setClients([...clients, ...data.accountList])
            }
            setPageNo((page + 1));
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest(1, searchText);
    }, []);

    useEffect(() => {
        const root = document.getElementById('sidebar-content');
        if (!loading && hasMore && root.scrollHeight <= root.clientHeight) {
            sendAPIRequest();
        }
    }, [loading, hasMore]);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest(1);
    }

    const handleDelete = async ({accountid, name}) => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
        if (isConfirm) {
            setLoading(true);
            const {code, error} = await deleteClient({accountid})
            if (code === 200) {
                await sendAPIRequest(1);
            } else {
                setLoading(false);
                setError({message: error, retry: false});
            }
        }
    }

    const onSearchClick = () => {
        setShowSearch(true);
    }

    const onSearchClose = () => {
        setShowSearch(false);
        setSearchText('');
        setLoading(true);
        sendAPIRequest(1, '');
    }

    const handleClientClick = (client) => () => {
        if (onClientClick) {
            onClientClick(client);
        } else {
            navigate(PATH_CLIENTS_CLIENT, {state: {client}});
        }
    }

    useDebouncedEffect(async () => {
        if (showSearch) {
            setLoading(true);
            await sendAPIRequest(1);
        }
    }, [searchText], 500)

    const onRetry = !!error.retry ? handleRetry : null;

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Clients"} onSidePanelClick={onSidePanelOpen} onSearchClick={onSearchClick}/>
            <TextSearch isVisible={showSearch} onClose={onSearchClose} onTextChange={setSearchText}
                        searchText={searchText}/>
            <SideMenu selected={MENUS.CLIENTS}/>
            <div id='appCapsule' className={"mt-76"}>
                {/* Items */}
                {/* details */}

                <InfiniteScroll
                    className="listview separate-list clients-list image-listview inset no-line no-arrow mt-2"
                    dataLength={clients.length}
                    next={sendAPIRequest}
                    hasMore={hasMore}
                    loader={<Spinner/>}
                    scrollableTarget={"sidebar-content"}
                >
                    {
                        clients.map(client => {
                            return (
                                <li key={client.accountid} className="items-card card card-border">
                                    <a onClick={handleClientClick(client)} className="item">
                                        <img src={client.imageurl || PLACEHOLDER_AVATAR} alt="image"
                                             className="image"/>
                                        <div className="in">
                                            <div>
                                                <h4>{client.name}</h4>
                                                <p>{client.email}</p>
                                            </div>
                                            <h4 className="mbl-no">{client.phone}</h4>
                                        </div>
                                    </a>
                                    <span onClick={() => handleDelete(client)} className="delete-item py-3 ps-1 pe-3">
                                    <ion-icon name="close"/>
                                </span>
                                </li>
                            )
                        })
                    }
                    {
                        clients.length === 0 &&
                        <span className={"fontsize-heading"} style={{textAlign: 'center'}}>No Client Found</span>
                    }
                </InfiniteScroll>
                <Link to={PATH_CLIENTS_CLIENT} style={{marginLeft: '16px'}}
                      className="btn add-tag-anchor btn-primary mt-1">
                      <span>
                        <ion-icon name="add-circle"/>
                      </span>
                    Add Client
                </Link>
            </div>
        </>
    )
}

export default withUserAuth(Clients);
