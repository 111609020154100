import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {Link} from "react-router-dom";
import {usePosStore} from "../../Store";
import {useLocation} from "react-router";
import {MENUS, PATH_HOME, PATH_POS_CHECKOUT, PATH_POS_EMAIL_RECEIPT, PATH_POS_SMS_RECEIPT} from "../../constants";
import PrintView from "../PrintView";
import SideMenu from "../../Components/SideMenu";

const POSCash = () => {
    const posStore = usePosStore();
    const location = useLocation();
    const receipt = location.state?.receipt || {};
    const sale = location.state?.sale || {};
    console.log('receipt', receipt, sale);

    const handlePrint = () => {
        window.print();
    }

    return <>
        <Header title={"Sale Receipt"} hasBack backURL={posStore.checks.length > 0 ? PATH_POS_CHECKOUT : PATH_HOME}/>
        <SideMenu selected={MENUS.POS}/>
        <PrintView sale={sale}/>
        <div id="appCapsule">
            {/* Send Via */}
            <div className="send-via mt-2">
                <div className="send-email">
                    <Link to={PATH_POS_EMAIL_RECEIPT} state={{receipt}}>
                        <div className="in card-border">
                            <ion-icon class="icon" name="mail-outline"/>
                        </div>
                    </Link>
                    <h5>Email</h5>
                </div>
                <div className="send-sms">
                    <Link to={PATH_POS_SMS_RECEIPT} state={{receipt}}>
                        <div className="in card-border">
                            <ion-icon class="icon" name="chatbox-ellipses-outline"/>
                        </div>
                    </Link>
                    <h5>SMS</h5>
                </div>
                <div className="print">
                    <a onClick={handlePrint}>
                        <div className="in card-border">
                            <ion-icon class="icon" name="print-outline"/>
                        </div>
                    </a>
                    <h5>Print</h5>
                </div>
            </div>
            {/* * Send Via */}
            {/* Notification Heading */}
            <div className="section confirmed-heading mt-4">
                <div className="section-heading">
                    <img src="/img/paid.png" alt=""/>
                    <div>
                        <h2 className="title">This order is paid</h2>
                        <h3>Order id <span>#{receipt.invoiceNumber}</span></h3>
                    </div>
                </div>
            </div>
            {/* Notification Heading */}
        </div>
    </>
}

export default withUserAuth(POSCash);
