import Header from "../../Components/Header";
import {useNavigate} from "react-router";
import _ from 'lodash';
import {useEffect, useRef, useState} from "react";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {MENUS, PLACEHOLDER_CAMERA} from "../../constants";
import {getAllItems, getOrderAppDetails, updateOrderAppDetails, uploadFile} from "../../API";
import withUserAuth from "../../Behavior/withUserAuth";
import QRCode from "react-qr-code";
import ItemsDialog from "../../Components/ItemsDialog";
import SideMenu from "../../Components/SideMenu";

const OrderApp = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [allItems, setAllItems] = useState([]);
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [originalImageUrl, setOriginalImageUrl] = useState('');
    const [imageURL, setImageUrl] = useState(PLACEHOLDER_CAMERA);
    const [imageFile, setImageFile] = useState(null);
    const [showItemsDialog, setShowItemsDialog] = useState(false);
    const [specialOfferItems, setSpecialOfferItems] = useState([]);
    const imageInputRef = useRef();
    const [welcomeMessage, setWelcomeMessage] = useState('')
    const [description, setDescription] = useState('')
    const [orderAppEnabled, setOrderAppEnabled] = useState('')

    const handleRemoveImage = (e) => {
        e.stopPropagation();
        setImageUrl(() => originalImageUrl || PLACEHOLDER_CAMERA);
        setImageFile(null);
    }

    const sendAPIRequest = async () => {
        const res = await getAllItems();
        if (res.code === 200) {
            const items = _.flatMap(res.data.allitems, 'items');
            setAllItems(items);
        }
        const {code, data, error} = await getOrderAppDetails();
        if (code === 200) {
            const {orderApp} = data;
            setOriginalImageUrl(orderApp.imageURL);
            setImageUrl(orderApp?.imageURL || PLACEHOLDER_CAMERA);
            setWelcomeMessage(orderApp.welcomeMessage);
            setDescription(orderApp.description);
            setOrderAppEnabled(orderApp.orderAppEnabled);
            setSpecialOfferItems(orderApp.specialOfferItems);
            setQrCodeUrl(orderApp.orderAppURL);
        } else {
            setError(error);
        }
        setLoading(false);

    }

    useEffect(() => {
        sendAPIRequest();
    }, [])

    const handleSave = async () => {
        const req = {welcomeMessage, imageURL, description, orderAppEnabled, specialOfferItems};
        setLoading(true);
        if (imageURL !== PLACEHOLDER_CAMERA && imageURL !== originalImageUrl) { //need to upload file
            const res = await uploadFile(imageFile);
            if (res.code === 200) {
                req.imageURL = res.data.image.url;
            } else {
                setLoading(false);
                setError(res.error)
                return;
            }
        }
        const res = await updateOrderAppDetails(req);
        setLoading(false);
        if (res.code === 200) {
            navigate(-1);
        } else {
            setError(res.error);
        }
    }

    const handleShowItems = () => {
        setShowItemsDialog(true);
    }

    const handleSectionChange = (isChecked, itemId) => {
        const items = _.clone(specialOfferItems);
        if (isChecked) {
            items.push(itemId);
        } else {
            const index = _.indexOf(items, itemId);
            if (index >= 0) {
                items.splice(index, 1);
            }
        }
        setSpecialOfferItems(items);
    }
    let specialItems = 'Add Items';
    if (specialOfferItems.length > 0) {
        specialItems = specialOfferItems
            .map(id => allItems.find(item => item.itemid == id))
            .filter(item => !!item)
            .reduce((pre, curr, index) => `${pre}${index != 0 ? ',' : ''} ${curr.name}`, '');
    }

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            {<ItemsDialog isOpen={showItemsDialog} items={allItems} selectedItems={specialOfferItems}
                          onSelectionChange={handleSectionChange} onClose={() => setShowItemsDialog(false)}/>}
            <Header hasBack title={"Order App"}/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                <div className="section mt-2">
                    <div className="row m-0">
                        <div className="col-6 ps-0">
                            <div
                                onClick={() => imageInputRef.current.click()}
                                className="card img-card card-border" data-bs-toggle="modal"
                                data-bs-target="#getPicturesDialog">
                                <img src={imageURL} className="card-img-top" alt="image"/>
                                <div style={{display: 'flex'}}>
                                    <h4 style={{flex: 1}}>Select Photo</h4>
                                    {imageFile && <h4 onClick={handleRemoveImage} style={{flex: 1}}>Remove</h4>}
                                </div>
                                <input
                                    style={{display: 'none'}}
                                    ref={imageInputRef}
                                    type="file"
                                    className="form-control"
                                    id="photo"
                                    accept="image/*"
                                    onChange={(e) => {
                                        if (e.target?.files?.length > 0) {
                                            setImageUrl(URL.createObjectURL(e.target.files[0]));
                                            setImageFile(e.target.files[0]);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* * Items */}
                    {/* details */}
                    <div className="order-app-wrapper mt-2">
                        <div className="card card-border">
                            <div className="card-body">
                                <form>
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="Welcome">Welcome Message</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="Welcome"
                                                value={welcomeMessage}
                                                onChange={e => setWelcomeMessage(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group basic">
                                        <div className="input-wrapper border-bottom pb-1">
                                            <h6>Special Offers (Items)</h6>
                                            <a onClick={handleShowItems}>{specialItems}</a>
                                        </div>
                                    </div>
                                    <div className="form-group basic flex-row" style={{display: 'flex'}}>
                                        <div style={{flex: 1}}>Enable Order App</div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="SwitchMore"
                                                   checked={orderAppEnabled}
                                                   onChange={(e) => setOrderAppEnabled(e.target.checked)}
                                            />
                                            <label className="form-check-label" htmlFor="SwitchMore"
                                                   id="itemsSwitchToggler"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="sell-checkout-qr mt-4">
                        <div className="section">
                            <div className="card card-border">
                                <div className="card-body">
                                    <QRCode value={qrCodeUrl}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block mt-2">Save</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(OrderApp);
