import ReactApexChart from 'react-apexcharts';
import {formatNumber} from "../Utils";

const LineChart = ({data}) => {
    const timelineChartOptions = {
        chart: {
            toolbar: {
                show: false,
            },
            foreColor: "#747584",
            height: 200,
            zoom: {
                autoScaleYaxis: true,
            },
        },
        annotations: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            colors: ["#1DCC70", "#1DCC70", "#1DCC70"],
            curve: "smooth",
        },
        grid: {
            borderColor: "#ddd",
            strokeDashArray: 10,
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                colorStops: [
                    {
                        offset: 0,
                        color: "#1DCC70",
                        opacity: 0.5,
                    },
                    {
                        offset: 100,
                        color: "#1DCC70",
                        opacity: .1,
                    },
                ],
            },
        },
        xaxis: {
            categories: data?.map((d) => d.dateStr),
            tickAmount: 6,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                color: "#ddd",
            },
        },
        yaxis: {
            axisTicks: {
                show: true,
                color: "#ddd",
            },
            labels: {
                formatter: (value) => formatNumber(value),
            }
        },
        tooltip: {
            marker: {
                show: false,
            },
        },
        markers: {
            size: 0,
            style: "hollow",
            colors: ["#1DCC70", "#1DCC70", "#1DCC70"],
        },

    }

    const series = [
        {
            name: 'Transactions',
            data: data?.map((d) => d.count) || [],
        },
    ];

    return (
        <ReactApexChart options={timelineChartOptions} series={series} type={"area"}/>
    )
}

export default LineChart;
