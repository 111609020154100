import Modal from 'react-modal';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0, 0.5)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'none',
        inset: 'none',
        borderRadius: 'none',
        border: 0,
        background: 'none',
    },
};

const ErrorDialog = ({ isOpen, content, onClose, onRetry }) => {
    return (
        <Modal
            style={customStyles}
            isOpen={isOpen}>
            <div className="dialogbox show"  >
                <div className="modal-dialog" >
                    <div className="modal-content card-border">
                        <div className="modal-icon" style={{ fontSize: '18px', marginBottom: 0 }}>
                            Error
                        </div>
                        <div className="modal-body mt-2">
                            { content }
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                {onClose && <a className="btn" onClick={onClose}>Close</a>}
                                {onRetry && <a className="btn" onClick={onRetry}>Retry</a>}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ErrorDialog;
