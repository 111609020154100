import {useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Header from "../../Components/Header";
import SaleDetail from "../../Components/SaleDetail";
import withUserAuth from "../../Behavior/withUserAuth";
import EmailDialog from "../../Components/EmailDialog";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {sendInvoice} from "../../API";
import {showSuccessToast} from "../../Utils";
import PrintView from "../PrintView";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const Receipt = () => {
    const location = useLocation();
    const navigate = useNavigate();
    console.log('state', location.state);
    const sale = location.state?.sale;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showEmailDialog, setShowEmailDialog] = useState(false);

    const handleSendClick = async (email) => {
        setLoading(true);

        const res = await sendInvoice({
            sentto: email,
            invoiceid: sale.invoiceId,
            notificationtype: 1,
            isAddCustomer: false
        })
        if (res.code === 200) {
            showSuccessToast(res.message);
            setShowEmailDialog(false);
        } else {
            setError(res.error);
        }
        setLoading(false)
    }

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            {showEmailDialog && <EmailDialog onClose={() => setShowEmailDialog(false)} onSend={handleSendClick}/>}
            <PrintView sale={sale}/>
            <Header title={"Sale Detail"} hasBack/>
            <SideMenu selected={MENUS.RECEIPTS}/>
            <SaleDetail
                sale={sale}
                showRefund
                showPrint
                onEmailClick={() => setShowEmailDialog(true)}
            />
        </>


    )
}

export default withUserAuth(Receipt);
