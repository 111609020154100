import {
    ORDER_TYPE_DELIVERY,
    ORDER_TYPE_DINE_IN,
    ORDER_TYPE_TAKEAWAY,
    PATH_RECEIPTS_REFUND,
    PAYMENT_TYPES,
    PLACEHOLDER_FOOD,
    SALE_TYPE_ID_RETURN
} from "../constants";
import {Link} from "react-router-dom";
import {isAdminLoggedIn} from "../DB";
import _ from "lodash";
import {formatNumber} from "../Utils";

const SaleDetail = ({sale, showRefund, onEmailClick, showPrint, handleProceed, handleDelete, hideAction, isOnlineOrder}) => {
    let paymentType = sale?.salePayments?.[0]?.paymentTypeId || 0;

    let totalTax = _.flatMap(sale.saleDetails, 'taxes')
        .filter(item => !_.isEmpty(item))
        .reduce((total, curr) => parseFloat(total) + curr.taxAmount, parseFloat(0.00));
    if(isOnlineOrder)
    {
        totalTax = sale.taxAmount;
    }
    const handlePrintClick = () => {
        window.print();
    }
    return (
        <div id="appCapsule">
            {/* checkout item */}
            <div className="section">
                <div className="row receipt-details-item">
                    {
                        sale.saleDetails.map((s, i) => (
                            <div key={i} className="col-4 mt-2">
                                <a>
                                    <div className="card card-border p-0">
                                        <img src={s.imageUrl || PLACEHOLDER_FOOD} className="card-img-top" alt="image"/>
                                        <h4>{s.itemName}</h4>
                                    </div>
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* * Checkout Item */}
            {/* Summary */}
            <div className="section mt-3">
                <div className="grand-total-count">
                    <div className="card card-border">
                        <div className="card-body">
                            <div className="single-data">
                                <h4>Payment Status</h4>
                                {
                                    sale.salePayments?.length > 0 ? <p className="text-success">Paid</p> :
                                        <p className="text-danger">Not Paid</p>
                                }
                            </div>
                            <div className="single-data">
                                <h5>Payment</h5>
                                <p>{PAYMENT_TYPES[paymentType]}</p>
                            </div>
                            <div className="single-data">
                                <h5>Section</h5>
                                <p>{sale.tableName || ''}</p>
                            </div>
                            <div className="single-data">
                                <h5>Staff</h5>
                                <p>{`${sale.createdby} ${sale.createdbysurname}`}</p>
                            </div>
                            <div className="single-data">
                                <h5>Client</h5>
                                <p>{sale.accountName}</p>
                            </div>
                            <div className="single-data">
                                <h3>Net Total</h3>
                                <h3>{sale.netTotal}</h3>
                            </div>
                            <div className="single-data">
                                <h5>Discount</h5>
                                <p>{sale.discount}%</p>
                            </div>
                            <div className="single-data">
                                <h5>Tip</h5>
                                <p>{sale.tip}%</p>
                            </div>
                            <div className="single-data">
                                <h5>Total Tax</h5>
                                <p>{formatNumber(totalTax)}</p>
                            </div>
                            <div className="single-data">
                                <h3>Grand Total</h3>
                                <h3>{sale.grandTotal}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* * Summary */}
            {/* Extra */}
            <div className="section rcv-variation-button mt-2">
                <div className="btn-group" role="group">
                    <input type="radio" className="btn-check " name="btnradioGroup1" id="DineIn"
                           readOnly
                           checked={sale.onlineOrderType === ORDER_TYPE_DINE_IN}
                    />
                    <label className="btn btn-outline-primary" htmlFor="DineIn">Dine In</label>
                    <input type="radio" className="btn-check " name="btnradioGroup1" id="takeAway"
                           readOnly
                           checked={sale.onlineOrderType === ORDER_TYPE_TAKEAWAY}
                    />
                    <label className="btn btn-outline-primary" htmlFor="takeAway">Take Away</label>
                    <input type="radio" className="btn-check " name="btnradioGroup1" id="delivery"
                           readOnly
                           checked={sale.onlineOrderType === ORDER_TYPE_DELIVERY}
                    />
                    <label className="btn btn-outline-primary" htmlFor="delivery">Delivery</label>
                </div>
            </div>
            {/* * Extra */}
            {/* Buttons */}
            {!hideAction && 
                <div className="section mt-2">
                    <div className="row">
                        {showRefund && isAdminLoggedIn() && sale.invoiceTypeId != SALE_TYPE_ID_RETURN &&
                            <div className="col">
                                <Link to={PATH_RECEIPTS_REFUND} replace={true} state={{sale}}
                                    className="btn btn-primary btn-shadow btn-lg btn-block">Refund</Link>
                            </div>
                        }
                        {!!onEmailClick &&
                            <div className="col">
                                <a onClick={onEmailClick}
                                className="btn btn-primary btn-shadow btn-lg btn-block">Send</a>
                            </div>
                        }
                        {showPrint &&
                            <div className="col">
                                <a onClick={handlePrintClick}
                                className="btn btn-primary btn-shadow btn-lg btn-block">Print</a>
                            </div>
                        }
                        {
                            !!handleProceed &&
                            <div className="col">
                                <a onClick={handleProceed}
                                className="btn btn-primary btn-shadow btn-lg btn-block">Proceed</a>
                            </div>
                        }
                        {
                            !!handleDelete &&
                            <div className="col">
                                <a onClick={handleDelete} className="btn btn-primary btn-shadow btn-lg btn-block">Delete</a>
                            </div>
                        }
                    </div>
                </div>
    }
            {/* * Buttons */}
        </div>
    )
}

export default SaleDetail;
