import {Link} from 'react-router-dom';
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {
    MENUS,
    PATH_SETTINGS_IN_STORE_PAYMENTS,
    PATH_SETTINGS_ONLINE_PAYMENTS,
    PATH_SETTINGS_ORDER_APP
} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const AcceptPayments = () => {

    return (
        <>
            <Header title={"Accepting Payments"} hasBack/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                <ul className="listview card-border image-listview text inset mt-2">
                    <li>
                        <Link to={PATH_SETTINGS_IN_STORE_PAYMENTS} className="item">
                            <div className="in">
                                <div>In Store Payments</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_ONLINE_PAYMENTS} className="item">
                            <div className="in">
                                <div>Online Payments</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={PATH_SETTINGS_ORDER_APP} className="item">
                            <div className="in">
                                <div>Order App</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <div className="item d-flex justify-content-between">
                            <div>Enable Credit</div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="SwitchCheckDefault5"/>
                                <label className="form-check-label" htmlFor="SwitchCheckDefault5"/>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

        </>
    )
}

export default withUserAuth(AcceptPayments);
