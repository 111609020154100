import Header from "../../Components/Header";
import {useNavigate} from "react-router";
import {useEffect, useRef, useState} from "react";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {COUNTRIES, MENUS, PLACEHOLDER_CAMERA} from "../../constants";
import {
    connectInStore,
    disconnectInStore,
    getCompanyDetails,
    getConnectStatusInStore,
    updateOrgDetails,
    uploadFile
} from "../../API";
import withUserAuth from "../../Behavior/withUserAuth";
import {setOrgData} from "../../DB";
import SideMenu from "../../Components/SideMenu";
import $ from "jquery";

const OrganizationInfo = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [isConnected, setIsConnected] = useState(false);
    const [originalImageUrl, setOriginalImageUrl] = useState('');
    const [imageUrl, setImageUrl] = useState(PLACEHOLDER_CAMERA);
    const [imageFile, setImageFile] = useState(null);
    const imageInputRef = useRef();
    const [name, setName] = useState('')
    const [businessName, setBusinessName] = useState('');
    const [businessType, setBusinessType] = useState('')
    const [orgNumber, setOrgNumber] = useState('');
    const [taxId, setTaxId] = useState('');
    const [website, setWebsite] = useState('0');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [postal, setPostal] = useState('');
    const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState(null);

    const handleRemoveImage = (e) => {
        e.stopPropagation();
        setImageUrl(() => originalImageUrl || PLACEHOLDER_CAMERA);
        setImageFile(null);
    }

    const sendAPIRequest = async () => {
        const res = await getConnectStatusInStore();
        if (res.code === 200) {
            setIsConnected(res.data.isConnected);
        }
        const {code, data, error} = await getCompanyDetails();
        if (code === 200) {
            const {company} = data;
            setCompany(company);
            setOriginalImageUrl(company.logourl || '');
            setImageUrl(company.logourl || PLACEHOLDER_CAMERA);
            setName(company?.name || '');
            setBusinessName(company?.businessname || '');
            setBusinessType(company?.storetype || '');
            setOrgNumber(company?.orgnumber || '');
            setTaxId(company?.taxid || '');
            setWebsite(company?.siteurl || '');
            setAddress(company?.address || '');
            setCity(company?.city || '');
            setPostal(company?.postal || '');
            setCountry(company?.country || '');
            setPhone(company?.orgbusinesscontact || '');
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);

    }

    useEffect(() => {
        sendAPIRequest();
    }, [])

    const handleSave = async () => {
        const orgInfo = {
            OrgName: name,
            OrgBusinessName: businessName,
            OrgNumber: orgNumber,
            OrgBusinessContact: phone,
            OrgTaxId: taxId,
            OrgSiteURL: website,
            OrgAddress: address,
            OrgCity: city,
            OrgPostal: postal,
            OrgCountry: country,
            OrgLogoPath: imageUrl,
            OrgCurrencyId: company.currencyid,
            OrgStoreTypeId: company.storetypeid,
        }
        setLoading(true);
        if (imageUrl !== PLACEHOLDER_CAMERA && imageUrl !== originalImageUrl) { //need to upload file
            const res = await uploadFile(imageFile);
            if (res.code === 200) {
                orgInfo.OrgLogoPath = res.data.image.url;
            } else {
                setLoading(false);
                setError(res.error)
                return;
            }
        }
        const res = await updateOrgDetails(orgInfo);
        setLoading(false);
        if (res.code === 200) {
            setOrgData(res.data.company);
            navigate(-1);
        } else {
            setError(res.error);
        }
    }

    const handleConnect = async () => {
        setLoading(true);
        const ReturnURL = window.location.href;
        const {code, data, error} = await connectInStore({ReturnURL});
        if (code === 200) {
            window.location.href = data.redirectURL;
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    const handleDisconnect = async () => {
        setLoading(true);
        const {code, error} = await disconnectInStore();
        if (code === 200) {
            setIsConnected(false);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const onRetry = !!error.retry ? handleRetry : null;

    useEffect(() => {
        function handleResize() {
            const windscroll = $(window).scrollTop();
            if (windscroll >= 100) {
                $(".appHeader").removeClass("transparent")
            } else {
                $(".appHeader").addClass("transparent")
            }
        }

        window.addEventListener('scroll', handleResize)
        handleResize();
        return () => {
            window.removeEventListener('scroll', handleResize)
        }
    }, [])

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header hasBack title={"Organization Info"}/>
            <SideMenu selected={MENUS.SETTINGS}/>
            {/* App Capsule */}
            <div id="appCapsule" className={"p-0"}>
                <div onClick={() => imageInputRef.current.click()} className="cover-img-wrapper">
                    <img src={imageUrl} className="cover-img" alt="image"/>
                    <img src="/img/org-1.png" data-bs-toggle="modal"
                         data-bs-target="#getPicturesDialog" alt="" className="logo-img"/>
                    <input
                        style={{display: 'none'}}
                        ref={imageInputRef}
                        type="file"
                        className="form-control"
                        id="photo"
                        accept="image/*"
                        onChange={(e) => {
                            if (e.target?.files?.length > 0) {
                                setImageUrl(URL.createObjectURL(e.target.files[0]));
                                setImageFile(e.target.files[0]);
                            }
                        }}
                    />
                </div>
                {/* Items */}
                <div className="section organizational-info mt-2">
                    {/*<div className="row m-0">*/}
                    {/*    <div className="col-6 ps-0">*/}
                    {/*        <div*/}
                    {/*            onClick={() => imageInputRef.current.click()}*/}
                    {/*            className="card img-card card-border" data-bs-toggle="modal"*/}
                    {/*            data-bs-target="#getPicturesDialog">*/}
                    {/*            <img src={imageUrl} className="card-img-top" alt="image"/>*/}
                    {/*            <div style={{display: 'flex'}}>*/}
                    {/*                <h4 style={{flex: 1}}>Select Photo</h4>*/}
                    {/*                {imageFile && <h4 onClick={handleRemoveImage} style={{flex: 1}}>Remove</h4>}*/}
                    {/*            </div>*/}
                    {/*            <input*/}
                    {/*                style={{display: 'none'}}*/}
                    {/*                ref={imageInputRef}*/}
                    {/*                type="file"*/}
                    {/*                className="form-control"*/}
                    {/*                id="photo"*/}
                    {/*                accept="image/*"*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    if (e.target?.files?.length > 0) {*/}
                    {/*                        setImageUrl(URL.createObjectURL(e.target.files[0]));*/}
                    {/*                        setImageFile(e.target.files[0]);*/}
                    {/*                    }*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/* * Items */}
                    {/* Stripe Button */}

                    <div className="mt-2">
                        {
                            !isConnected ?
                                <a onClick={handleConnect} className="stripe-connect"><span>Connect with</span></a>
                                : <a onClick={handleDisconnect} className="stripe-connect"><span>Disconnect</span></a>

                        }
                        <p className="mt-2">
                            Connect your account to Stripe, to pre populate all details below.
                        </p>
                    </div>
                    {/* * Stripe Button */}
                    {/* details */}
                    <div className="section-title mt-3">Details</div>
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="text4">Organaization Name (What Authorities
                                            See)</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="text4"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="text3">Business Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="text3"
                                            value={businessName}
                                            onChange={e => setBusinessName(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="text4">Type of Business</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="text4"
                                            value={businessType}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="text4">Organaization Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="text4"
                                            value={orgNumber}
                                            onChange={e => setOrgNumber(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="text4">Tax ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="text4"
                                            value={taxId}
                                            onChange={e => setTaxId(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="text4">Website</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            id="text4"
                                            value={website}
                                            onChange={e => setWebsite(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="amount3">Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="amount3"
                                            value={address}
                                            onChange={e => setAddress(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="city">City</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="city"
                                            value={city}
                                            onChange={e => setCity(e.target.value)}
                                            maxLength={100}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="posCode">Post Code</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="posCode"
                                            value={postal}
                                            onChange={e => setPostal(e.target.value)}
                                            maxLength={10}
                                        />

                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="country">Country</label>
                                        <select className="form-control custom-select" id="country"
                                                value={country}
                                                onChange={(e) => setCountry(e.target.value)}
                                        >
                                            {COUNTRIES.map(country => <option key={country}
                                                                              value={country}>{country}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group flag-mbl-input basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="phone">Mobile</label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            autoComplete="off"
                                            value={phone}
                                            onChange={e => setPhone(e.target.value)}
                                            maxLength={15}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* * details */}
                    <div className="mt-2">
                        <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Save</a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default withUserAuth(OrganizationInfo);
