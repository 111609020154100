import {useEffect, useState} from 'react';
import {getDarkMode, getUser, setAuthToken, setOrgData, setUserData, toggleDarkMode} from "../DB";
import {Link, useNavigate} from "react-router-dom";
import {
    MENUS,
    PATH_CLIENTS,
    PATH_INVOICES,
    PATH_ITEMS,
    PATH_LOGIN,
    PATH_ORDERS,
    PATH_PRODUCTS,
    PATH_RECEIPTS,
    PATH_REPORT,
    PATH_SELL,
    PATH_SETTINGS,
    PATH_STAFFS,
    PLACEHOLDER_AVATAR
} from "../constants";
import {getAllStaffs, switchStaff} from "../API";
import $ from "jquery";
import ErrorDialog from "./ErrorDialog";
import Loader from "./Loader";

const SideMenu = ({selected}) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        setUserData({});
        setOrgData({});
        setAuthToken('');
        navigate(PATH_LOGIN, {replace: true});
    }
    const [darkMode, setDarkMode] = useState(getDarkMode());
    const [staffs, setStaffs] = useState([getUser()]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);


    // const [amountReceived, setAmountReceived] = useState('');
    // const [registerStatus, setRegisterStatus] = useState({});

    // const sendApiRequest = async () => {
    //     const res = await getStatusOfUserRegister();
    //     if (res.code === 200) {
    //         setRegisterDetail(res.data.registerdetails);
    //         setRegisterStatus(res.data.registerdetails);
    //     }
    //     const resp = await getBalance();
    //     if (resp.code === 200) {
    //         setAmountReceived(resp.data.cashManagementBalance)
    //     }
    // }

    const sendApiRequest = async () => {
        const {data, code} = await getAllStaffs({staffType: 0});
        if (code === 200) {
            setStaffs(data.staffs);
        }
    }

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 991) {
                $('#sidebarPanel').addClass('show');
                $('#sidebarPanel').show()
                $('#search').addClass("show")
            }

            if (window.innerWidth < 991) {
                $('#sidebarPanel').removeClass('show');
                $('#sidebarPanel').hide()
                $('#search').removeClass("show")
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        sendApiRequest();
    }, []);

    const handleDarkMode = () => {
        setDarkMode(() => {
            toggleDarkMode(!darkMode);
            return !darkMode;
        })

        if (darkMode) {
            document.body.classList.remove('dark-mode');
        } else {
            document.body.classList.add('dark-mode');
        }
    }

    const handleCloseMenu = () => {
        $('#sidebarPanel').removeClass('show');
        $('#sidebarPanel').hide()

    }

    const user = getUser();

    const handleUserChange = async (e) => {
        setLoading(true)
        const {code, data, error} = await switchStaff(e.target.value)
        if (code === 200) {
            setUserData(data.user);
            setAuthToken(data.user.token);
            window.location.reload();
        } else {
            setLoading(false);
            setError(error)
        }
    }

    const handleRetry = async () => {
        setError('');
        await handleUserChange();
    }

    return (
        <>
            {loading && <Loader/>}
            {!!error &&
                <ErrorDialog isOpen={true} content={error} onClose={() => setError('')} onRetry={handleRetry}/>}
            <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex={-1} role="dialog"
                 onClick={handleCloseMenu}>
                <div onClick={e => e.stopPropagation()} className="modal-dialog" role="document">
                    <div className="modal-content" style={{height: '100%', border: 0, borderRadius: 0}}>
                        <div className="modal-body p-0">
                            <div className="profileBox pt-2 pb-2">
                                <div className="image-wrapper">
                                    <img src={user.imageurl || PLACEHOLDER_AVATAR} alt="image" className="imaged  w36"/>
                                </div>
                                <div className="in">
                                    {/*<strong>{`${user.name} ${user.surname}`}</strong>*/}
                                    <select className="form-control custom-select" id="Role"
                                            value={user.userid}
                                            onChange={handleUserChange}
                                    >
                                        {
                                            staffs.map(staff => (
                                                <option key={staff.userid}
                                                        value={staff.userid}>{`${staff.name} ${staff.surname}`}</option>
                                            ))
                                        }
                                    </select>


                                    {/*{*/}
                                    {/*    registerStatus.isregisterenabled && <> {*/}
                                    {/*        registerStatus.isregisteropen ? <div className="text-success">Register Open</div> :*/}
                                    {/*            <div className="text-danger">Register Closed</div>*/}
                                    {/*    }*/}
                                    {/*    </>*/}
                                    {/*}*/}
                                </div>
                                <a className="btn btn-link btn-icon sidebar-close" onClick={handleCloseMenu}
                                   data-bs-dismiss="modal">
                                    <ion-icon name="close"></ion-icon>
                                </a>
                            </div>

                            <ul className="listview flush transparent no-line image-listview">
                                <li className={selected === MENUS.POS ? "active-menu" : ""}>
                                    <Link to={PATH_SELL} className="item">
                                        <div className="icon-box bg-primary card-border">
                                            <ion-icon name="cash"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Sell
                                        </div>
                                    </Link>
                                </li>
                                
                                <li className={selected === MENUS.ORDERS ? "active-menu" : ""}>
                                    <Link to={PATH_ORDERS} className="item">
                                        <div className="icon-box bg-primary card-border">
                                            <ion-icon name="cart"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Orders
                                        </div>
                                    </Link>
                                </li>
                               
                                <li className={selected === MENUS.ITEMS ? "active-menu" : ""}>
                                    <Link to={PATH_ITEMS} className="item">
                                        <div className="icon-box bg-primary card-border">
                                            <ion-icon name="pricetags-sharp"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Items
                                        </div>
                                    </Link>
                                </li>
                                <li className={selected === MENUS.CLIENTS ? "active-menu" : ""}>
                                    <Link to={PATH_CLIENTS} className="item">
                                        <div className="icon-box bg-primary card-border">
                                            <ion-icon name="heart"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Clients
                                        </div>
                                    </Link>
                                </li>
                                <li className={selected === MENUS.RECEIPTS ? "active-menu" : ""}>
                                    <Link to={PATH_RECEIPTS} className="item">
                                        <div className="icon-box bg-primary card-border">
                                            <ion-icon name="receipt"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Receipts
                                        </div>
                                    </Link>
                                </li>
                                <li className={selected === MENUS.STAFF ? "active-menu" : ""}>
                                    <Link to={PATH_STAFFS} className="item">
                                        <div className="icon-box bg-primary card-border">
                                            <ion-icon name="people"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Staffs
                                        </div>
                                    </Link>
                                </li>
                                <li className={selected === MENUS.REPORTS ? "active-menu" : ""}>
                                    <Link to={PATH_REPORT} className="item">
                                        <div className="icon-box bg-primary card-border">
                                            <ion-icon name="bar-chart-sharp"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Reports
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://rapedido.es/user-manual/" className="item">
                                        <div className="icon-box bg-primary card-border">
                                            <ion-icon name="help-sharp"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Help
                                        </div>
                                    </a>
                                </li>
                                <li className={selected === MENUS.SETTINGS ? "active-menu" : ""}>
                                    <Link to={PATH_SETTINGS} className="item">
                                        <div className="icon-box bg-primary card-border">
                                            <ion-icon name="settings-sharp"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Settings
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            {/* * menu */}
                            {/* Dark mode Switch */}
                            {/*<ul className="listview darkmode-switcher image-listview text inset no-line mt-2">*/}
                            {/*    <li>*/}
                            {/*        <div className="in d-flex">*/}
                            {/*            <div className="form-check form-switch ">*/}
                            {/*                <input className="form-check-input" type="checkbox" id="SwitchCheckDefault1"*/}
                            {/*                       checked={darkMode} onChange={handleDarkMode}/>*/}
                            {/*                <label className="form-check-label" htmlFor="SwitchCheckDefault1"/>*/}
                            {/*            </div>*/}
                            {/*            <div className={"align-self-center"} style={{marginLeft: '6px'}}>*/}
                            {/*                Dark Mode*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        /!*<div className="log-out-btn">*!/*/}
                            {/*        /!*    <a onClick={handleLogout} className="btn bg-primary btn-shadow btn-lg">Log Out</a>*!/*/}
                            {/*        /!*</div>*!/*/}
                            {/*    </li>*/}
                            {/*</ul>*/}

                            <ul className="listview darkmode-switcher image-listview text inset no-line mt-2">
                                <li>
                                    <div className={"item"}>
                                        <div className="in">
                                            <div className="form-check form-switch me-2">
                                                <input className="form-check-input dark-mode-switch" type="checkbox"
                                                       id="SwitchCheckDefault1"
                                                       checked={darkMode} onChange={handleDarkMode}/>
                                                <label className="form-check-label" htmlFor="SwitchCheckDefault1"/>
                                            </div>
                                            <div>
                                                Dark Mode
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="log-out-btn">*/}
                                    {/*    <a onClick={handleLogout} className="btn bg-primary btn-shadow btn-lg">Log Out</a>*/}
                                    {/*</div>*/}
                                </li>
                            </ul>
                            {/* Log Out */}
                            <div className="section log-out-btn mt-3">
                                <a onClick={handleLogout} className="btn bg-primary btn-shadow btn-lg">Log Out</a>
                            </div>
                            {/* * Log Out */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideMenu;
