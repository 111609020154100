import {useEffect, useState} from 'react';
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {CASH_TYPE_PUT, CASH_TYPE_TAKE, MENUS, TYPE_AMOUNT} from "../../constants";
import {formatNumber} from "../../Utils";
import {useNavigate} from "react-router-dom";
import {usePosStore} from "../../Store";
import SideMenu from "../../Components/SideMenu";

const POSDiscount = () => {
    const posStore = usePosStore();
    const sale = posStore.getSelectedCheck();
    const navigate = useNavigate();
    const [discount, setDiscount] = useState(() => {
        return sale.discountAmount || 0;
    });
    const [newBalance, setNewBalance] = useState(() => {
        return sale.amount - discount;
    });
    const [type, setType] = useState(TYPE_AMOUNT);

    useEffect(() => {
        calculateBalance();
    }, [discount, type]);

    const calculateBalance = () => {
        const netPrice = parseFloat(sale.amount);
        const discountt = parseFloat(discount);
        let balance;
        if (type === TYPE_AMOUNT) {
            balance = netPrice - discountt;
        } else {
            balance = netPrice - ((netPrice * discountt) / 100);
        }
        setNewBalance(formatNumber(balance));
    }

    const handleSave = async () => {
        const netPrice = parseFloat(sale.amount);
        const discountt = parseFloat(discount);
        let amount, percentage;
        if (type === TYPE_AMOUNT) {
            amount = discountt;
            percentage = (100 * amount) / netPrice
        } else {
            percentage = discountt;
            amount = (netPrice * percentage) / 100;
        }
        posStore.setDiscount(formatNumber(percentage), formatNumber(amount));
        navigate(-1);
    }

    return <>
        <Header title={"Add Discount"} hasBack/>
        <SideMenu selected={MENUS.POS}/>
        <div id="appCapsule">
            {/* Cash payment Amount */}
            <div className="row section cash-payment-amount mt-2">
                <div className="col-4">
                    <div className="single-cash-payment">
                        <p>Sale Amount</p>
                        <input
                            type="number"
                            className="form-control"
                            id="taxRate"
                            value={formatNumber(sale.amount)}
                            disabled
                            style={{
                                background: 'transparent',
                                border: 0,
                                fontSize: '24px',
                                fontWeight: 700,
                                padding: '2px',
                                textAlign: 'center'
                            }}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="single-cash-payment">
                        <p>Discount</p>
                        <input
                            type="number"
                            className="form-control"
                            id="taxRate"
                            value={discount}
                            onChange={e => setDiscount(e.target.value)}
                            style={{
                                background: 'transparent',
                                border: 0,
                                fontSize: '24px',
                                fontWeight: 700,
                                padding: '2px',
                                textAlign: 'center'
                            }}
                        />

                    </div>
                </div>
                <div className="col-4">
                    <div className="single-cash-payment">
                        <p>New Amount</p>
                        <input
                            type="number"
                            className="form-control"
                            id="taxRate"
                            value={newBalance}
                            disabled
                            style={{
                                background: 'transparent',
                                border: 0,
                                fontSize: '24px',
                                fontWeight: 700,
                                padding: '2px',
                                textAlign: 'center'
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* * Cash payment Amount */}
            {/* Amount Button */}
            {/*<div className="row section amount-btn mt-3">*/}
            {/*    <div className="col">*/}
            {/*        <button onClick={addAmount(10)} className="btn btn-light btn-shadow btn-block">10</button>*/}
            {/*    </div>*/}
            {/*    <div className="col">*/}
            {/*        <button onClick={addAmount(20)} className="btn btn-light btn-shadow btn-block">20</button>*/}
            {/*    </div>*/}
            {/*    <div className="col">*/}
            {/*        <button onClick={addAmount(50)} className="btn btn-light btn-shadow btn-block">50</button>*/}
            {/*    </div>*/}
            {/*    <div className="col">*/}
            {/*        <button onClick={addAmount(100)} className="btn btn-light btn-shadow btn-block">100</button>*/}
            {/*    </div>*/}
            {/*    <div className="col">*/}
            {/*        <button onClick={addAmount(500)} className="btn btn-light btn-shadow btn-block">500</button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/* * Amount Button */}
            {/* Options */}
            <div className="section tip-option mt-3">
                <div className="btn-group" role="group">
                    <input type="radio"
                           className="btn-check"
                           name="btnradioGroup1"
                           id="btnradio1"
                           value={CASH_TYPE_PUT}
                           checked={type === CASH_TYPE_PUT}
                           onChange={e => setType(parseInt(e.target.value))}
                    />
                    <label className="btn btn-outline-primary" htmlFor="btnradio1">Amount</label>
                    <input
                        type="radio"
                        className="btn-check "
                        name="btnradioGroup1"
                        id="btnradio2"
                        value={CASH_TYPE_TAKE}
                        checked={type === CASH_TYPE_TAKE}
                        onChange={e => setType(parseInt(e.target.value))}
                    />
                    <label className="btn btn-outline-primary" htmlFor="btnradio2">Percentage</label>
                </div>
            </div>
            {/* Buttons */}
            <div className="section mt-3">
                <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Done</a>
            </div>
            {/* * Buttons */}
        </div>

    </>
}

export default withUserAuth(POSDiscount);
