import {useState} from "react";
import {useLocation, useNavigate} from "react-router";
import Header from "../../Components/Header";
import withUserAuth from "../../Behavior/withUserAuth";
import {addSection, updateSection} from "../../API";
import ErrorDialog from "../../Components/ErrorDialog";
import Loader from "../../Components/Loader";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const Section = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const section = location.state;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [name, setName] = useState(() => {
        if (section) {
            return section.name;
        }
        return '';
    })

    const handleSave = async () => {
        let res;
        setLoading(true);
        if (section) {
            res = await updateSection({...section, name});
        } else {
            res = await addSection({name});
        }
        setLoading(false);
        if (res.code === 200) {
            navigate(-1);
        } else {
            setError(res.error);
        }
    }
    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header title={"Section"} hasBack/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                <div className="section item-amend-category mt-2">
                    {/* Set Name */}
                    <div className="card card-border">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="sectionName">Section Name</label>
                                        <input type="text" value={name} onChange={(e) => setName(e.target.value)}
                                               className="form-control" id="sectionName"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"/>
                                        </i>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* * Set Name */}
                    <div className="mt-4">
                        <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Save</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(Section);
