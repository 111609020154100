import {useState} from "react";
import Header from "../../Components/Header";
import {useLocation, useNavigate} from "react-router-dom";
import {MENUS, PAYMENT_TYPES, PLACEHOLDER_FOOD, SALE_TYPE_ID_RETURN} from "../../constants";
import {refundSale} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {showSuccessToast} from "../../Utils";
import SideMenu from "../../Components/SideMenu";
import withUserAuth from "../../Behavior/withUserAuth";

const Refund = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const sale = location.state?.sale;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    let paymentType = sale?.salePayments?.[0]?.paymentTypeId || 0;
    const handleRefund = async () => {
        setLoading(true);
        const params = {
            invoiceId: sale.invoiceId,
            typeid: SALE_TYPE_ID_RETURN,
            status: 1,
            salePayments: sale.salePayments
        };
        const {code, error} = await refundSale(params);
        if (code === 200) {
            showSuccessToast("Refund Successful");
            navigate(-1);
        } else {
            setError(error);
        }
        setLoading(false);
    };

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError({})}/>}
            <Header title={"Refund"} hasBack/>
            <SideMenu selected={MENUS.RECEIPTS}/>
            <div id="appCapsule">
                {/* Cash payment Amount */}
                <div className="row section refund-amount mt-2 justify-content-center">
                    <div className="col-4">
                        <div className="single-cash">
                            <p>Total Refund</p>
                            <h2>{sale.grandTotal}</h2>
                        </div>
                    </div>
                </div>
                {/* * Cash payment Amount */}
                {/* Item Deleted */}
                <div className="section item-deleted-wrapper mt-3">
                    <div className="section-title text-center">item Deleted</div>
                    <div className="row">
                        {
                            sale.saleDetails.map((detail, index) => (
                                <div key={index} className="col-4">
                                    <a>
                                        <div className="card card-border p-0">
                                            <img src={detail.imageUrl || PLACEHOLDER_FOOD} className="card-img-top"
                                                 alt="image"/>
                                            <h4>{detail.itemName}</h4>
                                        </div>
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                </div>
                {/* * Item Deleted */}
                {/* Options */}
                <div className="section refund-option mt-3">
                    <div className="section-title">Refund to: <strong>{PAYMENT_TYPES[paymentType]}</strong></div>
                    <div className="section-title">Sold by: <strong>{sale.createdbysurname}</strong></div>
                </div>
                {/* Buttons */}
                <div className="section mt-3">
                    <a onClick={handleRefund} className="btn btn-primary btn-shadow btn-lg btn-block">Done</a>
                </div>
                {/* * Buttons */}
            </div>

        </>
    )
}

export default withUserAuth(Refund);
