import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import {useEffect, useState} from "react";
import {emailTransactionData, getReport} from "../../API";
import ReportItem from "../../Components/ReportItem";
import LineChart from "../../Components/LineChart";
import moment from "moment";
import ReportExportDialog from "../../Components/ReportExportDialog";
import withUserAuth from "../../Behavior/withUserAuth";
import {MENUS, REPORT_DATE_FORMAT} from "../../constants";
import {showSuccessToast} from "../../Utils";
import {Print} from "react-easy-print";
import SideMenu from "../../Components/SideMenu";

const TRIGGERS = [
    {
        id: 1,
        title: '1D'
    },
    {
        id: 2,
        title: '7D'
    },
    {
        id: 3,
        title: '1M'
    },
    {
        id: 4,
        title: '3M'
    },
    {
        id: 5,
        title: '1Y'
    },
    {
        id: 6,
        title: 'Max'
    }
];

const Report = ({onSidePanelOpen}) => {
    const [selectedTrigger, setSelectedTrigger] = useState(1);
    const [isOnline, setIsOnline] = useState(true);
    const [isInStore, setIsInStore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [report, setReport] = useState([]);
    const [showExportDialog, setShowExportDialog] = useState(false);

    const getStartEndDate = () => {
        let startDate = moment().format(REPORT_DATE_FORMAT);
        const endDate = moment().format(REPORT_DATE_FORMAT);
        switch (selectedTrigger) {
            case 2:
                startDate = moment().subtract(7, 'd').format(REPORT_DATE_FORMAT);
                break;
            case 3:
                startDate = moment().subtract(1, 'months').format(REPORT_DATE_FORMAT);
                break;
            case 4:
                startDate = moment().subtract(3, 'months').format(REPORT_DATE_FORMAT);
                break;
            case 5:
            case 6:
                startDate = moment().subtract(1, 'year').format(REPORT_DATE_FORMAT);
                break;
        }
        return {startDate, endDate};
    }

    const sendAPIRequest = async () => {
        const res = await getReport({...getStartEndDate(), isOnline, isInStore});
        if (res.code === 200) {
            setReport(res.data);
        } else {
            setError(res.error);
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        sendAPIRequest();
    }, [selectedTrigger, isOnline, isInStore]);

    const handleTriggerClick = (id) => () => {
        setSelectedTrigger(id);
    }

    const handleExportSave = async (params) => {
        setLoading(true);
        const res = await emailTransactionData(params);
        if (res.code === 200) {
            showSuccessToast(res.message);
            setShowExportDialog(false);
        } else {
            setError(res.error);
        }
        setLoading(false);
    }

    const handleDownloadReport = async () => {
        setLoading(true);
        const res = await emailTransactionData({...getStartEndDate(), action: 1, isDownload: true});
        if (res.code === 200) {
            window.open(res.data.httpPath, "_blank")
        } else {
            setError(res.error);
        }
        setLoading(false);
    }

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            {!!showExportDialog && <ReportExportDialog isOpen {...getStartEndDate()} onSave={handleExportSave}
                                                       onClose={() => setShowExportDialog(false)}/>}
            <Header title={"Report"} onSidePanelClick={onSidePanelOpen}/>
            <SideMenu selected={MENUS.RECEIPTS}/>
            <Print single>
                <div id="appCapsule">
                    <div className="section mt-3">
                        <div className="account-overview">
                            <div className="card">
                                <ul id="chartTimeTrigger" className="chart-time-trigger">
                                    {TRIGGERS.map(trigger => (
                                        <li key={trigger.id}
                                            className={`single-time-trigger ${trigger.id === selectedTrigger ? 'time-active' : ''}`}>
                                            <button onClick={handleTriggerClick(trigger.id)}>{trigger.title}</button>
                                        </li>
                                    ))}
                                </ul>
                                <LineChart data={report.data}/>
                            </div>
                        </div>
                    </div>
                    {/* * Over View */}
                    {/* Check */}
                    <div className="section mt-3">
                        <div className="report-check">
                            <div className="form-check">
                                <input checked={isOnline} onChange={() => setIsOnline(!isOnline)} type="checkbox"
                                       className="form-check-input" id="onlineCheck"/>
                                <label className="form-check-label" htmlFor="onlineCheck">Online</label>
                            </div>
                            <div className="form-check">
                                <input checked={isInStore} onChange={() => setIsInStore(!isInStore)} type="checkbox"
                                       className="form-check-input" id="checkInStore"/>
                                <label className="form-check-label" htmlFor="checkInStore">In-Store</label>
                            </div>
                        </div>
                    </div>
                    {/* * Check */}
                    {/* Amount Card */}
                    <div className="section payment-card row mt-2">
                        <div className="col-6 mt-1">
                            <div className="card card-border">
                                <div className="card-body">
                                    <h4>Total Sales Amount</h4>
                                    <h2 className="mb-0">{report.totalAmount}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mt-1">
                            <div className="card card-border">
                                <div className="card-body">
                                    <h4>No of sales</h4>
                                    <h2 className="mb-0">{report.totalCount}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mt-1">
                            <div className="card card-border">
                                <div className="card-body">
                                    <h4>Avg. Sales Amount</h4>
                                    <h2 className="mb-0">{report.avgAmount}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mt-1">
                            <div className="card card-border">
                                <div className="card-body">
                                    <h4>Total Returns</h4>
                                    <h2 className="mb-0">{report.totalReturns}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mt-1">
                            <div className="card card-border">
                                <div className="card-body">
                                    <h4>No of Returns</h4>
                                    <h2 className="mb-0">{report.noOfReturns}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* * Amount Card */}
                    {/* Payment */}
                    <div id="paymentProgress" className="section payment-progress mt-3">
                        <div className="section-title">Payments &amp; Others</div>
                        <div className="row">
                            <div className="col-4 mt-2">
                                <ReportItem
                                    title={"Card Payment"}
                                    value={report.cardPayments}
                                />
                            </div>
                            <div className="col-4 mt-2">
                                <ReportItem
                                    title={"Cash Payment"}
                                    value={report.cashPayments}
                                />
                            </div>
                            <div className="col-4 mt-2">
                                <ReportItem
                                    title={"Other Payment"}
                                    value={report.otherPayments}
                                />
                            </div>

                            <div className="col-4 mt-2">
                                <ReportItem
                                    title={"Avg. Margin"}
                                    value={report.avgMargin}
                                />
                            </div>

                            <div className="col-4 mt-2">
                                <ReportItem
                                    title={"Returning Customers"}
                                    value={report.returningCustomersAvg}
                                />
                            </div>
                        </div>
                    </div>
                    {/* * Payment */}
                    <div className="section mt-4">
                        <div className="row">
                            <div className="col">
                                <a onClick={handleDownloadReport}
                                   className="btn btn-primary btn-shadow btn-lg btn-block">Export</a>
                            </div>
                            <div className="col">
                                <a onClick={() => setShowExportDialog(true)}
                                   className="btn btn-primary btn-shadow btn-lg btn-block">Send</a>
                            </div>
                            <div className="col">
                                <a onClick={() => window.print()}
                                   className="btn btn-primary btn-shadow btn-lg btn-block">Print</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Print>

        </>
    );
}

export default withUserAuth(Report);
