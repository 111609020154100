import {PLACEHOLDER_FOOD} from "../constants";
import {SortableHandle} from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => <span className="move-icon"><ion-icon name="move"></ion-icon></span>);

const Item = ({item, selectedCategory, onItemClick, onItemEdit, onItemDelete, isEditMode}) => {
    return (
        <div className="col-4 sell-card mt-2" onClick={() => onItemClick && onItemClick(item)}>
            <div className="card item-card card-border p-0">
                <img src={item.detailimageurl || PLACEHOLDER_FOOD} className="card-img-top" alt="image"/>
                <h4>{item.name}</h4>
                {isEditMode &&
                    <div className="items-actions-wrapper">
                        <span onClick={() => onItemEdit && onItemEdit(item, selectedCategory)}
                              className="edit-item-button">
                            <ion-icon name="create"></ion-icon>
                        </span>
                        <DragHandle/>
                        <span className="delete-item-box" onClick={() => onItemDelete && onItemDelete(item)}>
                            <ion-icon name="trash-outline"></ion-icon>
                        </span>
                    </div>
                }
            </div>
        </div>
    );
};

export default Item;
