import {Link, useLocation, useNavigate} from "react-router-dom";
import {PATH_SIGNUP_PAYMENT} from "../constants";
import {getStoreTypes} from "../API";
import {useEffect, useState} from "react";
import Loader from "../Components/Loader";
import ErrorDialog from "../Components/ErrorDialog";
import Header from "../Components/Header";
import {isEmpty} from "lodash";

const SignupWhatDoYouDo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const businessType = location?.state;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [storeTypes, setStoreTypes] = useState([]);
    const [selectedTypeId, setSelectedTypeId] = useState(-1);

    const sendAPIRequest = async () => {
        const {code, data, error} = await getStoreTypes();
        if (code === 200) {
            setStoreTypes(data.storetypes);
            setSelectedTypeId(data.storetypes[0].storeTypeID)
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    useEffect(async () => {
        if (isEmpty(businessType)) {
            navigate(-1);
        } else {
            await sendAPIRequest();
        }
    }, []);

    const onRetry = !!error.retry ? handleRetry : null;
    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header hasBack title={"Your Business"}/>
            <div id="appCapsule">
                <div className="section mt-2">
                    <h2>What does your business sell?</h2>
                    <h4 className="mt-3">We want to make sure we set up your account, so that its fully optimised for
                        your line of business.</h4>
                </div>
                <div className="section mt-2">
                    <div className="card card-border">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="businessType">Business Type</label>
                                        <select className="form-control custom-select" id="businessType"
                                                onChange={e => setSelectedTypeId(e.target.value)}>
                                            {
                                                storeTypes.map(({title, storeTypeID}) => <option key={storeTypeID}
                                                                                                 value={storeTypeID}>{title}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="section mt-4">
                    <Link to={PATH_SIGNUP_PAYMENT} state={{...businessType, StoreTypeId: selectedTypeId}}
                          className="btn btn-primary btn-shadow btn-lg btn-block">Continue</Link>
                </div>
            </div>
        </>
    )
}

export default SignupWhatDoYouDo;
