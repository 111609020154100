import _ from 'lodash';
import {useInvoiceStore} from "../../Store";
import Header from "../../Components/Header";
import SideMenu from "../../Components/SideMenu";
import {MENUS, PATH_INVOICES_CLIENT, PATH_INVOICES_PRODUCT, PATH_INVOICES_VIEW} from "../../constants";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {formatStripeAmount} from "../../Utils";
import {useState} from "react";
import {finalizeInvoice, getInvoice, invoiceRemoveLine, invoiceUpdateLine, updateInvoiceDescription} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {useDebouncedEffect} from "../../Hooks/useDebouncedEffect";
import withUserAuth from "../../Behavior/withUserAuth";

const NewInvoice = ({onSidePanelOpen}) => {
    const navigate = useNavigate();

    const invoiceStore = useInvoiceStore();
    const invoice = invoiceStore.getInvoice();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [description, setDescription] = useState(invoice?.description);

    const products = invoice?.lines || [];
    const handleAddProduct = () => {
        if (!_.isEmpty(invoice)) {
            navigate(PATH_INVOICES_PRODUCT);
        }
    }

    const handleQuantityChange = async (line, quantity) => {
        if (quantity > 0) {
            setLoading(true);
            const params = {...line, quantity};
            const {code, data, error} = await invoiceUpdateLine(params);
            if (code === 200) {
                invoiceStore.setInvoiceLine(data.line);
            } else {
                setError(error);
            }
            setLoading(false);
        }
    }

    const handleRemoveLine = async ({lineId}) => {
        setLoading(true);
        const {code, error} = await invoiceRemoveLine({lineId});
        if (code === 200) {
            invoiceStore.removeInvoiceLine(lineId);
        } else {
            setError(error);
        }
        setLoading(false);
    }

    const handleFinalizeInvoice = async () => {
        setLoading(true);
        if(invoice.status == "draft")
        {
            const params = {invoiceId: invoice.id};
            const res = await getInvoice(params);
            if (res.code === 200) {
                invoiceStore.setInvoice(res.data.invoice)
            } else {
                invoiceStore.setInvoice(invoice);
            }
        
            // const {code, data, error} = await finalizeInvoice({invoiceId: invoice.id});
            // if (code === 200) {
            //     invoiceStore.setInvoice(data.invoice);
            //     navigate(PATH_INVOICES_VIEW);
            // } else {
            //     setError(error);
            // }
        }
        else
        {
            invoiceStore.setInvoice(invoice);
        }
        navigate(PATH_INVOICES_VIEW);
        setLoading(false);
    }

    useDebouncedEffect(async () => {
        if (_.isEmpty(invoice) || invoice.description === description) {
            return;
        }
        setLoading(true);
        const params = {
            invoiceId: invoice.id,
            description
        };
        const {code, data, error} = await updateInvoiceDescription(params);
        if (code === 200) {
            invoiceStore.setInvoice(data.invoice);
        } else {
            setError(error);
        }
        setLoading(false);
    }, [description], 1000)

    let title = "New Invoice" ;
    if(invoice && invoice.invoiceNumber)
    {
        title = `Invoice`;
    }
    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header title={title} onSidePanelClick={onSidePanelOpen}/>
            <SideMenu selected={MENUS.INVOICES}/>
            <div id="appCapsule">
                {/* Customer */}
                <div className="section invoice-added-customer mt-2">
                    <div className="section-title small" style={{float: 'right'}}><span className='invoice-number'>{invoice.invoiceNumber} </span> {invoice && invoice.status && <span className={"badge badge-primary invoice-status-" + invoice.status}>{invoice.status}</span>}</div>
                    <div className="section-title medium">Client </div>
                    {
                        _.isEmpty(invoice) ?
                            <Link to={PATH_INVOICES_CLIENT} className="add-btn-large">
                                <ion-icon name="add"></ion-icon>
                            </Link>
                            :
                            <ul className="listview separate-list image-listview inset no-line no-arrow m-0">
                                <li className="items-card card card-border">
                                    <div className="item">
                                        {/*<img src="assets/img/order/1.png" alt="image" className="image" />*/}
                                        <div className="in">
                                            <div>
                                                <h4>{invoice.customerName}</h4>
                                                <p>{invoice.customerEmail}</p>
                                                <p>{invoice.customerPhone}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<span className="delete-item">*/}
                                    {/*  <ion-icon name="close" />*/}
                                    {/*</span>*/}
                                    {/*<div className="delete-modal" />*/}
                                </li>
                            </ul>
                    }
                </div>
                {/* * Customer */}
                {/* Items */}
                <div className="section invoice-added-items mt-2">
                    <div className="section-title medium">Items</div>
                    <ul className="listview separate-list image-listview inset no-line no-arrow m-0">
                        {
                            products.map((product) => {
                                const {description, amount, lineId, quantity, imageUrl} = product;
                                return (
                                    <li key={lineId} className="items-card card card-border" id="check1">
                                        <div className="item">
                                            {imageUrl && <img src={imageUrl} alt={description} className="image" />}
                                            <div className="in">
                                                <div>
                                                    <h4>{description}</h4>
                                                    {/*<p>This is a longer description to ensure there are not more than*/}
                                                    {/*    100 characters in the description. </p>*/}
                                                    {/* item price quantity */}
                                                    <div className="item-price-qnt justify-content-between mt-1">
                                                        <h5>{formatStripeAmount(amount)}</h5>
                                                        <div className="qnt-incre-decre">
                                                            <div className="qnt-incre-decre-bg"/>
                                                            <div className="delete-checkout-modal"/>
                                                            <div className="delete-chekout-list-item" id="qntDecrease">
                                                                <ion-icon
                                                                    onClick={() => handleQuantityChange(product, quantity - 1)}
                                                                    name="remove-circle"/>
                                                            </div>
                                                            <input type="text" value={quantity}/>
                                                            <div id="qntIncrease">
                                                                <ion-icon
                                                                    onClick={() => handleQuantityChange(product, quantity + 1)}
                                                                    name="add-circle"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span onClick={() => handleRemoveLine(product)} className="delete-item">
                                                <ion-icon name="close"/>
                                            </span>
                                        </div>
                                    </li>
                                )
                            })
                        }

                    </ul>
                    <a onClick={handleAddProduct} className="add-btn-large mt-2">
                        <ion-icon name="add"/>
                    </a>
                </div>
                {/* * Items */}
                {/* Items */}
                <div className="section mt-2">
                    <div className="section-title medium">Memo</div>
                    <div className="card card-border">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <input value={description}
                                               onChange={(e => !_.isEmpty(invoice) ? setDescription(e.target.value) : '')}
                                               type="textarea" className="form-control" id="memo"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"/>
                                        </i>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* * Items */}
                {/* preview Button */}
                <div className="section mt-2 mb-5">
                    <a onClick={handleFinalizeInvoice}
                       className="btn btn-primary btn-shadow btn-lg btn-block">
                           Preview &amp; Send                         
                           </a>
                </div>
                {/* * preview Button */}
            </div>
        </>
    )
}

export default withUserAuth(NewInvoice);
