import {useEffect, useState} from 'react';
import withUserAuth from "../../Behavior/withUserAuth";
import {getTables} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../../Components/Spinner";
import {useNavigate} from "react-router-dom";
import {usePosStore} from "../../Store";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const POSTables = () => {
    const posStore = usePosStore();
    const navigate = useNavigate();
    const [tables, setTables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});

    const sendAPIRequest = async () => {
        const {code, data, error} = await getTables();
        if (code === 200) {
            setTables(data.tables);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest(1);
    }, []);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest(1);
    }

    const onRetry = !!error.retry ? handleRetry : null;

    const handleClick = ({tableId, name}) => () => {
        posStore.setTable(tableId, name);
        navigate(-1);
    }

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Add Table"} hasBack/>
            <SideMenu selected={MENUS.POS}/>
            <div id='appCapsule'>
                {/* Items */}
                {/* details */}
                <div className="section">
                    <InfiniteScroll
                        className="listview receipts-filter filter-items image-listview no-line no-arrow inset"
                        dataLength={tables.length}
                        next={sendAPIRequest}
                        hasMore={false}
                        loader={<Spinner/>}
                        scrollableTarget={"sidebar-content"}
                    >
                        {
                            tables.map(table => {
                                return (
                                    <li key={table.tableId} className="items-card card card-border" data-item="today">
                                        <a onClick={handleClick(table)} className="item">
                                            <div className="in">
                                                <div>
                                                    <h4 style={{textAlign: 'left'}}>{table.name}</h4>
                                                    <h5 style={{margin: 0}}
                                                        className={`${table.status === 'Free' ? 'text-success' : 'text-danger'}`}>{table.status}</h5>
                                                </div>
                                                <p>{table.capacity}</p>
                                            </div>
                                        </a>
                                    </li>
                                )
                            })
                        }
                        {
                            tables.length === 0 &&
                            <span className={"fontsize-heading"} style={{textAlign: 'center'}}>No Table Found</span>
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(POSTables);
