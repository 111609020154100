import {useState} from 'react';
import Header from "../../Components/Header";
import withUserAuth from "../../Behavior/withUserAuth";
import {setTerminalInfo} from "../../API";
import ErrorDialog from "../../Components/ErrorDialog";
import Loader from "../../Components/Loader";
import {useLocation, useNavigate} from "react-router-dom";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const Hardware = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const terminal = location?.state?.terminal;

    const [readerName, setReaderName] = useState(terminal?.readerName || '')
    const [registrationCode, setRegisterCode] = useState(terminal?.registrationCode || '')
    const [locationName, setLocation] = useState(terminal?.locationName || '')
    const [addressLine1, setAddressLine] = useState(terminal?.addressLine1 || '')
    const [city, setCity] = useState(terminal?.city || '')
    const [postalCode, setPostalCode] = useState(terminal?.postalCode || '')
    const [state, setState] = useState(terminal?.state || '')

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});

    const sendAPIRequest = async () => {
        const readerReq = {
            readerName,
            registrationCode,
            locationName,
            addressLine1,
            city,
            postalCode,
            state
        };
        setLoading(true);
        const {code, error} = await setTerminalInfo(readerReq);
        if (code === 200) {
            navigate(-1);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const handleSave = async () => {
        sendAPIRequest();
    }

    const onRetry = !!error.retry ? handleRetry : null;

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Hardware"} hasBack/>
            <SideMenu selected={MENUS.SETTINGS}/>
            {/* App Capsule */}
            <div id="appCapsule">
                {/* Items */}
                <div className="section mt-2">
                    {/* Set Name */}
                    <div className="card card-border">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="Name">Reader Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Name"
                                            value={readerName}
                                            onChange={e => setReaderName(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="hardwareType">Type</label>
                                        <select className="form-control custom-select" id="hardwareType">
                                            {/*<option value={0}>Select Type</option>*/}
                                            <option value={1}>Terminal</option>
                                            {/*<option value={2}>Printer</option>*/}
                                            {/*<option value={2}>Scanner</option>*/}
                                            {/*<option value={2}>Cashbox</option>*/}
                                            {/*<option value={2}>Weight</option>*/}
                                            {/*<option value={2}>Other</option>*/}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="code">Reader Registration Code</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="code"
                                            value={registrationCode}
                                            onChange={e => setRegisterCode(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="Location">Location Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Location"
                                            value={locationName}
                                            onChange={e => setLocation(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="Address">Address Line</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Address"
                                            value={addressLine1}
                                            onChange={e => setAddressLine(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="City">City</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="City"
                                            value={city}
                                            onChange={e => setCity(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="postal">Postal Code</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="postal"
                                            value={postalCode}
                                            onChange={e => setPostalCode(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="state">State</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            value={state}
                                            onChange={e => setState(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* * Set Name */}
                    <div className="mt-4">
                        <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Save</a>
                        {/*<a href="settings-hardware.html" className="btn btn-primary btn-shadow btn-lg btn-block mt-2">Order Card Terminal</a>*/}
                        {/*<a href="settings-hardware.html" className="btn btn-primary btn-shadow btn-lg btn-block mt-2">Order Other Hardware</a>*/}
                    </div>
                </div>
            </div>


        </>
    )
}

export default withUserAuth(Hardware);
