import {useEffect, useState} from "react";
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import {deleteStaff, getAllStaffs} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import {Link} from "react-router-dom";
import {MENUS, PATH_STAFFS_STAFF, PLACEHOLDER_AVATAR, STAFF_TYPE} from "../../constants";
import withUserAuth from "../../Behavior/withUserAuth";
import Spinner from "../../Components/Spinner";
import SideMenu from "../../Components/SideMenu";

const Staffs = ({onSidePanelOpen}) => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [staffType, setStaffType] = useState(0);

    const sendAPIRequest = async () => {
        const params = {staffType};
        const {code, data, error} = await getAllStaffs(params);
        if (code === 200) {
            setClients(data.staffs);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest();
    }, [staffType]);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const handleDelete = ({userid, name}) => async () => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
        if (isConfirm) {
            setLoading(true);
            const {code, error} = await deleteStaff({userid})
            if (code === 200) {
                await sendAPIRequest();
            } else {
                setLoading(false);
                setError({message: error, retry: false});
            }
        }
    }

    const onRetry = !!error.retry ? handleRetry : null;

    return (
        <>
            {loading && <Loader/>}
            {!!error.message &&
                <ErrorDialog isOpen={true} content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
            <Header title={"Staffs"} onSidePanelClick={onSidePanelOpen}/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                {/* Category */}
                <div className="section">
                    <ul id="categories" className="categories pt-1 pb-1 pe-4">
                        {
                            _.keys(STAFF_TYPE).map((key) => {
                                return (
                                    <li key={key} className={`category ${key == staffType ? 'category-active' : ''}`}>
                                        <a onClick={() => setStaffType(key)}>{STAFF_TYPE[key]}</a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

                <InfiniteScroll
                    className="listview separate-list staff-filter-items filter-items image-listview no-arrow no-line inset"
                    dataLength={clients.length}
                    next={sendAPIRequest}
                    loader={<Spinner/>}
                    scrollableTarget={"sidebar-content"}
                >
                    {
                        clients.map(staff => {
                            return (
                                <li key={staff.userid} className="items-card card card-border">
                                    <div className="items-card-wrapper">
                                        <Link to={PATH_STAFFS_STAFF} state={{staff}} className="item">
                                            <img src={staff.imageurl || PLACEHOLDER_AVATAR} alt="image"
                                                 className="image"/>
                                            <div className="in">
                                                <div>
                                                    <h4>{staff.name}</h4>
                                                    <p>{STAFF_TYPE[staff.stafftype]}</p>
                                                </div>
                                                <h4 className="mbl-no">{staff.phonenumber}</h4>
                                            </div>
                                        </Link>
                                        <span onClick={handleDelete(staff)} className="delete-item py-3 ps-1 pe-3">
                                          <ion-icon name="close"/>
                                        </span>
                                    </div>
                                </li>
                            )
                        })
                    }
                    {
                        clients.length === 0 &&
                        <span className={"fontsize-heading"} style={{textAlign: 'center'}}>No Staff Found</span>
                    }
                </InfiniteScroll>
                <Link to={PATH_STAFFS_STAFF} style={{marginLeft: '16px'}}
                      className="btn add-tag-anchor btn-primary mt-1">
                      <span>
                        <ion-icon name="add-circle"/>
                      </span>
                    Add Staff
                </Link>
                {/* * Item */}
            </div>
        </>
    )
}

export default withUserAuth(Staffs);
