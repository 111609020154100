import withUserAuth from "../../Behavior/withUserAuth";
import {useEffect, useState} from "react";
import {getInvoice, getInvoices} from "../../API";
import Header from "../../Components/Header";
import {useNavigate, useSearchParams} from "react-router-dom";
import {MENUS, PATH_INVOICES_NEW, PATH_ORDERS_QR} from "../../constants";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import SideMenu from "../../Components/SideMenu";
import {useInvoiceStore} from "../../Store";
import {formatStripeAmount} from "../../Utils";

const Orders = ({onSidePanelOpen}) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [invoices, setInvoices] = useState([]);

    const invoiceStore = useInvoiceStore();

    const sendAPIRequest = async (status) => {
        setLoading(true);
        const res = await getInvoices(status);
        if (res.code === 200) {
            setInvoices(res.data.invoiceList)
        } else {
            setError(res.error);
        }
        setLoading(false);
    }

    const status = searchParams.get('status') || '';

    useEffect(async () => {
        await sendAPIRequest(status);
    }, [status]);

    const onTypeSelected = (status) => () => {
        const urlParams = new URLSearchParams(searchParams);
        urlParams.set('status', status);
        setSearchParams(urlParams);
    }

    const handleNewInvoice = () => {
        invoiceStore.clearStore();
        navigate(PATH_INVOICES_NEW);
    }

    const handleInvoiceClick = async (invoice) => {
        setLoading(true);
        const params = {invoiceId: invoice.id};
        const res = await getInvoice(params);
        if (res.code === 200) {
            invoiceStore.setInvoice(res.data.invoice)
        } else {
            invoiceStore.setInvoice(invoice);
        }
        setLoading(false);
        navigate(PATH_INVOICES_NEW);
        // navigate({pathname: PATH_PARKED_DETAIL, search: `id=${invoiceId}`});
    }

    const handleOrderQRCode = () => {
        navigate(PATH_ORDERS_QR);
    }

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header title={"Invoices"} onSidePanelClick={onSidePanelOpen} onQrCodeClick={handleOrderQRCode}
                    onAddClick={handleNewInvoice}/>
            <SideMenu selected={MENUS.INVOICES}/>
            <div id="appCapsule">
                {/* Category */}
                <ul id="categories" className="categories ps-2 py-1 pe-4">
                    <li className={`category ${status === '' ? 'category-active' : ''}`}>
                        <a onClick={onTypeSelected('')}>All</a>
                    </li>
                    <li className={`category ${status === 'draft' ? 'category-active' : ''}`}>
                        <a onClick={onTypeSelected('draft')}>Draft</a>
                    </li>
                    <li className={`category ${status === 'open' ? 'category-active' : ''}`}>
                        <a onClick={onTypeSelected('open')}>Outstanding</a>
                    </li>
                    <li className={`category ${status === 'void' ? 'category-active' : ''}`}>
                        <a onClick={onTypeSelected('void')}>Void</a>
                    </li>
                    <li className={`category ${status === 'paid' ? 'category-active' : ''}`}>
                        <a onClick={onTypeSelected('paid')}>Paid</a>
                    </li>
                </ul>
                {/* * Category */}
                {/* Order Item */}
                <ul className="listview order-filter-items filter-items image-listview inset">
                    {
                        invoices.map((invoice) => {
                            const {id, total, customerName, invoiceNumber, status} = invoice;
                            return (
                                <li key={id} onClick={() => handleInvoiceClick(invoice)}
                                    className="items-card card card-border">
                                    <div className="item">
                                        {/*<img src={customerUrl || PLACEHOLDER_AVATAR} alt="image" className="image"/>*/}
                                        <div className="in">
                                            <div>
                                                <h4>{customerName}                                                
                                                </h4>
                                                <p>{invoiceNumber}</p>
                                                {status && <p><span className={"badge badge-primary invoice-status-" + status}>{status}</span></p>}
                                            </div>
                                            <div className="justify-content-center">
                                                <p style={{textAlign: 'right'}}>{formatStripeAmount(total)}</p>
                                            </div>
                                            {/*{*/}
                                            {/*    isParked && <button onClick={handleProcessed(invoiceId)}*/}
                                            {/*                        className="btn btn-secondary">Proceed</button>*/}

                                            {/*}*/}
                                            {/*{*/}
                                            {/*    !isParked && orderStatus === 0 &&*/}
                                            {/*    <button onClick={handleOrderAction(invoiceId, 2)}*/}
                                            {/*            className="btn btn-secondary">Mark In Progress</button>*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    !isParked && orderStatus === 2 &&*/}
                                            {/*    <button onClick={handleOrderAction(invoiceId, 1)}*/}
                                            {/*            className="btn btn-make-ready">Mark Ready</button>*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    !isParked && orderStatus === 1 &&*/}
                                            {/*    <button onClick={handleOrderAction(invoiceId, 3)}*/}
                                            {/*            className="btn btn-ready">Complete</button>*/}
                                            {/*}*/}
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                    {

                        invoices.length === 0 && <li>
                            <div className={"fontsize-heading"} style={{width: '100%', textAlign: 'center'}}>No Invoice
                                Found
                            </div>
                        </li>
                    }
                </ul>
                {/* * Order Item */}
            </div>

        </>
    )
}
export default withUserAuth(Orders);
