import {isFirstLogin, isUserLoggedIn} from "../DB";
import {Navigate} from "react-router-dom";
import {PATH_LOGIN, PATH_SELL, PATH_SIGNUP_FIRST} from "../constants";

const Home = () => {
    let navigateTo = PATH_LOGIN;
    if (isUserLoggedIn()) {
        if (isFirstLogin()) {
            navigateTo = PATH_SIGNUP_FIRST;
        } else {
            navigateTo = PATH_SELL;
        }
    }
    return <Navigate to={navigateTo} />
};

export default Home;
