import {useNavigate} from "react-router-dom";
import {useInvoiceStore} from "../../Store";
import Products from "../Products/Products";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {useState} from "react";
import {invoiceAddLine} from "../../API";


const InvoiceProducts = () => {
    const navigate = useNavigate();
    const invoiceStore = useInvoiceStore();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleClick = async ({externalProductId, unitAmount, name, images}) => {
        setLoading(true);
        const {customerId, id} = invoiceStore.getInvoice();
        let imageUrl = '';
        if (images && images.length > 0) {
            imageUrl = images[0];
        }
        const params = {
            customerId,
            invoiceId: id,
            description: name,
            quantity: 1,
            product: externalProductId,
            price: unitAmount,
            imageUrl: imageUrl
        }
        const {code, data, error} = await invoiceAddLine(params);
        if (code === 200) {
            invoiceStore.setInvoiceLine(data.line);
            navigate(-1);
        } else {
            setError(error);
        }
        setLoading(false);
    }
    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Products onProductClick={handleClick}/>
        </>
    )
}

export default InvoiceProducts;
