import React from 'react';
import {createPosStore} from "./PosStore";
import {createItemsStore} from "./ItemsStore";
import {createInvoiceStore} from "./InvoiceStore";
import {useLocalStore} from 'mobx-react-lite';

const PosContext = React.createContext(null)
const ItemContext = React.createContext(null)
const InvoiceContext = React.createContext(null)

export const PosProvider = ({children}) => {
    const posStore = useLocalStore(createPosStore);
    const itemStore = useLocalStore(createItemsStore);
    const invoiceStore = useLocalStore(createInvoiceStore);

    return (
        <PosContext.Provider value={posStore}>
            <ItemContext.Provider value={itemStore}>
                <InvoiceContext.Provider value={invoiceStore}>
                    {children}
                </InvoiceContext.Provider>
            </ItemContext.Provider>
        </PosContext.Provider>
    )
}

export const usePosStore = () => React.useContext(PosContext);
export const useItemStore = () => React.useContext(ItemContext);
export const useInvoiceStore = () => React.useContext(InvoiceContext);
