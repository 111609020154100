import Modal from 'react-modal';
import {useState} from "react";
import {calculateProfit, calculateTaxAmount, showErrorToast} from "../Utils";
import {isEmpty} from "lodash";

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0, 0.5)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'none',
        inset: 'none',
        borderRadius: 'none',
        border: 0,
        background: 'none',
    },
};

const VariationDialog = ({isOpen, onClose, onSave, variation, taxRate}) => {

    console.log('variation>>>>>', variation);
    const [name, setName] = useState(() => variation?.name || '')
    const [price, setPrice] = useState(() => variation?.salesprice || '');
    const [cost, setCost] = useState(() => variation?.costprice || '');
    const [margin, setMargin] = useState(() => variation?.margin || '');
    const [retailPrice, setRetailPrice] = useState(() => variation?.retailprice || '');
    const [profit, setProfit] = useState(() => variation?.profit || '');
    const [barcode, setBarcode] = useState(() => variation?.barcode || '');
    const [sku, setSku] = useState(() => variation?.sku || '');
    const [taxAmount, setTaxAmount] = useState(() => {
        if (variation) {
            return calculateTaxAmount(retailPrice, taxRate);
        }
        return 0;
    });

    const handlePriceChange = e => {
        let value = e.target.value;
        const res = calculateProfit('price', value, cost, margin, taxRate);
        setPrice(value);
        setRetailPrice(res.retailPrice);
        setMargin(res.margin);
        setProfit(res.profit);
        setTaxAmount(res.taxAmount);
    }

    const handleCostChange = e => {
        let value = e.target.value;
        const res = calculateProfit('cost', price, value, margin, taxRate);
        setCost(value);
        setPrice(res.salePrice);
        setRetailPrice(res.retailPrice);
        setMargin(res.margin);
        setProfit(res.profit);
        setTaxAmount(res.taxAmount);
    }

    const handleMarginChange = e => {
        let value = e.target.value;
        const res = calculateProfit('margin', price, cost, value, taxRate);
        setPrice(res.salePrice);
        setRetailPrice(res.retailPrice);
        setMargin(value);
        setProfit(res.profit);
        setTaxAmount(res.taxAmount);
    }

    const validate = () => {
        if (!name) {
            return 'Name is required';
        }
        if (!price) {
            return 'Sale Price is required';
        }
        if (!cost) {
            return 'Cost is required';
        }
    }

    const handleSave = () => {
        let error = validate();
        if (!isEmpty(error)) {
            showErrorToast(error);
        } else {
            const newVariation = {
                ...(variation.itemvariationid ? {itemvariationid: variation.itemvariationid} : {}),
                name,
                salesprice: price,
                taxamount: taxAmount,
                retailprice: retailPrice,
                costprice: cost,
                barcode,
                sku,
                profit,
                margin,
            }
            onSave(newVariation)
        }
    }

    return (
        <Modal
            style={customStyles}
            isOpen={isOpen}>
            <div className="dialogbox show">
                <div className="modal-dialog">
                    <div className="modal-content card-border">
                        <div className="modal-icon" style={{fontSize: '18px', marginBottom: 0, marginTop: '16px'}}>
                            Variation
                        </div>
                        <div className="modal-body listing mt-2">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="itemName">
                                            Variation Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="itemName"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="price">
                                            Sale Price
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="price"
                                            value={price}
                                            onChange={e => handlePriceChange(e)}
                                            maxLength={10}
                                        />
                                        {/*<span style={{fontSize: '11px'}}>Retail Price {retailPrice}</span>*/}
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="cost">
                                            Cost
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="cost"
                                            value={cost}
                                            onChange={e => handleCostChange(e)}
                                            maxLength={10}
                                        />
                                        {/*<span style={{fontSize: '11px'}}>Tax Amount {taxAmount} </span>*/}
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="margin">
                                            Margin
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="margin"
                                            value={margin}
                                            onChange={e => handleMarginChange(e)}
                                            maxLength={10}
                                        />
                                        {/*<span style={{fontSize: '11px'}}>Profit {profit}</span>*/}
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="sku">
                                            SKU
                                        </label>
                                        <input type="text" className="form-control" id="sku" value={sku}
                                               maxLength={100}
                                               onChange={e => setSku(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="barcode">
                                            Barcode
                                        </label>
                                        <input type="text" className="form-control" id="barcode" value={barcode}
                                               maxLength={100}
                                               onChange={e => setBarcode(e.target.value)}/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <a onClick={onClose} className="btn">Close</a>
                                <a onClick={handleSave} className="btn">Save</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default VariationDialog;
