import {useState} from 'react';
import {PATH_SIGNUP_STRIPE} from "../constants";
import {Link, useLocation, useNavigate} from "react-router-dom";

const SignupPayment = () => {
    const location = useLocation();
    const signUpDetail = location?.state;
    const navigate = useNavigate();

    const [IsSwypeTerminal, setIsSwypeTerminal] = useState(false);
    const [IsInvoices, setIsInvoices] = useState(false);
    const [IsOnlineCheckout, setOnlineCheckout] = useState(false);
    const [IsOrderApp, setIsOrderApp] = useState(false);
    const [IsSmallCardReader, setSmallCardReader] = useState(false);

    return (
        <div>
            <div className="appHeader">
                <div className="left">
                    <a onClick={() => navigate(-1)} className="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div className="pageTitle">Your Business</div>
            </div>
            {/* * App Header */}
            {/* App Capsule */}
            <div id="appCapsule">
                <div className="section mt-2">
                    <h2>How would you like to accept Payments?</h2>
                    <h4 className="mt-3">Choose all that apply. Most or our merchants use multiple payment products</h4>
                </div>
                <div className="section what-do-you-sell-2 mt-2 mb-2">
                    <ul className="listview separate-list image-listview transparent no-line flush">
                        <li>
                            <div className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="id-card-outline" />
                                </div>
                                <div className="in">
                                    <div>
                                        <h3>The Rapedido Terminal</h3>
                                        <h5>All in One POS System- Android Terminal, with Card Aceptance. </h5>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input
                                            checked={IsSwypeTerminal}
                                            onChange={e => setIsSwypeTerminal(e.target.checked)}
                                            className="form-check-input" type="checkbox" id="SwitchCheckDefault1"/>
                                        <label className="form-check-label" htmlFor="SwitchCheckDefault1"/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="calculator-outline" />
                                </div>
                                <div className="in">
                                    <div>
                                        <h3>Small Card Reader</h3>
                                        <h5>Accept Cards, In-Person and on the go. </h5>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input
                                            checked={IsSmallCardReader}
                                            onChange={e => setSmallCardReader(e.target.checked)}
                                            className="form-check-input" type="checkbox" id="SwitchCheckDefault2"/>
                                        <label className="form-check-label" htmlFor="SwitchCheckDefault2"/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="qr-code-outline" />
                                </div>
                                <div className="in">
                                    <div>
                                        <h3>Order App</h3>
                                        <h5>QR Code and pay by link offer to your customers, to order and pay. </h5>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input
                                            checked={IsOrderApp}
                                            onChange={e => setIsOrderApp(e.target.checked)}
                                            className="form-check-input" type="checkbox" id="SwitchCheckDefault3"/>
                                        <label className="form-check-label" htmlFor="SwitchCheckDefault3"/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="newspaper-outline" />
                                </div>
                                <div className="in">
                                    <div>
                                        <h3>Invoices</h3>
                                        <h5>Send Invoices Free and get paid fast!</h5>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input
                                            checked={IsInvoices}
                                            onChange={e => setIsInvoices(e.target.checked)}
                                            className="form-check-input" type="checkbox" id="SwitchCheckDefault4"/>
                                        <label className="form-check-label" htmlFor="SwitchCheckDefault4"/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cart-outline" />
                                </div>
                                <div className="in">
                                    <div>
                                        <h3>Online Checkout</h3>
                                        <h5>Create checkout button and payable link for your website.</h5>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input
                                            checked={IsOnlineCheckout}
                                            onChange={e => setOnlineCheckout(e.target.checked)}
                                            className="form-check-input" type="checkbox" id="SwitchCheckDefault5"/>
                                        <label className="form-check-label" htmlFor="SwitchCheckDefault5"/>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="section mt-4">
                    <Link to={PATH_SIGNUP_STRIPE} state={{
                        ...signUpDetail,
                        IsSmallCardReader,
                        IsOrderApp,
                        IsOnlineCheckout,
                        IsInvoices,
                        IsSwypeTerminal
                    }} className="btn btn-primary btn-shadow btn-lg btn-block">Continue</Link>
                </div>
            </div>
        </div>

    )
}

export default SignupPayment;
