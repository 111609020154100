import {v1 as uuidv1} from 'uuid';

export const getDarkMode = () => {
    const darkMode = localStorage.getItem('DARK_MODE');
    return !!JSON.parse(darkMode);
}

export const toggleDarkMode = (darkMode) => {
    localStorage.setItem('DARK_MODE', JSON.stringify(darkMode));
}

export const getDeviceId = () => {
    let deviceId = localStorage.getItem('DEVICE_ID');
    if (!deviceId) {
        deviceId = uuidv1();
        localStorage.setItem('DEVICE_ID', deviceId);
    }
    return deviceId;
}

export const setRegisterDetail = (registerDetail) => {
    localStorage.setItem("REGISTER_DETAIL", JSON.stringify(registerDetail));
}

export const getRegisterDetail = () => {
    const registerDetail = localStorage.getItem("REGISTER_DETAIL")
    if (registerDetail) {
        return JSON.parse(registerDetail);
    }
    return {};
}

export const setUserData = (userData) => {
    localStorage.setItem("USER_DATA", JSON.stringify(userData));
}

export const getUser = () => {
    const userData = localStorage.getItem("USER_DATA")
    if (userData) {
        return JSON.parse(userData);
    }
    return {};
}

export const setOrgData = (orgData) => {
    localStorage.setItem("ORG_DATA", JSON.stringify(orgData));
}

export const getOrgData = () => {
    const orgData = localStorage.getItem("ORG_DATA")
    if (orgData) {
        return JSON.parse(orgData);
    }
    return {};
}

export const isFirstLogin = () => {
    return !!getUser()?.isfirstlogin;
}

export const setAuthToken = (token) => {
    localStorage.setItem("TOKEN", token);
}

export const getAuthToken = () => {
    return localStorage.getItem('TOKEN');
}

export const isUserLoggedIn = () => {
    return !!getAuthToken();
};

export const isAdminLoggedIn = () => {
    return getUser()?.roleid == 1 || false;
}

