import {useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router";
import _ from 'lodash';
import Header from "../../Components/Header";
import {useItemStore, usePosStore} from "../../Store";
import POSItemDetail from "../../Components/POSIitemsDetail";
import {v1 as uuidv1} from "uuid";
import {showErrorToast} from "../../Utils";
import SideMenu from "../../Components/SideMenu";
import {MENUS} from "../../constants";

const POSItem = () => {
    const itemStore = useItemStore();
    const posStore = usePosStore();
    const allModifiers = itemStore.getModifiers();
    const location = useLocation();
    const navigate = useNavigate();
    const item = location?.state?.item;
    const category = itemStore.getCategoryById(item.categoryid);

    const saleItemRefs = useRef([]);

    const [saleItems, setSaleItems] = useState(() => {
        const saleCartItems = posStore.getItemById(item.itemid);
        if (saleCartItems.length > 0) {
            return saleCartItems;
        }
        return [{id: uuidv1()}];
    })

    const setSaleItemRef = (el) => {
        if (el) {
            const refIndex = _.findIndex(saleItemRefs.current, (refs => refs.id === el.id));
            if (refIndex >= 0) {
                saleItemRefs.current.splice(refIndex, 1, el);
            } else {
                saleItemRefs.current.push(el);
            }
        }
    }

    const handleAddAnother = () => {
        setSaleItems((saleItems) => [...saleItems, {id: uuidv1()}]);
    }

    const handleRemoveLast = () => {
        if (saleItems.length > 0) {
            const tempItems = [...saleItems];
            tempItems.splice(-1);
            saleItemRefs.current.splice(-1);
            setSaleItems(tempItems);
        }
    }

    const handleAddToOrder = () => {
        try {
            const saleItems = saleItemRefs.current.reduce((preSale, nextSales) => {
                return [...preSale, nextSales.getItemDetail()];
            }, []);
            posStore.addItemsToCart(item.itemid, saleItems);
            navigate(-1);
        } catch (error) {
            showErrorToast(error);
        }
    }
    const hasImage = !!item.detailimageurl;
    return (
        <>
            <Header className={'sell-item-header transparent'} title={!hasImage ? item.name : ''} hasBack/>
            <SideMenu selected={MENUS.POS}/>
            <div id="appCapsule" className={hasImage ? 'p-0' : ''}>
                {/* Product Details */}
                {
                    hasImage &&
                    <div className="sell-item-img">
                        <img src={item.detailimageurl} className="card-img-top" alt="image"/>
                    </div>
                }

                <div className="card-text px-3 mt-2">
                    {hasImage && <h2>{item.name}</h2>}
                    <p>
                        <small>{item.description}</small>
                    </p>

                    <div className="item-price-qnt mt-1">
                        <h5>{item.salesprice}</h5>
                        <div className="qnt-incre-decre">
                            <div className="qnt-incre-decre-bg"></div>
                            <div></div>
                            <div id="qntDecrease" onClick={handleRemoveLast}>
                                <ion-icon name="remove-circle"></ion-icon>
                            </div>
                            <input type="text" value={saleItems.length} readOnly/>
                            <div id="qntIncrease" onClick={handleAddAnother}>
                                <ion-icon name="add-circle"></ion-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section sell-item-pic-details mt-2">
                    {
                        saleItems.map((saleItem) => (
                            <POSItemDetail
                                key={saleItem.id}
                                ref={setSaleItemRef}
                                category={category}
                                item={item}
                                allModifiers={allModifiers}
                                saleItem={saleItem}
                            />
                        ))
                    }
                </div>
                {/* * Product Details */}
                {/* Add to cart button */}
                <div className="section add-to-order mt-2">
                    <a onClick={handleAddToOrder} className="btn btn-primary btn-shadow btn-lg btn-block">Add to
                        Order</a>
                </div>
            </div>
        </>
    );


}

export default POSItem;
