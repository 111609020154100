import * as React from 'react';
import {useState} from 'react';

const PasswordField = ({label, password, setPassword, error}) => {
    const [passwordShown, setPasswordShown] = useState(false);

    return (
        <div className="input-wrapper">
            <div>
                <label className="label" htmlFor="password1">
                    {label}
                </label>
                <input
                    id="password1"
                    type={passwordShown ? "text" : "password"}
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <span className="text-danger">{error}</span>
            </div>
            <div className="ps-toggler" onClick={() => setPasswordShown(!passwordShown)}>
                <ion-icon name="eye-outline"/>
                <ion-icon name="eye-off-outline"/>
            </div>
        </div>
    )
}

export default PasswordField;
