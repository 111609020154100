import {useLocation, useNavigate} from "react-router";
import {useRef, useState} from "react";
import {MENUS} from "../../constants";
import {addProduct, updateProduct, uploadFile} from "../../API";
import _ from 'lodash'
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import Header from "../../Components/Header";
import SideMenu from "../../Components/SideMenu";

const Product = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const product = location.state?.product || {};

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [imageUrl, setImageUrl] = useState(() => _.isEmpty(product?.images) ? '' : product.images[0]);
    const [imageFile, setImageFile] = useState(null);
    const imageInputRef = useRef();
    const [name, setName] = useState(() => product?.name || '')
    const [description, setDescription] = useState(() => product?.description || '')
    const [unitAmount, setUnitAmount] = useState(() => product?.unitAmount || '');

    const handleSave = async () => {

        const newProduct = {
            ...(product.externalProductId ? {externalProductId: product.externalProductId} : {}),
            images: product.images || [],
            name,
            description,
            unitAmount,
        }

        setLoading(true);

        if (imageUrl !== '' && imageUrl !== product?.images?.[0]) { //need to upload file
            const res = await uploadFile(imageFile);
            if (res.code === 200) {
                newProduct.images = [res.data.image.url];
            } else {
                setLoading(false);
                setError(res.error)
                return;
            }
        }
        let res;
        if (newProduct.externalProductId) { //Update
            res = await updateProduct(newProduct);
        } else {
            res = await addProduct(newProduct);
        }
        setLoading(false);
        if (res.code === 200) {
            navigate(-1);
        } else {
            setError(res.error);
        }
    }

    // const handleDelete = async () => {
    //     const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
    //     if (isConfirm) {
    //         setLoading(true);
    //         const {accountid} = client;
    //         const {code, error} = await deleteClient({accountid})
    //         if (code === 200) {
    //             navigate(-1);
    //         } else {
    //             setLoading(false);
    //             setError(error);
    //         }
    //     }
    // }

    console.log('product', product);
    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header hasBack title={"Product"}/>
            <SideMenu selected={MENUS.PRODUCTS}/>

            <div id="appCapsule">
                {/* modify image */}
                <div className="section">
                    <div onClick={() => imageInputRef.current.click()} className="get-img-wrapper">
                        <div className={`img-holder ${imageUrl ? 'img-active' : ''}`}>
                            {imageUrl && <img src={imageUrl} alt="img"/>}
                            <div className="get-img">
                                <ion-icon name="camera"/>
                            </div>
                            <input
                                style={{display: 'none'}}
                                ref={imageInputRef}
                                type="file"
                                className="form-control"
                                id="photo"
                                accept="image/*"
                                onChange={(e) => {
                                    if (e.target?.files?.length > 0) {
                                        setImageUrl(URL.createObjectURL(e.target.files[0]));
                                        setImageFile(e.target.files[0]);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* * modify image */}
                {/* details */}
                <div className="section">
                    <div className="card card-border mt-2">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="itemName">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="itemName"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                        />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"/>
                                        </i>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="Description">Description</label>
                                        <textarea
                                            id="Description"
                                            rows={2}
                                            className="form-control"
                                            value={description}
                                            onChange={e => setDescription(e.target.value)}
                                        />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"/>
                                        </i>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="price">Price</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="price"
                                            value={unitAmount}
                                            onChange={e => setUnitAmount(e.target.value)}
                                            readOnly={!_.isEmpty(product)}
                                        />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"/>
                                        </i>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* * details */}
                {/* action Button */}
                <div className="section mt-2 mb-5">
                    <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Save</a>
                </div>
                {/* * action Button */}
            </div>

        </>
    )
}

export default Product;
