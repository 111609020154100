import {getDarkMode} from "../DB";

const Loader = () => {
    return (
        <div id="loader">
            <img src={getDarkMode() ? "/img/loading-dark-icon.png" : "/img/loading-icon.png"} alt="icon"
                 className="loading-icon"/>
        </div>
    )
}

export default Loader;
