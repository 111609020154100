import Modal from 'react-modal';
import {PATH_LOGIN} from "../constants";
import {Link} from "react-router-dom";

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0, 0.5)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'none',
        inset: 'none',
        borderRadius: 'none',
        border: 0,
        background: 'none',
    },
};

const SignupDialog = ({ isOpen }) => {
    return (
        <Modal
            style={customStyles}
            isOpen={isOpen}>
            <div className="dialogbox show"  >
                <div className="modal-dialog" >
                    <div className="modal-content card-border">
                        <div className="modal-icon">
                            <ion-icon name="checkmark-circle"></ion-icon>
                        </div>
                        <div className="modal-body mt-2">
                            Please check your email to verify your account
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <Link className="btn" to={PATH_LOGIN}>Sign In</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </Modal>
    )
}

export default SignupDialog;
