import {formatStripeAmount} from "../Utils";

const ProductItem = ({product, onClick}) => {
    const {externalProductId, images, name, description, unitAmount} = product
    let imageUrl = '';
    if (images && images.length > 0) {
        imageUrl = images[0];
    }
    return (
        <li key={externalProductId} className="items-card card card-border">
            <a onClick={() => onClick(product)} className="item">
                {imageUrl && <img src={imageUrl} alt="image" className="image"/>}
                <div className="in">
                    <div>
                        <h4>{name}</h4>
                        <p>{description}</p>
                        {/* item price quantity */}
                        <div className="item-price-qnt justify-content-between mt-1">
                            <h5>{formatStripeAmount(unitAmount)}</h5>
                        </div>
                    </div>
                </div>
            </a>
        </li>
    )
}

export default ProductItem;
