import {useEffect, useState} from 'react';
import {v1 as uuidv1} from "uuid";
import _ from 'lodash';
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {useItemStore, usePosStore} from "../../Store";
import {Link, useNavigate} from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {
    MENUS,
    ORDER_TYPE_DELIVERY,
    ORDER_TYPE_DINE_IN,
    ORDER_TYPE_TAKEAWAY,
    PATH_HOME,
    PATH_POS_CARD,
    PATH_POS_CASH,
    PATH_POS_CLIENT,
    PATH_POS_DISCOUNT,
    PATH_POS_ITEM,
    PATH_POS_SHARE_QR,
    PATH_POS_TABLE,
    PATH_POS_TIP,
} from "../../constants";
import {calculateGrandTotal, cleanObj, formatNumber, replaceItem, showErrorToast, showSuccessToast} from "../../Utils";
import {getUser} from "../../DB";
import {useObserver} from "mobx-react-lite";
import {parkSale} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {DEFAULT_SALE} from "../../Store/PosStore";
import PrintView from "../PrintView";
import SideMenu from "../../Components/SideMenu";


const POSCheckout = () => {
    const posStore = usePosStore();
    const itemStore = useItemStore();
    const navigate = useNavigate();
    const saleItems = posStore.saleItems;

    if (posStore.checks.length === 0) {
        const id = uuidv1();
        posStore.setSelectedCheck(id);
        posStore.saveChecks([{id, ...DEFAULT_SALE, ...posStore.sale, saleItems: cleanObj(saleItems)}]);
    }
    const [isSplitEqual, setIsSplitEqual] = useState(() => posStore.isSplitEqual);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const splitChecks = () => {
        if (isSplitEqual) {
            if (posStore.checks.length > 1) {
                const divider = posStore.checks.length;
                const newChecks = posStore.checks.map(check => {
                    return {
                        ...check,
                        amount: posStore.sale.amount / divider,
                        netTotal: posStore.sale.netTotal / divider,
                        saleItems: posStore.saleItems.map(item => {
                            return {
                                ...item,
                                total: item.total / divider,
                                originalSellingPrice: item.total,
                                taxes: item.taxes.map(tax => ({...tax, taxAmount: tax.taxAmount / divider})),
                                isSplitItem: true,
                            }
                        })
                    }
                });
                posStore.saveChecks(newChecks);
            }
        }
    }

    useEffect(() => {
        posStore.setIsSplitEqual(isSplitEqual);
        splitChecks();
    }, [isSplitEqual])

    const handleAddCheck = () => {
        posStore.addCheck();
        splitChecks();
    }

    const handleCheckChange = (id) => () => {
        posStore.setSelectedCheck(id);
    };

    const handleOnlineOrderType = (type) => () => {
        posStore.setOnlineOrderType(type);
    }

    const handleParkedClick = async (openQRCode = false) => {
        const saleDetails = posStore.saleItems.map(item => {
            if (item.isCustomItem == 1) {
                return _.omit(item, 'itemId');
            }
            return item;
        });
        if (saleDetails.length > 0) {
            const saleReq = {..._.omit(posStore.sale, 'saleItems'), saleDetails, shareQR: openQRCode};
            saleReq.grandTotal = calculateGrandTotal(saleReq.amount, saleReq);
            setLoading(true);
            const {data, code, error, message} = await parkSale(saleReq);
            if (code === 200) {
                showSuccessToast(message);
                posStore.clearStore();
                if (openQRCode) {
                    const invoiceId = data.invoiceId;
                    navigate(PATH_POS_SHARE_QR, {state: {invoiceId}});
                } else {
                    navigate(-1);
                }

            } else {
                setLoading(false);
                setError(error);
            }
        } else {
            showErrorToast('Cart is empty!');
        }

    }

    const handleShareClick = () => {
        handleParkedClick(true);
    }

    const handleDeleteSale = () => {
        posStore.clearStore();
        navigate(-1);
    }

    const handleQuantityChange = ({id, isCustomItem}, addItem) => () => {
        const item = _.flatMap(itemStore.getAllItems(), 'items').find(item => item.itemid == id);
        if (!isCustomItem && (item.hasvariations || item.hasmodifier)) {
            navigate(PATH_POS_ITEM, {state: {item: cleanObj(item)}});
        } else {
            const tempChecks = [...posStore.checks];
            const checkIndex = tempChecks.findIndex(c => c.id == posStore.selectedCheck);
            const tempCheck = tempChecks[checkIndex];
            if (addItem) {
                const saleItem = posStore.saleItems.find(item => item.itemId === id);
                const newSaleItem = {...saleItem, id: uuidv1()};
                posStore.addItemToCart(newSaleItem, false);
                tempCheck.saleItems.push(newSaleItem);
            } else {
                posStore.removeItemFromCart(id, false);
                const itemIndex = _.findLastIndex(tempChecks[checkIndex].saleItems, (i => i.itemId == id));
                if (itemIndex >= 0) {
                    tempCheck.saleItems.splice(itemIndex, 1);
                }
            }
            tempCheck.amount = tempCheck.saleItems.reduce((total, curr) => total + curr.total, 0);
            const totalTax = _.flatMap(tempCheck.saleItems, 'taxes')
                .filter(item => !_.isEmpty(item))
                .reduce((total, curr) => total + curr.taxAmount, 0);
            tempCheck.netTotal = tempCheck.amount - totalTax;
            tempChecks[checkIndex] = tempCheck;
            posStore.saveChecks(tempChecks);
            splitChecks()
        }
    }

    const getPopover = (itemId) => {
        if (posStore.checks.length === 1) {
            return <></>;
        }
        const handleClick = (check) => () => {
            if (posStore.isSplitEqual) {
                posStore.setIsSplitEqual(false);
                posStore.clearChecks();
            } else {
                let checks = _.cloneDeep(posStore.checks);
                const toCheck = _.cloneDeep(check)
                const fromCheck = _.cloneDeep(posStore.getSelectedCheck());
                const itemsAfterRemove = fromCheck.saleItems.filter(item => item.itemId != itemId);
                const itemsToAdd = fromCheck.saleItems.filter(item => item.itemId == itemId);
                fromCheck.saleItems = itemsAfterRemove;
                fromCheck.amount = fromCheck.saleItems.reduce((total, current) => total + current.total, 0);
                fromCheck.netamount = fromCheck.amount - _.flatMap(fromCheck.saleItems, 'taxes').reduce((total, current) => total + current.taxAmount, 0);
                toCheck.saleItems = toCheck.saleItems.concat(itemsToAdd);
                toCheck.amount = toCheck.saleItems.reduce((total, current) => total + current.total, 0);
                toCheck.netamount = toCheck.amount - _.flatMap(toCheck.saleItems, 'taxes').reduce((total, current) => total + current.taxAmount, 0);
                checks = replaceItem(checks, fromCheck, 'id');
                checks = replaceItem(checks, toCheck, 'id');
                posStore.saveChecks(checks);
            }
        }

        const menus = posStore.checks
            .map((check, index) => ({...check, no: index + 1}))
            .filter(check => (check.id != posStore.selectedCheck))
            .map((check) => (
                <Popover.Header key={check.no} onClick={handleClick(check)}>Check # {check.no}</Popover.Header>));

        const popover = (
            <Popover id="popover-basic">
                {menus}
            </Popover>
        );
        return popover;
    }

    const handleCashClick = () => {
        const selectedCheck = posStore.getSelectedCheck();
        if (selectedCheck.saleItems.length > 0) {
            navigate(PATH_POS_CASH);
        } else {
            showErrorToast('Check is empty!')
        }
    }

    const handleCardClick = () => {
        const selectedCheck = posStore.getSelectedCheck();
        if (selectedCheck.saleItems.length > 0) {
            navigate(PATH_POS_CARD);
        } else {
            showErrorToast('Check is empty!')
        }
    }

    const staffName = getUser().name;

    const handlePrint = () => {
        window.print();
    }

    return useObserver(() => {
        const selectedCheck = posStore.getSelectedCheck();
        const groupedItems = _.flatMap(_.mapValues(_.groupBy(selectedCheck.saleItems, 'itemId'),
            (item => ({
                    id: item[0].itemId,
                    name: item[0].itemName,
                    imageUrl: item[0].imageUrl,
                    description: item[0].description,
                    isCustomItem: item[0].isCustomItem,
                    taxes: item[0].taxes,
                    total: item.reduce((total, current) => total + current.total, 0),
                    quantity: item.length,
                })
            )), (item) => item);
        const amount = groupedItems.reduce((total, current) => total + current.total, 0);

        const groupedTax = _.flatMap(_.mapValues(_.groupBy(_.flatMap(selectedCheck.saleItems, 'taxes').filter(item => !_.isEmpty(item)), 'taxId'), tax => ({
            value: tax[0].value,
            name: tax[0].name,
            taxAmount: tax.reduce((total, curr) => total + parseFloat(curr.taxAmount), 0)
        })));

        const taxAmount = groupedTax.reduce((total, curr) => total + curr.taxAmount, 0);

        const netTotal = amount - taxAmount;

        const {tipAmount, discountAmount, onlineOrderType} = selectedCheck;
        const grandTotal = calculateGrandTotal(amount, selectedCheck);

        const {tableName} = posStore.table;
        const {accountName} = posStore.client;
        return (
            <>
                {loading && <Loader/>}
                {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
                <PrintView sale={selectedCheck}/>
                <Header hasBack backURL={PATH_HOME} title={"Checkout"} onShareClick={handleShareClick}/>
                <SideMenu selected={MENUS.POS}/>
                <div id="appCapsule">
                    {/* Category */}
                    <div className="section">
                        <ul id="categories" className="categories pt-1 pb-1 pe-4">
                            {
                                posStore.checks.map((check, index) => (
                                    <li key={check.id}
                                        className={`category ${check.id === posStore.selectedCheck ? 'category-active' : ''}`}>
                                        <a onClick={handleCheckChange(check.id)}>Check #{index + 1}</a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    {/* * Category */}
                    <div className="section checkout-list-items">
                        {/* Item */}
                        <ul className="listview separate-list staff-filter-items filter-items image-listview inset no-line no-arrow mx-0">
                            <li className="items-card card card-border" data-item="check1">
                                {
                                    groupedItems.map(item => {
                                        return (
                                            <div className="item" key={`${item.id}-${item.quantity}`}>
                                                {item.imageUrl &&
                                                    <img src={item.imageUrl} alt="image" className="image"/>}
                                                <OverlayTrigger trigger="click" rootClose placement="bottom-end"
                                                                overlay={getPopover(item.id)}>
                                                    <div className="in">
                                                        <div>
                                                            <h4>{item.name}</h4>
                                                            <p>{item.description}</p>
                                                            {/* item price quantity */}
                                                            <div className="item-price-qnt mt-1">
                                                                <h5>{formatNumber(item.total)}</h5>
                                                                <div className="qnt-incre-decre">
                                                                    <div className="qnt-incre-decre-bg"/>
                                                                    <div className="delete-chekout-list-item"
                                                                         onClick={handleQuantityChange(item, false)}
                                                                         id="qntDecrease">
                                                                        <ion-icon name="remove-circle"/>
                                                                    </div>
                                                                    <input type="text" defaultValue={item.quantity}/>
                                                                    <div id="qntIncrease"
                                                                         onClick={handleQuantityChange(item, true)}>
                                                                        <ion-icon name="add-circle"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        )
                                    })
                                }
                            </li>
                        </ul>
                        {/* * Item */}
                    </div>
                    {/* Summary */}
                    <div className="section mt-2">
                        <div className="grand-total-count">
                            <div className="card card-border">
                                <div className="card-body">
                                    <div className="single-data">
                                        <h3>Net Total</h3>
                                        <h3>{formatNumber(netTotal)}</h3>
                                    </div>
                                    <div className="single-data">
                                        <h4>Discount</h4>
                                        <Link to={PATH_POS_DISCOUNT}>{discountAmount || 'Add'}</Link>
                                    </div>
                                    <div className="single-data">
                                        <h4>Tip</h4>
                                        <Link to={PATH_POS_TIP}>{tipAmount || 'Add'}</Link>
                                    </div>
                                    {
                                        groupedTax.map((tax, index) => (
                                            <div className="single-data" key={index}>
                                                <h4>{tax.name} ({tax.value}%)</h4>
                                                <p>{formatNumber(tax.taxAmount)}</p>
                                            </div>
                                        ))
                                    }
                                    <div className="single-data">
                                        <h3>Grand Total</h3>
                                        <h3>{formatNumber(grandTotal)}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-border mt-2">
                                <div className="card-body">
                                    <div className="single-data">
                                        <h4>Payment Status</h4>
                                        <p className="text-danger">Not Paid</p>
                                    </div>
                                    <div className="single-data">
                                        <h4>Table</h4>
                                        <Link to={PATH_POS_TABLE} id="sectionTable">{tableName || 'Add'}</Link>
                                    </div>
                                    <div className="single-data">
                                        <h4>Check</h4>
                                        <a onClick={handleAddCheck} id="CheckNum">Add</a>
                                    </div>
                                    <div className="single-data">
                                        <h4>Staff</h4>
                                        <a>{staffName}</a>
                                    </div>
                                    <div className="single-data">
                                        <h4>Client</h4>
                                        <Link to={PATH_POS_CLIENT}>{accountName || 'Add'}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* * Summary */}
                    {/* Extra */}
                    <div className="section rcv-variation-button mt-2">
                        <div className="btn-group" role="group">
                            <input type="radio" className="btn-check " name="btnradioGroup1" id="DineIn"
                                   onChange={handleOnlineOrderType(ORDER_TYPE_DINE_IN)}
                                   checked={onlineOrderType === ORDER_TYPE_DINE_IN}
                            />
                            <label className="btn btn-outline-primary" htmlFor="DineIn">Dine In</label>
                            <input type="radio" className="btn-check " name="btnradioGroup1" id="takeAway"
                                   onChange={handleOnlineOrderType(ORDER_TYPE_TAKEAWAY)}
                                   checked={onlineOrderType === ORDER_TYPE_TAKEAWAY}
                            />
                            <label className="btn btn-outline-primary" htmlFor="takeAway">Take Away</label>
                            <input type="radio" className="btn-check " name="btnradioGroup1" id="delivery"
                                   onChange={handleOnlineOrderType(ORDER_TYPE_DELIVERY)}
                                   checked={onlineOrderType === ORDER_TYPE_DELIVERY}
                            />
                            <label className="btn btn-outline-primary" htmlFor="delivery">Delivery</label>
                        </div>
                    </div>
                    {/* * Extra */}
                    {/* Buttons */}
                    <div className="section mt-2">
                        <div className="row">
                            <div className="col-4 mt-2">
                                <a onClick={() => handleParkedClick()}
                                   className="btn btn-primary btn-shadow btn-lg btn-block">Park</a>
                            </div>
                            <div className="col-4 mt-2">
                                <a onClick={handleCashClick}
                                   className="btn btn-primary btn-shadow btn-lg btn-block">Cash</a>
                            </div>
                            <div className="col-4 mt-2">
                                <a onClick={handleCardClick}
                                   className="btn btn-primary btn-shadow btn-lg btn-block">Card</a>
                            </div>
                            <div className="col-4 mt-2">
                                <a onClick={handleDeleteSale} className="btn btn-danger btn-shadow btn-lg btn-block">Delete
                                    Sale</a>
                            </div>
                            <div className="col-4 mt-2">
                                <a onClick={() => setIsSplitEqual(true)}
                                   className="btn btn-primary btn-shadow btn-lg btn-block">Split Equally</a>
                            </div>
                            <div className="col-4 mt-2">
                                <a onClick={handlePrint}
                                   className="btn btn-primary btn-shadow btn-lg btn-block">Print</a>
                            </div>
                        </div>
                    </div>
                    {/* * Buttons */}
                </div>
            </>
        )
    });
}

export default withUserAuth(POSCheckout)
