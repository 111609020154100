import {useState} from 'react';
import {useLocation, useNavigate} from "react-router";
import _ from 'lodash';
import Header from "../../Components/Header";
import {useItemStore} from "../../Store";
import SectionsDialog from "../../Components/SectionsDialog";
import TaxesDialog from "../../Components/TaxesDialog";
import {addCategory, updateCategory} from "../../API";
import ErrorDialog from "../../Components/ErrorDialog";
import Loader from "../../Components/Loader";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";
import withUserAuth from "../../Behavior/withUserAuth";

const Category = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const itemStore = useItemStore();
    const category = location.state?.category;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isShowSections, setShowSections] = useState(false);
    const [isShowTaxes, setShowTaxes] = useState(false);
    const [name, setName] = useState(() => {
        if (category) {
            return category.name;
        }
        return '';
    })
    const [notifySection, setNotifySection] = useState(() => {
        if (category) {
            const section = itemStore.getSections().find(sec => sec.kitchenId === category.kitchenID);
            return section || {};
        }
        return {};
    });
    const [selectedTaxes, setSelectedTaxes] = useState(() => {
        if (category && category.taxes) {
            return category.taxes.map(id => itemStore.getTaxes().find(tax => tax.companyTaxId === id)).filter(tax => !!tax);
        }
        return [];
    });
    // const [includeTax, setIncludeTax] = useState(false);

    const handleSectionChange = (section) => {
        setNotifySection(section);
        setShowSections(false);
    }

    const handleTaxChange = (isChecked, tax) => {
        if (isChecked) {
            setSelectedTaxes([...selectedTaxes, tax]);
        } else {
            setSelectedTaxes(_.remove(selectedTaxes, (t => t.companyTaxId !== tax.companyTaxId)));
        }
    }

    const handleDeleteTax = (tax) => {
        setSelectedTaxes(_.remove(selectedTaxes, (t => t.companyTaxId !== tax.companyTaxId)));
    }

    const handleSave = async () => {
        setLoading(true)
        const kitchenId = notifySection?.kitchenId;
        const taxes = selectedTaxes.map(({companyTaxId}) => companyTaxId);
        let res;
        if (category) {
            res = await updateCategory({categoryid: category.categoryID, name, kitchenId, taxes, tax: 10})
        } else {
            res = await addCategory({name, kitchenId, taxes})
        }
        if (res.code === 200) {
            navigate(-1);
        } else {
            setLoading(false);
            setError(res.error);
        }
    }
    console.log('Error', error);
    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}}
            {<SectionsDialog isOpen={isShowSections} sections={itemStore.getSections()} selectedSection={notifySection}
                             onSelectionChange={handleSectionChange} onClose={() => setShowSections(false)}/>}
            {<TaxesDialog isOpen={isShowTaxes} taxes={itemStore.getTaxes()} selectedTaxes={selectedTaxes}
                          onSelectionChange={handleTaxChange} onClose={() => setShowTaxes(false)}/>}
            <Header title={"Category"} hasBack/>
            <SideMenu selected={MENUS.ITEMS}/>
            <div id="appCapsule">
                {/* Items */}
                <div className="section item-amend-category mt-2">
                    {/* Set Name */}
                    <div className="card card-border">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="categoryName">Category Name</label>
                                        <input type="text" className="form-control" id="categoryName" value={name}
                                               maxLength={200}
                                               onChange={(e) => setName(e.target.value)}/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"/>
                                        </i>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* * Set Name */}
                    {/* details */}
                    {/*<ul className="listview tax-switcher transparent darkmode-transparent flush mt-4">*/}
                    {/*    <li>*/}
                    {/*        <div className="include-tax">*/}
                    {/*            <div>Include Tax in Category Price</div>*/}
                    {/*            <div className="form-check form-switch">*/}
                    {/*                <input className="form-check-input" type="checkbox" checked={includeTax} onChange={(e) => setIncludeTax(e.target.checked)} id="SwitchTax"/>*/}
                    {/*                <label className="form-check-label" htmlFor="SwitchTax" id="itemsSwitchToggler"/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                    <div className="tax-wrapper">
                        <div className="section-title">Tax</div>
                        <ul className="listview image-listview separate-list inset no-line no-arrow m-0">
                            {
                                selectedTaxes.map(tax => (
                                    <li key={tax.companyTaxId}>
                                        <a className="item">
                                            <div className="in">
                                                <div>{tax.name}</div>
                                                <span>{tax.tax}%</span>
                                            </div>
                                        </a>
                                        <span className="delete-item py-3 ps-1 pe-3"
                                              onClick={() => handleDeleteTax(tax)}>
                                                <ion-icon name="close"/>
                                            </span>
                                        <div className="delete-modal"/>
                                    </li>
                                ))
                            }
                        </ul>
                        <a onClick={() => setShowTaxes(true)} className="btn add-tag-anchor btn-primary mt-1">
                                <span>
                                    <ion-icon name="add-circle"/>
                                </span>
                            Add Tax
                        </a>
                    </div>
                    <div className="section-title mt-3">Notify Section</div>
                    {
                        !_.isEmpty(notifySection) &&
                        <ul className="listview image-listview separate-list inset no-line no-arrow m-0">
                            <li>
                                <a className="item">
                                    <div className="in">
                                        <div>{notifySection.name}</div>
                                    </div>
                                </a>
                                <span onClick={() => setNotifySection({})} className="delete-item py-3 ps-1 pe-3">
                                    <ion-icon name="close"/>
                                </span>
                            </li>
                        </ul>
                    }
                    <a onClick={() => setShowSections(true)} className="btn add-tag-anchor btn-primary mt-1">
                        <span>
                            <ion-icon name="add-circle"/>
                        </span>
                        {_.isEmpty(notifySection) ? 'Add' : 'Change'} Section
                    </a>
                    <div className="mt-4">
                        <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Save</a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default withUserAuth(Category);
