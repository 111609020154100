import Modal from 'react-modal';
import {useState} from "react";
import {useItemStore} from "../Store";
import {calculateRetailPrice, calculateTaxAmount} from "../Utils";
import {v1 as uuidv1} from "uuid";

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0, 0.5)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'none',
        inset: 'none',
        borderRadius: 'none',
        border: 0,
        background: 'none',
    },
};

const ModifierDialog = ({isOpen, categoryId, onClose, onSave}) => {
    const itemStore = useItemStore();
    const allCategories = itemStore.getAllItems();

    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');
    const [category, setCategory] = useState(() => {
        if (categoryId) return allCategories.find(cat => cat.categoryID === categoryId);
        return {};
    });

    const handleSave = () => {
        const taxesRate = itemStore.getTaxes().filter(t => category.taxes.includes(t.companyTaxId));
        const totalTaxRate = taxesRate.reduce((total, curr) => (curr.tax + total), 0);
        const retailPrice = calculateRetailPrice(amount, totalTaxRate);
        const customItem = {
            id: uuidv1(),
            itemId: 0,
            itemName: name,
            isCustomItem: 1,
            quantity: 1,
            hasvariations: false,
            total: parseFloat(amount),
            taxes: taxesRate.map(tax => ({
                taxId: tax.companyTaxId,
                name: tax.name,
                value: tax.tax,
                taxAmount: parseFloat(calculateTaxAmount(retailPrice, tax.tax)),
            })),
        }
        onSave(customItem);
    }

    return (
        <Modal
            style={customStyles}
            isOpen={isOpen}>
            <div className="dialogbox show">
                <div className="modal-dialog">
                    <div className="modal-content card-border">
                        <div className="modal-icon" style={{fontSize: '18px', marginBottom: 0, marginTop: '16px'}}>
                            Custom Item
                        </div>
                        <div className="modal-body listing mt-2">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="itemName">
                                            Item Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="itemName"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="price">
                                            Amount
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="price"
                                            value={amount}
                                            onChange={e => setAmount(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="selectCategory">
                                            Category
                                        </label>
                                        <select
                                            className="form-control custom-select"
                                            id="selectCategory"
                                            value={category.categoryid}
                                            onChange={(e) => {
                                                const _category = allCategories.find(cat => cat.categoryID == e.target.value);
                                                setCategory(_category);
                                            }}
                                        >
                                            {
                                                allCategories.map(cat => <option key={cat.categoryID}
                                                                                 value={cat.categoryID}>{cat.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <a onClick={onClose} className="btn">Close</a>
                                <a onClick={handleSave} className="btn">Save</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModifierDialog;
