import {useState} from "react";
import {useLocation, useNavigate} from "react-router";
import Header from "../../Components/Header";
import withUserAuth from "../../Behavior/withUserAuth";
import {addTable, deleteTable, updateTable} from "../../API";
import ErrorDialog from "../../Components/ErrorDialog";
import Loader from "../../Components/Loader";
import {MENUS} from "../../constants";
import SideMenu from "../../Components/SideMenu";

const Table = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const table = location?.state?.table;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [name, setName] = useState(() => table?.name || '');
    const [capacity, setCapacity] = useState(() => table?.capacity || '');

    const handleSave = async () => {
        let res;
        setLoading(true);
        if (table) {
            res = await updateTable({...table, name, capacity});
        } else {
            res = await addTable({name, capacity});
        }
        setLoading(false);
        if (res.code === 200) {
            navigate(-1);
        } else {
            setError(res.error);
        }
    }

    const handleDelete = async () => {
        const isConfirm = await window.confirm(`Are you sure you want to delete ${name}?`);
        if (isConfirm) {
            setLoading(true);
            const {tableId} = table;
            const {code, error} = await deleteTable({tableId})
            if (code === 200) {
                navigate(-1);
            } else {
                setLoading(false);
                setError(error);
            }
        }
    }

    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header title={"Table"} hasBack/>
            <SideMenu selected={MENUS.SETTINGS}/>
            <div id="appCapsule">
                <div className="section item-amend-category mt-2">
                    {/* Set Name */}
                    <div className="card card-border">
                        <div className="card-body">
                            <form>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="name">Table Name</label>
                                        <input type="text" value={name} onChange={(e) => setName(e.target.value)}
                                               className="form-control" id="name"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"/>
                                        </i>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label" htmlFor="capacity">Table Capacity</label>
                                        <input type="number" value={capacity}
                                               onChange={(e) => setCapacity(e.target.value)}
                                               className="form-control" id="capacity"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"/>
                                        </i>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* * Set Name */}
                    <div className="mt-4">
                        <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Save</a>
                        {
                            !!table?.tableId && <a onClick={handleDelete}
                                                   className="btn btn-danger btn-shadow btn-lg btn-block mt-2">Delete</a>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default withUserAuth(Table);
