import axios from 'axios';
import {getAuthToken, getDeviceId, getUser} from "../DB";
import {cleanObj} from "../Utils";
import {STATUS_COMPLETED, STATUS_PARKED, TYPE_ID_SALE} from "../constants";

// const BASE_URL = 'http://my.justswype.com/api';

//export const SERVER_URL = 'https://localhost:8001'
export const SERVER_URL = 'https://rapedidoapp-stage.azurewebsites.net'

const BASE_URL = `${SERVER_URL}/api`;

export const getInstance = (Authorization, responseType) => {
    return axios.create({
        baseURL: BASE_URL,
        headers: {
            'Access-Control-Allow-Origin': '*',
            ...(Authorization ? {Authorization} : {}),
        },
        ...(responseType ? {responseType} : {}),
    });
}

const handleResponse = ({ data }) => {
    if (data) {
        if (data.status === 0) {
            return {code: 400, error: data.message};
        } else {
            return {code: 200, data: data.payload, message: data.message};
        }
    }
}

const handleErrorResponse = ({ request, response, message }) => {
    if (response) {
        return { code: response.status, error: response.data.title || response.statusText };
    } else if (request) {
        return { code: 500, error: 'Unable to send request. Try again later!'};
    } else {
        return { code: 500, error: message};
    }
}

const sendGetRequest = async (url, params, responseType) => {
    try {
        const res = await getInstance(getAuthToken(), responseType).get(url, {params});
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}

const sendPostRequest = async (url, data) => {
    const requestedBy = getUser().userid;
    if (!!requestedBy) {
        data = {...data, requestedBy};
    }
    try {
        const res = await getInstance(getAuthToken()).post(url, data);
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}

const sendFileUploadRequest = async (url, file) => {
    try {
        const fd = new FormData();
        fd.append('image', file, file.name);
        const res = await getInstance(getAuthToken()).post(url, fd);
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}

export const loginWithToken = async (token) => {
    try {
        const res = await getInstance(token).post('auth/loginwithtoken');
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}

export const loginUser = async (username, password) => {
    try {
        const res = await getInstance().post('auth/login', {username, password, deviceid: getDeviceId()});
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}

export const signupUser = async ({name, email, password}) => {
    try {
        const res = await getInstance().post('auth/SignUp', {
            name,
            email,
            password,
            country: 'United States',
            deviceid: getDeviceId()
        });
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}

export const forgotPassword = async ({email}) => {
    try {
        const res = await getInstance().post('auth/ForgetPassword', {email, deviceid: getDeviceId()});
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}

export const firstSetupDetail = async (params) => {
    return sendPostRequest('auth/FirstSetupDetails', params);
}

export const getStoreTypes = async () => {
    return sendGetRequest('misc/GetStoreTypes');
}

export const getAllItems = async () => {
    return sendGetRequest('/item/GetAll');
}

export const updateItemOrder = async (params) => {
    return sendPostRequest('Item/ItemChangeOrder', params);
}

export const getAllSections = async () => {
    return sendGetRequest('Kitchen/GetAll');
}

export const addSection = async (section) => {
    return sendPostRequest('Kitchen/Add', section);
}

export const updateSection = async (section) => {
    return sendPostRequest('Kitchen/Update', section);
}

export const deleteSection = async (section) => {
    return sendPostRequest('Kitchen/Remove', section);
}

export const getAllTaxes = async () => {
    return sendGetRequest('/item/TaxGetAll');
}

export const addTax = async (tax) => {
    return sendPostRequest('Item/TaxAdd', tax);
}

export const updateTax = async (tax) => {
    return sendPostRequest('Item/TaxUpdate', tax);
}

export const deleteTax = async (tax) => {
    return sendPostRequest('Item/TaxRemove', tax);
}

export const addCategory = async (category) => {
    return sendPostRequest('Item/CategoryAdd', category);
}

export const updateCategory = async (category) => {
    return sendPostRequest('Item/CategoryUpdate', category);
}

export const deleteCategory = async (category) => {
    return sendPostRequest('Item/CategoryRemove', category);
}

export const getAllModifiers = async () => {
    return sendGetRequest('Item/ModifierGetAll');
}

export const addModifier = async (modifier) => {
    return sendPostRequest('Item/ModifierAdd', modifier);
}

export const updateModifier = async (modifier) => {
    return sendPostRequest('Item/ModifierUpdate', modifier);
}

export const deleteModifier = async (modifier) => {
    return sendPostRequest('Item/ModifierRemove', modifier);
}

export const getAllUnits = async () => {
    return sendGetRequest('misc/GetUnits');
}

export const uploadFile = async (file) => {
    return sendFileUploadRequest('misc/PostUserImage', file);
}

export const addItem = async (data) => {
    return sendPostRequest('Item/ItemAdd', data);
}

export const updateItem = async (data) => {
    return sendPostRequest('Item/ItemUpdate', data);
}

export const deleteItem = async (data) => {
    return sendPostRequest('Item/ItemRemove', data);
}

export const getAllProducts = async (searchstring) => {
    return sendGetRequest('ProductStripe/GetList', {searchstring})
}

export const addProduct = async (data) => {
    return sendPostRequest('ProductStripe/Add', data);
}

export const updateProduct = async (data) => {
    return sendPostRequest('ProductStripe/update', data);
}

export const addInvoice = async (params) => {
    return sendPostRequest('InvoiceStripe/AddInvoice', params);
}

export const updateInvoiceDescription = async (params) => {
    return sendPostRequest('InvoiceStripe/UpdateInvoiceDescription', params);
}

export const invoiceAddLine = async (params) => {
    return sendPostRequest('InvoiceStripe/AddLine', params);
}

export const invoiceUpdateLine = async (params) => {
    return sendPostRequest('InvoiceStripe/UpdateLine', params);
}

export const finalizeInvoice = async (params) => {
    return sendPostRequest('InvoiceStripe/FinalizeInvoice', params);
}

export const getInvoiceImage = async (params) => {
    return sendGetRequest('InvoiceStripe/getInvoiceImage', params);
}

export const getInvoice = async (params) => {
    return sendGetRequest('InvoiceStripe/getInvoice', params);
}

export const sendStripeInvoice = async (params) => {
    return sendPostRequest('InvoiceStripe/SendInvoice', params);
}

export const voidInvoice = async (params) => {
    return sendPostRequest('InvoiceStripe/VoidInvoice', params);
}

export const removeDraftInvoice = async (params) => {
    return sendPostRequest('InvoiceStripe/RemoveDraftInvoice', params);
}

export const invoiceRemoveLine = async (params) => {
    return sendPostRequest('InvoiceStripe/RemoveLine', params);
}

export const getAllClients = async (params) => {
    return sendGetRequest('Customer/GetList', params);
}

export const addClient = async (data) => {
    return sendPostRequest('Customer/Add', data);
}

export const updateClient = async (data) => {
    return sendPostRequest('Customer/Update', data);
}

export const deleteClient = async (data) => {
    return sendPostRequest('Customer/Remove', data);
}

export const getAllStripeClients = async (params, isStripeConnected) => {
    let endpoint = isStripeConnected ? 'CustomerStripe/' : 'Customer/';
    return sendGetRequest(endpoint + 'GetList', params);
}

export const addStripeClient = async (data, isStripeConnected) => {
    let endpoint = isStripeConnected ? 'CustomerStripe/' : 'Customer/';
    return sendPostRequest(endpoint + 'Add', data);
}

export const updateStripeClient = async (data, isStripeConnected) => {
    let endpoint = isStripeConnected ? 'CustomerStripe/' : 'Customer/';
    return sendPostRequest(endpoint + 'Update', data);
}

export const deleteStripeClient = async (data, isStripeConnected) => {
    let endpoint = isStripeConnected ? 'CustomerStripe/' : 'Customer/';
    return sendPostRequest(endpoint + 'Remove', data);
}

export const getAllStaffs = async (params) => {
    return sendGetRequest('Staff/GetAll', params);
}

export const getAllStaffById = async (params) => {
    return sendGetRequest('Staff/GetById', params);
}

export const switchStaff = async (userid) => {
    return sendGetRequest('auth/SwitchUser', {userid});
}

export const getCompanyDetails = async (params) => {
    return sendGetRequest('auth/GetCompanyDetails', params);
}

export const updateOrgDetails = async (data) => {
    return sendPostRequest('auth/UpdateOrgInfo', data);
}


export const addStaff = async (data) => {
    return sendPostRequest('Staff/Add', data);
}

export const updateStaff = async (data) => {
    return sendPostRequest('Staff/Update', data);
}

export const updateStaffDetail = async (data) => {
    return sendPostRequest('Staff/UpdateAccDetail', data);
}

export const deleteStaff = async (data) => {
    return sendPostRequest('Staff/Remove', data);
}

export const getParkedList = async (data) => {
    const params = {...data, listtype: 1, pagesize: 10};
    return sendGetRequest('Sale/GetList', params);
}

export const getReceipts = async (data) => {
    const params = {...data, listtype: 2, pagesize: 10};
    return sendGetRequest('Sale/GetList', params);
}

export const getInvoices = async (status = '', pagesize = '', lastid = '',) => {
    console.log('status', status);
    return sendGetRequest('InvoiceStripe/GetList', {status});
}

export const getOrders = async () => {
    const params = {listtype: 6};
    return sendGetRequest('Sale/GetOrderList', params);
}

export const getOrderDetail = async (invoiceid) => {
    const params = {invoiceid};
    return sendGetRequest('Sale/GetInvoiceById', params);
}

export const updateOrderStatus = async (params) => {
    return sendGetRequest('Sale/UpdateOrderInvoiceStatus', params);
}

export const acceptOnlineOrder = async (invoiceId) => {
    const params = {invoiceId};
    return sendGetRequest('Sale/GetList', params);
}

export const getReport = async (params) => {
    params = {dwm: 'custom', ...params};
    return sendGetRequest('ReportsAPI/GetTransactionsData', params);
}

export const getStatusOfUserRegister = () => {
    return sendGetRequest('RegisterAPI/GetStatusOfUserRegister');
}

export const emailTransactionData = (params) => {
    params = {dwm: 'custom', ...params};
    return sendGetRequest('ReportsAPI/GetTransactionsExport', params);
}

export const getCashList = params => {
    return sendGetRequest('CashManagement/GetList', params);
}

export const getBalance = () => {
    return sendGetRequest('CashManagement/GetBalance');
}

export const addCashEntry = params => {
    return sendPostRequest('CashManagement/Add', params);
}

export const getTables = () => {
    return sendGetRequest('table/GetAll');
}

export const addTable = async (data) => {
    return sendPostRequest('table/add', data);
}

export const updateTable = async (data) => {
    return sendPostRequest('table/update', data);
}

export const deleteTable = async (data) => {
    return sendPostRequest('table/remove', data);
}

export const sendInvoice = async (params) => {
    return sendPostRequest('sale/SendInvoice', params);
}

export const refundSale = async (params) => {
    return sendPostRequest('Sale/Add', params);
}

export const parkSale = async (params) => {
    params = {typeid: TYPE_ID_SALE, status: STATUS_PARKED, ...params};
    return sendPostRequest('Sale/Add', params);
}

export const deleteSale = async (params) => {
    return sendPostRequest('Sale/CancelPark', params);
}

export const createSale = async (params) => {
    params = {typeid: TYPE_ID_SALE, status: STATUS_COMPLETED, ...params};
    console.log('Request', cleanObj(params));
    return sendPostRequest('Sale/Add', params);
}

export const getTerminalInfo = async (params) => {
    return sendGetRequest('StripeTerminal/GetTerminalReader', params);
}

export const setTerminalInfo = async (params) => {
    return sendPostRequest('StripeTerminal/RegisterReader', params);
}

export const getConnectionToken = async () => {
    return sendGetRequest('StripeTerminal/GetConnectionToken');
}

export const createPaymentIntent = async (params) => {
    return sendPostRequest('StripeTerminal/CreatePaymentIntent', params);
}

export const capturePaymentIntent = async (paymentId) => {
    return sendPostRequest(`StripeTerminal/CapturePaymentIntent?paymentIntentId=${paymentId}`);
}

export const shareQR = async (params) => {
    return sendPostRequest('Sale/ShareQR', params);
}

export const getConnectStatusOnline = async () => {
    return sendGetRequest('StripeConnect/GetConnectStatusOnline');
}

export const getConnectStatusInStore = async () => {
    return sendGetRequest('StripeConnect/GetConnectStatusInstore');
}

export const connectOnline = async (params) => {
    return sendPostRequest('StripeConnect/ConnectOnline', params);
}

export const connectStandardOAuth = async (params) => {
    return sendPostRequest('StripeConnect/ConnectStandardOAuth', params);
}

export const connectInStore = async (params) => {
    return sendPostRequest('StripeConnect/ConnectInstore', params);
}

export const createAndConnectInStore = async (params) => {
    return sendPostRequest('StripeConnect/CreateAndConnect', params);
}

export const disconnectOnline = async () => {
    return sendGetRequest('StripeConnect/DisconnectStatusOnline');
}

export const disconnectInStore = async () => {
    return sendGetRequest('StripeConnect/DisconnectStatusInstore');
}

export const getOrderAppDetails = async () => {
    return sendGetRequest('orderapp/GetDetails');
}

export const updateOrderAppDetails = async (params) => {
    return sendPostRequest('orderapp/UpdateDetails', params);
}

export const getDeliveryOptions = async () => {
    return sendGetRequest('auth/GetDeliveryOptions');
}

export const setDeliveryOptions = async (params) => {
    return sendPostRequest('auth/UpdateDeliveryOptions', params);
}

export const getQR = async () => {
    try {
        const res = await getInstance(getAuthToken(), 'arraybuffer').get('Sale/QRCode');
        return res;
    } catch (error) {
        return handleErrorResponse(error)
    }
}
