import withUserAuth from "../../Behavior/withUserAuth";
import {useEffect, useMemo, useState} from "react";
import {getOrders, updateOrderStatus} from "../../API";
import Header from "../../Components/Header";
import {useNavigate, useSearchParams} from "react-router-dom";
import {MENUS, PATH_ORDERS_DETAIL, PATH_ORDERS_QR, PATH_PARKED_DETAIL, PLACEHOLDER_AVATAR} from "../../constants";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import SideMenu from "../../Components/SideMenu";
import { event } from "jquery";

const Orders = ({onSidePanelOpen}) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [orders, setOrders] = useState([]);

    const sendAPIRequest = async () => {
        const res = await getOrders();
        if (res.code === 200) {
            setOrders(res.data.orderList)
        } else {
            setError(res.error);
        }
        setLoading(false);
    }

    useEffect(async () => {
        await sendAPIRequest();
    }, []);

    const type = parseInt(searchParams.get('type') || 6);

    const onTypeSelected = (type) => () => {
        const urlParams = new URLSearchParams(searchParams);
        urlParams.set('type', type);
        setSearchParams(urlParams);
    }

    const items = useMemo(() => type === 6 ? orders : orders.filter(order => order.orderStatus == type), [orders, type]);

    const handleOrderAction = (invoiceId, status) => async () => {
        setLoading(true);
        const res = await updateOrderStatus({invoiceId, status});
        if (res.code === 200) {
            await sendAPIRequest();
        } else {
            setLoading(false);
            setError(res.error)

        }
    }

    const handleProcessed = (invoiceId) => () => {
        navigate({pathname: PATH_PARKED_DETAIL, search: `id=${invoiceId}`});
    }
    const handleOrderProcessed = (invoiceId, isParked) => () => {
        if(isParked)
            navigate({pathname: PATH_PARKED_DETAIL, search: `id=${invoiceId}`});
        else 
            navigate({pathname: PATH_ORDERS_DETAIL, search: `id=${invoiceId}`});
    }

    const handleOrderQRCode = () => {
        navigate(PATH_ORDERS_QR);
    }
  
    const getStatusDiv= (orderStatus, isParked,status) =>
    {
        if(isParked)
             return <p><span className={"badge badge-primary invoice-status-draft"}>Parked</span></p>;
        else if(orderStatus == 0)
             return <p><span className={"badge badge-primary invoice-status-draft"}>{status}</span></p>;
        else if(orderStatus == 1)
             return <p><span className={"badge badge-primary invoice-status-paid"}>{status}</span></p>;
        else if(orderStatus == 2)
             return <p><span className={"badge badge-primary invoice-status-inprogress"}>{status}</span></p>;
        return <p className="order-number">&nbsp;</p>;

            //  {
            //     !isParked && orderStatus === 0 &&
            //     <button onClick={handleOrderAction(invoiceId, 2)}
            //             className="btn btn-secondary">Mark In Progress</button>
            // }
            // {
            //     !isParked && orderStatus === 2 &&
            //     <button onClick={handleOrderAction(invoiceId, 1)}
            //             className="btn btn-make-ready">Mark Ready</button>
            // }
            // {
            //     !isParked && orderStatus === 1 &&
            //     <button onClick={handleOrderAction(invoiceId, 3)}
            //             className="btn btn-ready">Complete</button>
            // }
    }
    return (
        <>
            {loading && <Loader/>}
            {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
            <Header title={"Orders"} onSidePanelClick={onSidePanelOpen} onQrCodeClick={handleOrderQRCode}/>
            <SideMenu selected={MENUS.ORDERS}/>
            <div id="appCapsule">
                {/* Category */}
                <ul id="categories" className="categories ps-2 py-1 pe-4">
                    <li className={`category ${type === 6 ? 'category-active' : ''}`}>
                        <a onClick={onTypeSelected(6)}>All</a>
                    </li>
                    <li className={`category ${type === 0 ? 'category-active' : ''}`}>
                        <a onClick={onTypeSelected(0)}>New</a>
                    </li>
                    <li className={`category ${type === 2 ? 'category-active' : ''}`}>
                        <a onClick={onTypeSelected(2)}>In Progress</a>
                    </li>
                    <li className={`category ${type === 1 ? 'category-active' : ''}`}>
                        <a onClick={onTypeSelected(1)}>Ready</a>
                    </li>
                </ul>
                {/* * Category */}
                {/* Order Item */}
                <ul className="listview order-filter-items filter-items image-listview inset">
                    {
                        items.map(({invoiceId, customerUrl, customerName, invoiceNumber, orderStatus, isParked, status, orderTime, orderDate}) => (
                            <li key={invoiceId} className="items-card card card-border" data-item="inProgress">
                                <div className="item">
                                    {/* <img src={customerUrl || PLACEHOLDER_AVATAR} alt="image" className="image"/> */}
                                    <div className="in" onClick={isParked && handleProcessed(invoiceId, event)}>
                                        <div className="order-detail-left" onClick={!isParked && handleOrderProcessed(invoiceId, isParked)}>
                                            <p className="order-number">{invoiceNumber}</p>
                                            {<p className="order-date">{orderDate}&nbsp;</p>}
                                            {<p className="order-date">{orderTime}&nbsp;</p>}
                                        </div>
                                        <div className="order-detail-right">
                                            {getStatusDiv(orderStatus, isParked, status)}
                                            <p className="order-customerName">&nbsp;{customerName && ("Client: " + customerName)}</p>
                                            <p>
                                            {
                                               isParked && <button 
                                                                className="btn btn-secondary order-action">Proceed</button>

                                            }
                                            {
                                                !isParked && orderStatus === 0 &&
                                                <button onClick={handleOrderAction(invoiceId, 2, event)}
                                                        className="btn btn-make-ready btn-secondary order-action">Mark In Progress</button>
                                            }
                                            {
                                                !isParked && orderStatus === 2 &&
                                                <button onClick={handleOrderAction(invoiceId, 1, event)}
                                                        className="btn btn-ready btn-secondary order-action">Mark Ready</button>
                                            }
                                            {
                                                !isParked && orderStatus === 1 &&
                                                <button onClick={handleOrderAction(invoiceId, 3, event)}
                                                        className="btn btn-secondary order-action">Mark Complete</button>
                                            }
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </li>
                        ))
                    }
                    {

                        items.length === 0 && <li>
                            <div className={"fontsize-heading"} style={{width: '100%', textAlign: 'center'}}>No Orders
                                Found
                            </div>
                        </li>
                    }
                </ul>
                {/* * Order Item */}
            </div>

        </>
    )
}
export default withUserAuth(Orders);
