// import {useState} from "react";
import {isUserLoggedIn} from "../DB";
import {Navigate} from "react-router-dom";
import {PATH_LOGIN} from "../constants";

const withUserAuth = (Component) => {

    const WrappedComponent = (props) => {
        if (!isUserLoggedIn()) {
            return <Navigate to={{pathname: PATH_LOGIN}}/>
        }
        return <Component {...props} />
    }
    return WrappedComponent;
}

export default withUserAuth;


