export const COUNTRIES = ["United Kingdom", "Afghanistan", "Albania", "Algeria", "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahrain", "Bangladesh", "Belarus", "Belgium", "Belize", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Cambodia", "Cameroon", "Canada", "Caribbean", "Chile", "China", "Colombia", "Congo (DRC)", "Costa Rica", "Côte d’Ivoire", "Croatia", "Cuba", "Czech Republic", "Denmark", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Eritrea", "Estonia", "Ethiopia", "Faroe Islands", "Finland", "France", "Georgia", "Germany", "Greece", "Greenland", "Guatemala", "Haiti", "Honduras", "Hong Kong SAR", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Korea", "Kuwait", "Kyrgyzstan", "Laos", "Latin America", "Latvia", "Lebanon", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macao SAR", "Macedonia, FYRO", "Malaysia", "Maldives", "Mali", "Malta", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Myanmar", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Nigeria", "Norway", "Oman", "Pakistan", "Panama", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Puerto Rico", "Qatar", "Réunion", "Romania", "Russia", "Rwanda", "Saudi Arabia", "Senegal", "Serbia", "Singapore", "Slovakia", "Slovenia", "Somalia", "South Africa", "Spain", "Sri Lanka", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Thailand", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Ukraine", "United Arab Emirates", "United States", "Uruguay", "Uzbekistan", "Venezuela", "Vietnam", "World", "Yemen", "Zimbabwe"];

export const PAYMENT_TYPES = {
    0: '',
    1: 'Cash',
    2: 'Card',
    7: 'Credit',
}

export const STAFF_TYPE = {
    0: 'All',
    1: 'Employed',
    2: 'Part Time',
    3: 'Prospect',
}

export const STAFF_ROLE = {
    1: 'Admin',
    2: 'Staff'
}

export const STAFF_GENDER = {
    0: 'Male',
    1: 'Female'
}

export const MENUS = {
    POS: 'POS',
    INVOICES: 'INVOICES',
    ORDERS: 'OREDERS',
    PRODUCTS: 'PRODUCTS',
    ITEMS: 'ITEMS',
    CLIENTS: 'CLIENTS',
    RECEIPTS: 'RECEIPTS',
    STAFF: 'STAFF',
    REPORTS: 'REPORTS',
    HELP: 'HELP',
    SETTINGS: 'SETTINGS',
}


export const TYPE_ID_SALE = 2;

export const STATUS_COMPLETED = 1;
export const STATUS_PARKED = 2;
export const STATUS_VOIDED = 3;


export const CASH_TYPE_PUT = 1;
export const CASH_TYPE_TAKE = 2;

export const TYPE_AMOUNT = 1;
export const TYPE_PERCENTAGE = 2;

export const ORDER_TYPE_TAKEAWAY = 1;
export const ORDER_TYPE_DELIVERY = 2;
export const ORDER_TYPE_DINE_IN = 3;

export const PAYMENT_CARD = 2;
export const PAYMENT_CASH = 1;
export const PAYMENT_CREDIT = 7;

export const SALE_TYPE_ID_RETURN = 3;

export const PLACEHOLDER_FOOD = '/img/food-placeholder.png'
export const PLACEHOLDER_CAMERA = '/img/camera-placeholder.svg';
export const PLACEHOLDER_AVATAR = '/img/avatar-placeholder.png';


export const INPUT_DATE_TIME_FORMAT = `yyyy-MM-ddTHH:mm:ss`
export const DISPLAY_DATE_FORMAT = `Do MMM, yyyy`
export const DISPLAY_TIME_FORMAT = `HH:mm`;
export const REPORT_DATE_FORMAT = `YYYYMMDD`;


export const PATH_HOME = '/';
export const PATH_LOGIN = '/login';
export const PATH_SIGNUP = '/signup';
export const PATH_FORGOT_PASSWORD = '/forgot-password';
export const PATH_SIGNUP_FIRST = '/signup-first';
export const PATH_SIGNUP_WHAT_DO_YOU_SELL = '/signup-what-do-you-sell';
export const PATH_SIGNUP_PAYMENT = '/signup-payment';
export const PATH_SIGNUP_STRIPE = '/signup-stripe';
export const PATH_ORGANIZATION_INFO = '/organization-info';
export const PATH_SELL = '/sell';
export const PATH_POS_ITEM = '/sell/item';
export const PATH_POS_DISCOUNT = '/sell/discount';
export const PATH_POS_TABLE = '/sell/table';
export const PATH_POS_TIP = '/sell/tip';
export const PATH_POS_CLIENT = '/sell/client';
export const PATH_POS_CHECKOUT = '/sell/checkout';
export const PATH_POS_CASH = '/sell/cash';
export const PATH_POS_CARD = '/sell/card';
export const PATH_POS_RECEIPT = '/sell/receipt';
export const PATH_POS_CUSTOM = '/sell/custom';
export const PATH_POS_EMAIL_RECEIPT = '/sell/email-receipt';
export const PATH_POS_SMS_RECEIPT = '/sell/sms-receipt';
export const PATH_POS_SHARE_QR = '/sell/share-qr';
export const PATH_INVOICES = '/invoices';
export const PATH_INVOICES_NEW = '/invoices/new';
export const PATH_INVOICES_CLIENT = '/invoices/client';
export const PATH_INVOICES_PRODUCT = '/invoices/product';
export const PATH_INVOICES_VIEW = '/invoices/view';
export const PATH_PRODUCTS = '/products';
export const PATH_PRODUCTS_PRODUCT = '/products/product';
export const PATH_ITEMS = '/items';
export const PATH_ITEM = '/items/item';
export const PATH_ITEM_VARIANT = 'items/variant';
export const PATH_ITEMS_CATEGORIES = '/items/categories';
export const PATH_ITEMS_CATEGORY = '/items/category';
export const PATH_ITEMS_ITEM = '/items/item';
export const PATH_SETTINGS = '/settings';
export const PATH_SETTINGS_SECTIONS = '/settings/sections';
export const PATH_SETTINGS_SECTION = '/settings/section';
export const PATH_SETTINGS_TABLES = '/settings/tables';
export const PATH_SETTINGS_TABLE = '/settings/table';
export const PATH_SETTINGS_TAXES = '/settings/taxes';
export const PATH_SETTINGS_TAX = '/settings/tax';
export const PATH_SETTINGS_MODIFIERS = '/settings/modifiers';
export const PATH_SETTINGS_MODIFIER = '/settings/modifier';
export const PATH_SETTINGS_CASH_LIST = '/settings/cash-list';
export const PATH_SETTINGS_CASH_EDIT = '/settings/cash-edit';
export const PATH_SETTINGS_PROFILE = '/settings/profile';
export const PATH_SETTINGS_ORG_INFO = '/settings/organization-info';
export const PATH_SETTINGS_HARDWARES = '/settings/hardwares';
export const PATH_SETTINGS_HARDWARE = '/settings/hardware';
export const PATH_SETTINGS_ACCEPT_PAYMENTS = '/settings/accepting-payments';
export const PATH_SETTINGS_IN_STORE_PAYMENTS = '/settings/in-store-payments';
export const PATH_SETTINGS_ONLINE_PAYMENTS = '/settings/online-payments';
export const PATH_SETTINGS_ORDER_APP = '/settings/order-app';
export const PATH_SETTINGS_DELIVERY = '/settings/delivery';
export const PATH_CLIENTS = '/clients';
export const PATH_CLIENTS_CLIENT = '/clients/client';
export const PATH_STAFFS = '/staffs';
export const PATH_STAFFS_STAFF = '/staffs/staff';
export const PATH_RECEIPTS = '/receipts';
export const PATH_RECEIPTS_RECEIPT = '/receipts/receipt';
export const PATH_RECEIPTS_REFUND = '/receipts/receipt/refund';
export const PATH_PARKED_LIST = '/parked';
export const PATH_PARKED_DETAIL = '/parked/detail';
export const PATH_ORDERS = '/orders';
export const PATH_ORDERS_DETAIL = '/orders/detail';
export const PATH_ORDERS_QR = '/orders/qr';
export const PATH_REPORT = '/report';
export const PATH_PRINT = '/print';

export const PATH_ = '';

// export const PATH_ = '';
// export const PATH_ = '';
// export const PATH_ = '';

