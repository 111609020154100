import {useEffect, useState} from 'react';
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {CASH_TYPE_PUT, CASH_TYPE_TAKE, MENUS} from "../../constants";
import {addCashEntry, getBalance} from "../../API";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import {formatNumber} from "../../Utils";
import {useNavigate} from "react-router-dom";
import SideMenu from "../../Components/SideMenu";

const CashEdit = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [cashInHand, setCashInHand] = useState(0);
    const [newBalance, setNewBalance] = useState(0);
    const [amount, setAmount] = useState(0);
    const [type, setType] = useState(CASH_TYPE_PUT);

    const sendAPIRequest = async () => {
        const {code, data, error} = await getBalance();
        if (code === 200) {
            setCashInHand(data.cashManagementBalance);
        } else {
            setError({message: error, retry: true});
        }
        setLoading(false);
    }

    useEffect(() => {
        sendAPIRequest();
    }, []);

    useEffect(() => {
        calculateBalance();
    }, [cashInHand, amount, type]);

    const handleRetry = async () => {
        setError({});
        setLoading(true)
        await sendAPIRequest();
    }

    const calculateBalance = () => {
        const inHand = parseFloat(cashInHand);
        const adjust = parseFloat(amount);
        let balance;
        if (type === CASH_TYPE_PUT) {
            balance = inHand + adjust;
        } else {
            balance = inHand - adjust;
        }
        setNewBalance(formatNumber(balance));
    }

    const addAmount = (a) => () => {
        const newAmount = parseFloat(amount) + parseFloat(a);
        setAmount(newAmount);
    }

    const handleSave = async () => {
        if (!amount || !parseFloat(amount)) {
            setError({message: 'Amount is Required', retry: false});
            return;
        }
        if (parseFloat(newBalance) < 0) {
            setError({message: 'Cannot take more cash than in hand', retry: false});
            return;
        }
        setLoading(true);
        const {code, error} = await addCashEntry({amount, typeid: type});
        if (code === 200) {
            navigate(-1);
        } else {
            setError({message: error, retry: false});
        }
        setLoading(false);
    }

    const onRetry = !!error.retry ? handleRetry : null;

    return <>
        {loading && <Loader/>}
        {!!error.message &&
            <ErrorDialog isOpen content={error.message} onClose={() => setError({})} onRetry={onRetry}/>}
        <Header title={"Add/Take Cash"} hasBack/>
        <SideMenu selected={MENUS.SETTINGS}/>
        <div id="appCapsule">
            {/* Cash payment Amount */}
            <div className="row section cash-payment-amount mt-2">
                <div className="col-4">
                    <div className="single-cash-payment">
                        <p>Cash On Hand</p>
                        <input
                            type="number"
                            className="form-control"
                            id="taxRate"
                            value={cashInHand}
                            disabled
                            style={{
                                background: 'transparent',
                                border: 0,
                                fontSize: '24px',
                                fontWeight: 700,
                                padding: '2px',
                                textAlign: 'center'
                            }}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="single-cash-payment">
                        <p>Adjust</p>
                        <input
                            type="number"
                            className="form-control"
                            id="taxRate"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            style={{
                                background: 'transparent',
                                border: 0,
                                fontSize: '24px',
                                fontWeight: 700,
                                padding: '2px',
                                textAlign: 'center'
                            }}
                        />

                    </div>
                </div>
                <div className="col-4">
                    <div className="single-cash-payment">
                        <p>New Balance</p>
                        <input
                            type="number"
                            className="form-control"
                            id="taxRate"
                            value={newBalance}
                            disabled
                            style={{
                                background: 'transparent',
                                border: 0,
                                fontSize: '24px',
                                fontWeight: 700,
                                padding: '2px',
                                textAlign: 'center'
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* * Cash payment Amount */}
            {/* Amount Button */}
            <div className="row section amount-btn mt-3">
                <div className="col">
                    <button onClick={addAmount(10)} className="btn btn-light btn-shadow btn-block">10</button>
                </div>
                <div className="col">
                    <button onClick={addAmount(20)} className="btn btn-light btn-shadow btn-block">20</button>
                </div>
                <div className="col">
                    <button onClick={addAmount(50)} className="btn btn-light btn-shadow btn-block">50</button>
                </div>
                <div className="col">
                    <button onClick={addAmount(100)} className="btn btn-light btn-shadow btn-block">100</button>
                </div>
                <div className="col">
                    <button onClick={addAmount(500)} className="btn btn-light btn-shadow btn-block">500</button>
                </div>
            </div>
            {/* * Amount Button */}
            {/* Options */}
            <div className="section tip-option mt-3">
                <div className="btn-group" role="group">
                    <input type="radio"
                           className="btn-check"
                           name="btnradioGroup1"
                           id="btnradio1"
                           value={CASH_TYPE_PUT}
                           checked={type === CASH_TYPE_PUT}
                           onChange={e => setType(parseInt(e.target.value))}
                    />
                    <label className="btn btn-outline-primary" htmlFor="btnradio1">Add</label>
                    <input
                        type="radio"
                        className="btn-check "
                        name="btnradioGroup1"
                        id="btnradio2"
                        value={CASH_TYPE_TAKE}
                        checked={type === CASH_TYPE_TAKE}
                        onChange={e => setType(parseInt(e.target.value))}
                    />
                    <label className="btn btn-outline-primary" htmlFor="btnradio2">Take</label>
                </div>
            </div>
            {/* Buttons */}
            <div className="section mt-3">
                <a onClick={handleSave} className="btn btn-primary btn-shadow btn-lg btn-block">Done</a>
            </div>
            {/* * Buttons */}
        </div>

    </>
}

export default withUserAuth(CashEdit);
