import {useNavigate} from "react-router-dom";
import $ from "jquery";


const Header = ({
                    title,
                    className = '',
                    hasBack,
                    backURL,
                    onQrCodeClick,
                    onSearchClick,
                    onCheckoutClick,
                    onShareClick,
                    onAddClick,
                    checkoutCount = 0
                }) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (backURL) {
            navigate(backURL, {replace: true});
        } else {
            navigate(-1);
        }
    }

    const onMenuClick = () => {
        console.log('show');
        $('#sidebarPanel').addClass('show');
        $('#sidebarPanel').show()
    }

    const handleShowSearch = () => {
        $('#search input').focus();
        setTimeout(() => {
            $('#search').addClass("show")
        }, 100);

    }

    return (
        <div className={`appHeader ${className}`}>
            <div className="left">
                {hasBack && <a onClick={handleBackClick} className="headerButton">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>}
                {!hasBack && <a onClick={onMenuClick} className="headerButton" data-bs-toggle="modal">
                    <ion-icon name="menu-outline"></ion-icon>
                </a>}
                {onQrCodeClick && <a onClick={onQrCodeClick} className="headerButton">
                    <ion-icon name="qr-code-outline"></ion-icon>
                </a>}
            </div>
            <div className="pageTitle">{title}</div>
            <div className="right">
                {onSearchClick && <a onClick={handleShowSearch} className="headerButton toggle-searchbox">
                    <ion-icon name="search-outline"></ion-icon>
                </a>}
                {onCheckoutClick && <a onClick={onCheckoutClick} className="headerButton">
                    <ion-icon name="cart-outline"></ion-icon>
                    <div className="badge badge-danger">{checkoutCount}</div>
                </a>}
                {onShareClick && <a onClick={onShareClick} className="headerButton">
                    <ion-icon name="paper-plane"></ion-icon>
                </a>}
                {onAddClick && <a onClick={onAddClick} className="headerButton">
                    <ion-icon name="add-circle-outline"></ion-icon>
                </a>}
            </div>
        </div>
    )
}

export default Header;
