import {useState} from 'react';
import withUserAuth from "../../Behavior/withUserAuth";
import Header from "../../Components/Header";
import {matchPath, useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import {useDebouncedEffect} from "../../Hooks/useDebouncedEffect";
import {getAllClients, sendInvoice} from "../../API";
import {MENUS, PATH_POS_EMAIL_RECEIPT, PLACEHOLDER_AVATAR} from "../../constants";
import {showErrorToast, showSuccessToast, validateEmail} from "../../Utils";
import Loader from "../../Components/Loader";
import ErrorDialog from "../../Components/ErrorDialog";
import SideMenu from "../../Components/SideMenu";

const POSSendReceipt = () => {
    const location = useLocation();
    const receipt = location.state?.receipt || {};
    const isEmail = !!matchPath(location.pathname, PATH_POS_EMAIL_RECEIPT);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [searchText, setSearchText] = useState('');
    const [isRegister, setIsRegister] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [clients, setClients] = useState([]);

    const loadCustomers = async () => {
        const params = {page: 1, pagesize: 5, searchstring: searchText};
        const {code, data} = await getAllClients(params);
        if (code === 200) {
            setClients(data.accountList);
        }
    }

    useDebouncedEffect(async () => {
        if (!isRegister) {
            await loadCustomers();
        }
    }, [searchText], 500);

    const sendInvoiceRequest = async ({accountid, email: clientEmail, phone: clientPhone}) => {
        const sentto = (accountid ? (isEmail ? clientEmail : clientPhone) : (isRegister ? email : searchText));
        if (!sentto) {
            showErrorToast(`${isEmail ? 'Email' : 'Phone'} is required`)
            return;
        } else if (isEmail) {
            if (!validateEmail(sentto)) {
                showErrorToast('Please enter valid email');
                return;
            }
        }
        if (isRegister) {
            if (!name) {
                showErrorToast('Name is required')
                return;
            }
            if (!phone) {
                showErrorToast('Phone is required');
                return;
            }
        }
        setLoading(true);
        const params = {
            invoiceid: receipt.invoiceId,
            accountid,
            sentto,
            name,
            phone,
            isAddCustomer: isRegister,
            notificationtype: isEmail ? 1 : 2,
        }
        const {code, error, message} = await sendInvoice(params);
        if (code === 200) {
            showSuccessToast(message);
            navigate('/');
        } else {
            setError(error);
            setLoading(false);
        }
    }

    const handleClientClick = (client) => () => {
        sendInvoiceRequest(client);
    };

    return <>
        {loading && <Loader/>}
        {!!error && <ErrorDialog isOpen={true} content={error} onClose={() => setError('')}/>}
        <Header title={isEmail ? "Email Receipt" : "SMS Receipt"} hasBack/>
        <SideMenu selected={MENUS.POS}/>
        <div id="appCapsule">
            <div className="section client-register mt-2">
                <div className="form-check">
                    <input type="checkbox" value={isRegister} onChange={(e) => setIsRegister(e.target.checked)}
                           className="form-check-input" id="customCheckd1"/>
                    <label className="form-check-label" htmlFor="customCheckd1">Register New Client</label>
                </div>
            </div>

            {
                !isRegister && (
                    <div className="section confirmed-email mt-2">
                        <form action="#">
                            <div className="card card-border">
                                <div className="card-body">
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label"
                                                   htmlFor="email1">{isEmail ? 'E-mail' : 'Mobile'}</label>
                                            <input onChange={e => setSearchText(e.target.value)} value={searchText}
                                                   type="email" className="form-control" id="email1"
                                                   placeholder={isEmail ? 'Your e-mail' : ''} required/>
                                        </div>
                                    </div>
                                    <a onClick={() => sendInvoiceRequest({})}
                                       className="btn btn-primary btn-shadow">Send</a>
                                </div>
                            </div>
                        </form>
                    </div>
                )
            }

            {
                !isRegister && clients.map(client => (
                    <div key={client.accountid} className="section client-details mt-3">
                        <div onClick={handleClientClick(client)} className="col-12 p-0" data-bs-toggle="modal">
                            <div className="card img-card card-border p-0">
                            <div className="in">
                                            <div>
                                                <h4>{client.name}</h4>
                                                <p>{client.email}</p>
                                            </div>
                                            <h4 className="mbl-no">{client.phone}</h4>
                                        </div>
                            </div>
                               
                        </div>
                    </div>
                ))

            }

            {
                isRegister && (
                    <div className="section client-register mt-3">
                        <div className="card card-border">
                            <div className="card-body">
                                <form>
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="Name">Client Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="Name"
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="Email">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="Email"
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="Phone">Phone</label>
                                            <input
                                                type="phone"
                                                className="form-control"
                                                id="Phone"
                                                value={phone}
                                                onChange={e => setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="mt-4">
                            <a onClick={() => sendInvoiceRequest({})}
                               className="btn btn-primary btn-shadow btn-lg btn-block mt-2">Send</a>
                        </div>
                    </div>
                )
            }
            {/* * Client Register */}
            {/* Client Name */}

        </div>

    </>
}

export default withUserAuth(POSSendReceipt);
